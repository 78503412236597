import styled from '@emotion/styled';

const Main = styled('main')({
	display: 'flex',
	minWidth: '100dvw',
	minHeight: '100dvh',
	background: 'linear-gradient(180deg, #FFF 43.22%, #F5F8FF 100%)',
	'& section:first-child': {
		flex: 1,
		position: 'relative',
	},
});

export default { Main };
