import styled from '@emotion/styled';

import { sidebarTheme } from '../../../sidebar-theme';

const MenuItem = styled('li')<{ selected: boolean }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: ${sidebarTheme.spacing['positive-50']} ${sidebarTheme.spacing['positive-150']};
	height: 40px;

	&:hover {
		background: rgba(38, 40, 66, 0.04);
	}

	${(props) =>
		props.selected &&
		`background: rgba(38, 40, 66, 0.08);
	
			&:hover {
				background: rgba(38, 40, 66, 0.04);
			}`}
`;

export const StyledLanguageMenu = {
	MenuItem,
};
