import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { SideMenuContent, SideMenuListItem } from '@dartech/dms-ui';
import { Profile } from '@dartech/griffon-auth';
import { Profile as IProfile } from '@dartech/griffon-auth';

import { IRoute } from '../nav-interface';
import useStyles from '../style';

interface Props {
	profile: Profile;
	isMini: boolean;
	routes: (profile: IProfile, t: (v: string) => string) => IRoute[];
	variant: 'light' | 'dark';
}

const Content = ({ profile, isMini, routes, variant }: Props) => {
	const { t } = useTranslation();
	const { classes } = useStyles({ isMini, variant });
	const history = useHistory();

	const onClick = (e, route) => {
		if (route?.length) {
			history.push(route);
		}
	};

	return (
		<SideMenuContent>
			{routes(profile, t).map((item) => {
				if (item.itemId) {
					return (
						<SideMenuListItem
							title={item.title}
							itemId={item.itemId}
							key={item.itemId}
							icon={<item.icon className={classes.menuIcon} />}
							customClickHandler={onClick}
							route={item.route}
							filledIcon={<item.filledIcon className={classes.menuIcon} />}
						/>
					);
				}
			})}
		</SideMenuContent>
	);
};

export { Content };
