import { Workspace } from '@dar/api-interfaces';

import { extractLegalName } from './extract-legal-name';
import { getWorkspaceCounter } from './get-workspace-counter';
import { TransformedCountersByWorkspaceId } from './transform-counters-by-workspace';

export function sortWorkspaces(
	transformedCountersByWorkspaceId: TransformedCountersByWorkspaceId,
	workspaces: Workspace[] = []
): Workspace[] {
	if (Object.keys(transformedCountersByWorkspaceId).length > 0) {
		workspaces.sort((a, b) => {
			const [firstWorkspaceCounter, secondWorkspaceCounter] = [
				getWorkspaceCounter(transformedCountersByWorkspaceId[a.id]),
				getWorkspaceCounter(transformedCountersByWorkspaceId[b.id]),
			];

			const [firstOrgShortName, secondOrgShortName] = [
				extractLegalName(a.organization?.shortName || ''),
				extractLegalName(b.organization?.shortName || ''),
			];

			//Sorting first by notification count then alphabetically
			//If values are equal, compare alphabetically
			return firstWorkspaceCounter !== secondWorkspaceCounter
				? secondWorkspaceCounter - firstWorkspaceCounter
				: firstOrgShortName.localeCompare(secondOrgShortName);
		});
	}

	return [...workspaces];
}
