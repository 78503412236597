const regions = ['kz', 'id', 'com', 'eu'];
export const domainInfo = regions.reduce(
	(acc, v) => {
		acc[`is${v.toUpperCase()}`] = () => window.origin.endsWith(v);
		return acc;
	},
	{
		isDarIo: () => window.origin.endsWith('dar.io'),
		isDev: () => window.origin.endsWith('zone'),
	}
) as Record<'isKZ' | 'isID' | 'isCOM' | 'isDarIo' | 'isDev' | 'isEU', () => boolean>;
