import { EventEmitter } from 'events';
import { BehaviorSubject } from 'rxjs';

import { INotificationData } from '@dar/api-interfaces';

export class NotificationService {
	public unreadNotifications = new BehaviorSubject<INotificationData[]>([]);
	public eventEmitter = new EventEmitter();

	public readById(notificationId: string, read: boolean) {
		return this.eventEmitter.emit('readById', notificationId, read);
	}

	public clearAll(notificationIds: string[]) {
		return this.eventEmitter.emit('clearAll', notificationIds);
	}
}
