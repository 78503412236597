import { UserAvatar as DmsUiUserAvatar } from '@dartech/dms-ui';
import styled from '@emotion/styled';
import { Divider as MuiDivider, ListItemIcon as MuiListItemIcon, Menu as MuiMenu } from '@mui/material';

import { sidebarTheme } from '../../../sidebar-theme';

const MenuItem = styled('div')`
	cursor: default;
	padding: ${sidebarTheme.spacing['positive-50']} ${sidebarTheme.spacing['positive-150']};
	height: 40px;
	display: flex;
	align-items: center;
	font-size: 14px;

	svg {
		margin-right: ${sidebarTheme.spacing['positive-100']};
	}

	&:hover {
		background: rgba(38, 40, 66, 0.04);
		cursor: pointer;
	}
`;

const ListItemIcon = styled(MuiListItemIcon)``;

const UserAvatar = styled(DmsUiUserAvatar)`
	width: 32px;
	height: 32px;
	margin: 0px !important;
`;

const Item = styled('div')`
	display: flex;
	align-items: center;
	padding: ${sidebarTheme.spacing['positive-50']} ${sidebarTheme.spacing['positive-150']};
	gap: ${sidebarTheme.spacing['positive-100']};
	height: 40px;

	&:hover {
		background: rgba(38, 40, 66, 0.04);
	}
`;

const Menu = styled(MuiMenu)`
	.paper {
		transform: translate(5%, 0px) !important;
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.12);
		border-radius: 0px 0px 4px 4px;
		border: 1px solid #e0e3ea;
		width: 320px;
	}
`;

const Divider = styled(MuiDivider)`
	margin-top: ${sidebarTheme.spacing['positive-50']};
	margin-bottom: ${sidebarTheme.spacing['positive-50']};
	height: 1px;
	background-color: #2628421f;
`;

export const StyledProfileMenu = {
	MenuItem,
	ListItemIcon,
	UserAvatar,
	Item,
	Menu,
	Divider,
};
