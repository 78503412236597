import React, { useEffect, useRef, useState } from 'react';

import { ChatUnreadCount, Workspace } from '@dar/api-interfaces';
import { SideModalContainer } from '@dartech/dms-ui';
import { IconButton, Typography } from '@mui/material';

import { ReactComponent as ChatIcon } from '../../assets/icons/chat.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as OpenIcon } from '../../assets/icons/open_in_new.svg';
import { environment } from '../../environments/environment';
import { ChatService } from '../../services/chat';
import useInterval from '../../utils/useInterval';
import { getUnreadMessages } from '../../utils/workchat';
import styles from './chat-link.module.scss';

interface Props {
	company: Workspace;
}

export const chatService = new ChatService();

const ChatLink: React.FC<Props> = ({ company }) => {
	const iframeRef = useRef(null);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [unreadMessage, setUnreadMessage] = useState<ChatUnreadCount>(null);
	const [loaded, setLoaded] = useState(false);

	const onClose = () => {
		setOpenDrawer(false);
	};

	const onInNew = () => {
		window.open(chatService.getUrl(), '_blank');
	};

	useEffect(() => {
		chatService.setCallback(() => {
			setOpenDrawer(true);
		});
	}, []);

	useEffect(() => {
		chatService.setUrl(`${environment().dmsWorkchatUrl}/${company.id}`);
	}, [company]);

	useEffect(() => {
		const messageListener = (event) => {
			const base = new URL(environment().dmsWorkchatUrl).origin;
			if (event.origin !== base || !iframeRef.current) {
				return;
			}

			switch (event.data) {
				case 'griffonTokenRequest': {
					console.log('Received auth request');
					const token = JSON.parse(localStorage.getItem('griffon-auth'))?.id_token;

					let message = {
						type: 'no token',
						token: '',
					};

					if (token) {
						message = {
							type: 'griffonTokenResponse',
							token,
						};
					}

					iframeRef.current.contentWindow.postMessage(message, base);
					break;
				}
				case 'griffonAuthSuccess': {
					console.log('MM auth success');
					setLoaded(true);
					break;
				}
				default:
					console.log(event.data);
			}
		};

		window.addEventListener('message', messageListener, false);
		return () => {
			window.removeEventListener('message', messageListener);
		};
	});

	useEffect(() => {
		getUnreadMessages()
			.then((res) => {
				setUnreadMessage(res?.length ? res[0] : null);
			})
			.catch((err) => console.error(err));
	}, []);

	useInterval(() => {
		getUnreadMessages()
			.then((res) => {
				setUnreadMessage(res?.length ? res[0] : null);
			})
			.catch((err) => console.error(err));
	}, 30000);

	const count = unreadMessage ? unreadMessage.mention_count + unreadMessage.msg_count : 0;

	return (
		<>
			<div className={styles.iconWrapper}>
				<ChatIcon className={styles.icon} onClick={() => setOpenDrawer(true)} />
				{!!count && <div className={styles.badge}>{count}</div>}
			</div>
			<SideModalContainer openDrawer={openDrawer} onClose={onClose} size='large' isModal={true} disableEnforceFocus>
				<div className={styles.header}>
					<Typography variant='h3' className={styles.headerText}>
						Workchat
					</Typography>
					<div className={styles.headerButtons}>
						{loaded && (
							<IconButton onClick={onInNew} aria-label='close' color='default' classes={{ root: styles.iconButton }}>
								<OpenIcon />
							</IconButton>
						)}
						<svg
							width='1'
							height='24'
							viewBox='0 0 1 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
							className={styles.separator}
						>
							<line x1='0.5' x2='0.5' y2='24' stroke='#E0E3EA' />
						</svg>
						<IconButton onClick={onClose} aria-label='close' color='default' classes={{ root: styles.iconButton }}>
							<CloseIcon />
						</IconButton>
					</div>
				</div>
				<iframe
					ref={iframeRef}
					src={`${chatService.getUrl()}?dms=true`}
					allow='camera; microphone'
					title='Workchat'
					className={styles.iframe}
				/>
			</SideModalContainer>
		</>
	);
};

export { ChatLink };
