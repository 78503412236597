import React, { useMemo } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import AuthHeader from '@topbar/src/components/auth-header/auth-header.component';
import FrontCard from '@topbar/src/components/front-card/front-card.component';
import { useAuth } from '@topbar/src/topbar/providers/auth-provider';

import routes from '../../../topbar/app/app-lazy';
import OnboardingLayout from '../onboarding-layout/onboarding-layout';
import { backButtonPaths, cardPaths, loginPaths, paths } from './settings';
import Styled from './style';

function AuthLayout() {
	const { pathname } = useLocation();

	const { authenticated, surveyStructure, workspace } = useAuth();

	const isSignup = useMemo(() => paths.includes(pathname), [pathname]);
	const isShowFrontCard = useMemo(() => cardPaths.includes(pathname), [pathname]);
	const isSignin = useMemo(() => loginPaths.includes(pathname), [pathname]);
	const hasBackButton = useMemo(() => backButtonPaths.includes(pathname), [pathname]);

	return (
		<Styled.Main>
			<section style={{ display: 'flex', flexDirection: 'column' }}>
				<AuthHeader
					hasBackButton={hasBackButton}
					showLogin={isSignup}
					showLogo={hasBackButton || !isShowFrontCard}
					showSignup={isSignin}
				/>
				<Switch>
					<Route exact path='/signup' component={routes.SignUpPage} />
					<Route exact path='/email-otp' component={routes.CheckEmail} />
					<Route exact path='/create-account' component={routes.CreateAccount} />
					<Route exact path='/reset-password-otp' component={routes.ResetPasswordOtp} />
					<Route exact path='/auth/social' component={routes.LoginSocial} />
					<Route exact path='/reset-password' component={routes.ResetPassword} />
					<Route path='/workspace' component={OnboardingLayout} />
					{authenticated && workspace && workspace.hasOwnProperty('isSurveyFinished') && !workspace.isSurveyFinished && surveyStructure && (
						<Redirect exact from='*' to={`/workspace/survey?step=${surveyStructure[0].key}`} />
					)}
					<Route exact path={['/signin', '/']} component={routes.SignInPage} />
					{authenticated !== null && <Redirect from='*' to='/signin' exact />}
				</Switch>
			</section>
			{isShowFrontCard && <FrontCard />}
		</Styled.Main>
	);
}

export default AuthLayout;
