import { Region } from '@dar-dms/utils';

export const WORKSPACE_STORAGE_KEY = 'workspace';

export const WORKSPACE_CREATE_STORAGE_KEY = 'workspace_create';

export const INVITATION_STORAGE_KEY = 'workspace_invitation';

export const WORKSPACE_TOKEN_STORAGE_KEY = 'workspace_token';

export const NEW_WORKSPACE_EMAIL_KEY = 'new_workspace_contact_email';

export const DEFAULT_TRIAL_DAYS = (region: Region) => (region === 'US' ? 30 : 7);
