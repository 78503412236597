import { ModuleSetting } from '@topbar/src/services/sidebar';

export function removeModuleSetting(
	modulesSettings: readonly ModuleSetting[],
	removedModuleSetting: ModuleSetting
): readonly ModuleSetting[] {
	return modulesSettings
		.filter((moduleSetting) => moduleSetting.moduleType !== removedModuleSetting.moduleType)
		.map((filteredModuleSetting) => ({
			...filteredModuleSetting,
			order:
				filteredModuleSetting.order > removedModuleSetting.order ? filteredModuleSetting.order - 1 : filteredModuleSetting.order,
		}));
}
