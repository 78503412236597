import styled from '@emotion/styled';

import { Button } from '../../components/shared/button/button';
import { flexHorizontalSpaceBetweenStyles } from '../../utils/styles';

const Container = styled(Button)<{ $isActive?: boolean; $hasNotifications?: boolean }>`
	${flexHorizontalSpaceBetweenStyles}

	padding: ${({ theme }) => `${theme.spacing['positive-50']} ${theme.spacing['positive-75']}`};
	position: relative;
	border-radius: ${(props) => props.theme.borderRadius.sm};
	text-decoration: none;
	color: #262842;
	min-height: 32px;
	max-height: 32px;
  width: 100%;

	&:hover {
		background: #2628420a;
		cursor: pointer;
	}

	&:active {
		background: #26284214;
	}

	&:focus-visible {
		background: #2628420a;
		outline: 2px solid #039be6;
	}

	${(props) =>
		props.$isActive &&
		`
    background: #E6F5FD;

    svg path{
      fill: #0072B8;
    }

    svg + p {
      color: #0072B8;
      font-weight: 500;
    }
  `}

	position: relative;

	&::after {
		content: '';
		display: inline-block;
		background: #0072b8;
		border: 2px solid #ffffff;
		border-radius: 50%;
		box-sizing: content-box;
		height: 8px;
		padding: 0;
		position: absolute;
		right: 1px;
		top: 1px;
		width: 8px;
		z-index: 1;
		transition: opacity 0.2s 0.5s;
		opacity: 0;
	}

	${(props) =>
		props.$hasNotifications &&
		`
    &::after {
      opacity: 1;
    }
  `}
`;

export const StyledMenuItemButton = {
  Container,
};
