import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import { LinearProgress } from '@mui/material';
import { useAuth } from '@topbar/src/topbar/providers/auth-provider';

import routes from '../../../topbar/app/app-lazy';
import { getSettings } from './settings';
import Styled from './style';

const OnboardingLayout: FC = () => {
	const { t } = useTranslation();
	const { pathname, search } = useLocation();
	const { path } = useRouteMatch();
	const { surveyStructure } = useAuth();
	const step = new URLSearchParams(search).get('step');

	const { title, description, progressStep } = useMemo(
		() => getSettings(pathname, step, surveyStructure, t),
		[pathname, step, surveyStructure, t]
	);

	return (
		<>
			{!!progressStep && (
				<LinearProgress
					sx={{ maxWidth: '500px', margin: 'auto', width: '100%' }}
					variant='determinate'
					value={(progressStep / 5) * 100}
				/>
			)}
			<Styled.Container>
				<h1>{title}</h1>
				<p>{description}</p>
				<Switch>
					<Route path={`${path}/business-name`} component={routes.BusinessName} />
					<Route path={`${path}/how-will-use`} component={routes.HowUse} />
					<Route path={`${path}/manage-tools`} component={routes.ManageTools} />
					<Route path={`${path}/survey`} component={routes.Survey} />
				</Switch>
			</Styled.Container>
		</>
	);
};

export default OnboardingLayout;
