export enum Modals {
	INVITE_APPROVERS_MODAL = 'invite-approvers-modal',
	DELETE_APPROVER_MODAL = 'delete-approver-modal',
	DOCUMENT_APPROVE_MODAL = 'document-approve-modal',
	SIGN_QR_MODAL = 'sign-qr-modal',
	EGOV_QR_MODAL = 'egov-qr-modal',
	EGOV_SIGN_TYPE_MODAL = 'egov-sign-type-modal',
	DIGITAL_SIGN_MODAL = 'digital-sign-modal',
	SIGNATURE_MODAL = 'signature-modal',
	APPROVE_DOCUMENT_MODAL = 'approve-document-modal',
}
