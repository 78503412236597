import { ModuleSetting, SidebarSettingRequest, getPinnedMenuItems } from '@topbar/src/services/sidebar';
import { domainInfo } from '@topbar/src/utils/domain';

import { ROUTES } from '../../../sidebar-constants';

type FetchProps = {
	onSuccess: (modulesSettings: readonly ModuleSetting[]) => void;
	onError: (modulesSettings: readonly ModuleSetting[]) => void;
	data: SidebarSettingRequest;
};

const isProdKZ = domainInfo.isKZ();
const DEFAULT_PINNED_ITEMS_KZ = [ROUTES.PROJECTS, ROUTES.PLANS, ROUTES.DOC_FLOW, ROUTES.COMPANY, ROUTES.TIMESHEETS];
const DEFAULT_PINNED_ITEMS = [ROUTES.PROJECTS, ROUTES.PLANS, ROUTES.COMPANY, ROUTES.TIMESHEETS];

export async function fetchModuleSettings({ onSuccess, onError, data }: FetchProps) {
	try {
		const {
			data: { modulesSettings },
		} = await getPinnedMenuItems(data);
		onSuccess(modulesSettings);
	} catch (error) {
		if (error.errorCode === 404) {
			if (isProdKZ) {
				onSuccess(DEFAULT_PINNED_ITEMS_KZ.map((item, index) => ({ moduleType: item, order: index + 1 })));
			} else {
				onSuccess(DEFAULT_PINNED_ITEMS.map((item, index) => ({ moduleType: item, order: index + 1 })));
			}
			return;
		} else {
			onError([]);
			return;
		}
	}
}
