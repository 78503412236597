import { useCallback, useState } from 'react';

type UseAnchorEl = {
	anchorEl: HTMLElement | null;
	isOpen: boolean;
	onModalClose: () => void;
	onModalOpen: (event: React.MouseEvent<HTMLElement>) => void;
};

export function useAnchorEl(): UseAnchorEl {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const onModalClose = useCallback(() => {
		setAnchorEl(null);
	}, []);

	const onModalOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	}, []);

	return {
		anchorEl,
		isOpen: !!anchorEl,
		onModalClose,
		onModalOpen,
	};
}
