import _isEqual from 'lodash/isEqual';
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import { Workspace } from '@dar/api-interfaces';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Autocomplete, Box, IconButton, InputAdornment, InputBase, Typography } from '@mui/material';
import { NotificationCounter } from '@topbar/src/components/sidebar/components/shared/notification-counter/notification-counter';
import { TypographyWithHint } from '@topbar/src/components/sidebar/components/shared/typography-with-hint/typography-with-hint';
import { ReactComponent as Search } from '@topbar/src/components/sidebar/svg/search.svg';

import { TransformedCountersByWorkspaceId } from '../../../utils/transform-counters-by-workspace';
import { WorkspaceAvatar } from '../../workspace-avatar/workspace-avatar';
import { Size } from '../../workspace-avatar/workspace-avatar-types';

interface Props {
	open: boolean;
	activeCompany: Workspace;
	options: Workspace[];
	onSelect: (val: Workspace) => void;
	countersByWorkspaceId: TransformedCountersByWorkspaceId;
}

const useStyles = makeStyles()((theme) => ({
	inputRoot: {
		width: '100%',
		minWidth: 180,
		padding: '12px',
		border: 'none',
		background: '#F7F8FC',
		'&:hover': { border: 'none' },
		'&:focus': { border: 'none' },
	},
	inputBase: {
		width: '100%',
		borderRadius: '8px 8px 0 0',
		borderBottom: '2px solid #039BE6',
		background: '#26284214',
		padding: '10px 12px',
		maxHeight: '40px',
		'& input': {
			transition: theme.transitions.create(['border-color', 'box-shadow']),
			fontSize: 14,
			lineHeight: '18px',
			'&:active': {
				borderColor: 'red',
			},
			'&:focus': {
				borderColor: 'green',
			},
		},
	},
	inputAdorement: {
		margin: '0 4px 0 0',
		width: '24px',
		height: '24px',
	},
	option: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		flex: 'auto',
	},
	clear: {
		width: '24px',
		height: '24px',
		'& path': {
			fill: '#6D7C8B',
		},
	},
	logo: {
		width: 40,
		height: 40,
		borderRadius: 6,
	},
	logoContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	workspaceName: {
		fontSize: '14px',
		fontWeight: 500,
		lineHeight: '18px',
		marginLeft: '8px',
	},
}));

const autoCompleteStyles = makeStyles<{ optionsLength: number }>()((_, { optionsLength }) => ({
	option: {
		marginBottom: '4px',
		borderRadius: '8px',
		padding: '8px 12px',
		display: 'flex',
		justifyContent: 'space-between',
		'&:hover': {
			background: '#2628420A',
		},
		'&:has(.isActive)': {
			background: '#26284214',
		},
		'&:focus-visible': {
			background: '#26284214',
			outline: '2px solid #039BE6',
		},
		'&>div:nth-child(1)': {
			display: 'flex',
			alignItems: 'center',
		},
	},
	listbox: {
		maxHeight: '336px',
		minHeight: '160px',
		padding: '16px 12px',
		borderTop: '1px solid #2628421F',
		width: '100%',
		height: `${optionsLength * 40 + (optionsLength - 1) * 4 + 32}px`,
		overflowY: optionsLength > 7 ? `scroll` : 'hidden',
	},
}));

const getTitle = (option: Workspace) => {
	const shortName = option?.organization?.shortName;
	const name = option?.organization?.name;
	if (shortName) {
		if (shortName === 'ТОО') return 'ТОО';
		return shortName?.replace('TOO', '').replace('ТОО', '').trim();
	}
	if (name) {
		if (name === 'ТОО') return 'ТОО';
		return name?.replace('TOO', '').replace('ТОО', '').trim();
	}
	return '';
};

export const MenuAutoCompleteSelect = memo(
	({ open, activeCompany, options, onSelect, countersByWorkspaceId }: Props) => {
		const { t } = useTranslation();
		const [inputValue, setInputValue] = useState('');
		const { classes } = useStyles();
		const { classes: autoCompleteClasses } = autoCompleteStyles({ optionsLength: options.length });
		const onInputChange = (__: unknown, val: string) => {
			setInputValue(val);
		};
		const getOptionSelected = (option: Workspace, value: Workspace) => activeCompany && option['id'] === value['id'];
		const onChange = (event: React.ChangeEvent<unknown>, val: Workspace) => {
			onSelect(val);
		};

		const Pop = (props) => {
			const { className, anchorEl, style, ...rest } = props;
			return (
				<div
					{...rest}
					style={{
						zIndex: 9999,
					}}
				/>
			);
		};
		const Paper = (props) => {
			const { className, anchorEl, style, ...rest } = props;
			return (
				<div
					{...rest}
					style={{
						zIndex: 9999,
					}}
				/>
			);
		};

		return (
			<Autocomplete
				fullWidth
				open={open}
				isOptionEqualToValue={getOptionSelected}
				getOptionLabel={(option) => getTitle(option)}
				options={options}
				inputValue={inputValue}
				classes={autoCompleteClasses}
				onChange={onChange}
				onInputChange={onInputChange}
				noOptionsText={t('general.nothingFound')}
				PaperComponent={Paper}
				className={classes.inputRoot}
				ListboxProps={{ role: 'list-box' }}
				renderInput={(params) => (
					<InputBase
						autoFocus
						onKeyDown={(event) => event.stopPropagation()}
						value={inputValue}
						placeholder={t('general.search')}
						ref={params.InputProps.ref}
						inputProps={{ ...params.inputProps }}
						className={classes.inputBase}
						startAdornment={
							<InputAdornment position='end' className={classes.inputAdorement}>
								{inputValue !== '' ? (
									<IconButton
										size='small'
										className={classes.clear}
										onClick={() => {
											setInputValue('');
										}}
									>
										<ClearIcon />
									</IconButton>
								) : (
									<Search />
								)}
							</InputAdornment>
						}
					/>
				)}
				renderOption={(props, option: Workspace) => {
					const isActiveWorkspace = option.id === activeCompany.id;

					return (
						<TypographyWithHint
							text={getTitle(option) || ''}
							shortWordMaxLength={isActiveWorkspace ? 20 : 22}
							render={(text) => (
								<li className={`${classes.option} ${isActiveWorkspace ? 'isActive' : null}`} {...props}>
									<Box display='flex' alignItems='center'>
										<WorkspaceAvatar
											workspaceAvatarSrc={option?.organization?.logo}
											workspaceName={getTitle(option)}
											size={Size.SMALL}
										/>
										<Typography className={classes.workspaceName}>{text}</Typography>
									</Box>
									{isActiveWorkspace ? (
										<CheckIcon style={{ fill: 'background: #8B8C9E' }} />
									) : countersByWorkspaceId[option.id] > 0 ? (
										<NotificationCounter counter={countersByWorkspaceId[option.id]} />
									) : null}
								</li>
							)}
						/>
					);
				}}
				PopperComponent={Pop}
			/>
		);
	},
	(prevProps, nextProps) =>
		prevProps.open === nextProps.open &&
		prevProps.activeCompany.id === nextProps.activeCompany.id &&
		_isEqual(prevProps.options, nextProps.options)
);
