import React, { ReactElement } from 'react';

import { StyledHeader } from './sidebar-header-styles';
import { HeaderProps } from './sidebar-header-types';

const DEFAULT_HOME_PAGE_LINK = '/overview';

export function SidebarHeader({ link, children }: HeaderProps): ReactElement {
	const { to = DEFAULT_HOME_PAGE_LINK, title } = link;

	return (
		<StyledHeader.Container>
			<StyledHeader.Link to={to} title={title}>
				{children}
			</StyledHeader.Link>
		</StyledHeader.Container>
	);
}
