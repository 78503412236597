import { useEffect, useRef } from 'react';

export default function useInterval(callback, delay) {
	const savedCallback = useRef<(args?) => void>();

	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	useEffect(() => {
		function handleCallback() {
			savedCallback.current();
		}

		if (delay !== null) {
			const id = setInterval(handleCallback, delay);

			return () => {
				clearInterval(id);
			};
		}
	}, [callback, delay]);
}
