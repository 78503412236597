export enum UserRoles {
	Admin = 'admin',
	Contributor = 'contributor',
	Viewer = 'viewer',
}

export interface Access {
	archived?: boolean;
	projectId: number;
	userId: string;
	roleId: UserRoles;
	createdAt?: Date;
	updatedAt?: Date;
	deactivate?: boolean;
}

export enum ProjectType {
	project = 'project',
	plan = 'plan',
}

export enum WorkflowType {
	kanban = 'kanban',
	scrum = 'scrum',
}

export interface Project {
	archived: boolean;
	createdAt: string;
	creatorId: string;
	dateStart?: string | Date;
	dateEnd?: string | Date;
	description: string;
	health?: string;
	healthDescription?: string;
	healthUpdateTime?: string;
	holdingId?: string;
	icon: string;
	id: number;
	numberOfLevels: number;
	ownerId: string;
	progress: number;
	projectManager: string;
	role: UserRoles;
	status: string;
	title: string;
	team: Access[];
	type: ProjectType;
	unreadCount: number;
	updatedAt?: string;
	workspaceId: string;
	driveFolderId?: string;
	driveInSync?: boolean;
	crossProject: boolean;
	workflowType: WorkflowType;
}
