import axios from 'axios';
import { AxiosResponse } from 'axios';

import { toast } from '@dartech/dms-ui';
import { environment } from '@topbar/src/environments/environment';
import i18n from '@topbar/src/i18';

import { clearTokens, getTokens } from '../utils/storage-helper';

const commonExceptionHandler = (error: any) => {
	const bypassAlertOnErrorCodes = error?.config?.bypassAlertOnErrorCodes;
	if (!bypassAlertOnErrorCodes || !bypassAlertOnErrorCodes.includes(`${error.response.status}`)) {
		toast.error(error?.detail, {
			duration: 3000,
			id: 'error',
			title: i18n.t(error?.response?.data?.detail),
			withIcon: true,
		});
	}
	return Promise.reject(error);
};

const client = axios.create({
	baseURL: `${environment().dmsBaseApi}/docflow/otp`,
});

const errorCodes = [400, 401, 403, 404];

client.interceptors.request.use((config) => {
	const { access: accessToken } = getTokens();
	config.headers['Authorization'] = accessToken ? `Bearer ${accessToken}` : '';
	return config;
});

client.interceptors.response.use((response: AxiosResponse) => response, commonExceptionHandler);

client.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error.response && errorCodes.includes(error.response.status)) {
			window.location.href = `${environment().dmsBase}/docflow-sign/access-denied`;
			clearTokens();
		}
		return Promise.reject(error);
	}
);

export default client;
