import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Button } from '../components/shared/button/button';
import { Typography } from '../components/shared/typography/typography';
import { TypographyVariant } from '../sidebar-theme';
import { Link } from '../components/shared/link/link';
import { flexHorizontalSpaceBetweenStyles } from '../utils/styles';

const Title = styled(Typography)`
	color: #262842;
	margin-left: ${(props) => props.theme.spacing['positive-125']};
`;

const Container = styled(Link)<{ $isActive?: boolean; $hasNotifications?: boolean }>`
	${flexHorizontalSpaceBetweenStyles}

	padding: ${({ theme }) => `${theme.spacing['positive-50']} ${theme.spacing['positive-75']}`};
	position: relative;
	border-radius: ${(props) => props.theme.borderRadius.sm};
	text-decoration: none;
	color: #262842;
	min-height: 32px;
	max-height: 32px;

	&:hover {
		background: #2628420a;
		cursor: pointer;
	}

	&:active {
		background: #26284214;
	}

	&:focus-visible {
		background: #2628420a;
		outline: 2px solid #039be6;
	}

	${(props) =>
		props.$isActive &&
		`
    background: #E6F5FD;

    svg path{
      fill: #0072B8;
    }

    svg + p {
      color: #0072B8;
      font-weight: 500;
    }
  `}

	position: relative;

	&::after {
		content: '';
		display: inline-block;
		background: #0072b8;
		border: 2px solid #ffffff;
		border-radius: 50%;
		box-sizing: content-box;
		height: 8px;
		padding: 0;
		position: absolute;
		right: 1px;
		top: 1px;
		width: 8px;
		z-index: 1;
		transition: opacity 0.2s 0.5s;
		opacity: 0;
	}

	${(props) =>
		props.$hasNotifications &&
		`
    &::after {
      opacity: 1;
    }
  `}
`;

const IconContainer = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;

	& svg {
		min-width: 20px;
		min-height: 20px;
	}
`;

const AdditionalStateContainer = styled(Box)`
	display: flex;
	align-items: center;

	& > *:not(:last-child) {
		margin-right: ${(props) => props.theme.spacing['positive-100']};
	}
`;

const BetaBadge = styled(Box)<{ $collapsed?: boolean }>`
	padding: ${({ theme }) => `${theme.spacing['positive-50']} ${theme.spacing['positive-75']}`};
	border-radius: ${(props) => props.theme.borderRadius.xxs};
	gap: ${(props) => props.theme.spacing['positive-50']};
	background: #0072b8;
	font-size: ${(props) => props.theme.fontSize[TypographyVariant.SMALL]};
	line-height: ${(props) => props.theme.lineHeight[TypographyVariant.SMALL]};
	color: #ffffff;

	${(props) =>
		props.$collapsed &&
		`
    background: #0072B8;
    border: 2px solid #FFFFFF;
    border-radius: 50%;
    box-sizing: content-box;
    height: 8px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 8px;
  `}
`;

const PinButton = styled(Button)`
	border: none;
	border-radius: ${(props) => props.theme.borderRadius.xxs};

	&:hover {
		background: rgba(38, 40, 66, 0.04);
		cursor: pointer;
	}

	&:active {
		background: rgba(38, 40, 66, 0.08);
	}

	&:focus-visible {
		outline: 2px solid #0072b8;
	}

	& svg {
		pointer-events: none;
	}
`;

export const StyledMenuItem = {
	AdditionalStateContainer,
	Container,
	Title,
	BetaBadge,
	PinButton,
	IconContainer,
};
