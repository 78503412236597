import React, { FC, ReactElement, useEffect, useState } from 'react';

import { ChatContext } from '../../contexts/chat-context';
import { useChat } from '../../hooks/useChat';

type ChatData = {
	AIBotChat: FC;
};

function isChatData(chatData: any): chatData is ChatData {
	return chatData && 'AIBotChat' in chatData;
}

export const ChatBotProviderWrapper: FC = ({ children }) => {
	const [chatData, setChatData] = useState<ChatData | Record<string, never>>({});

	useEffect(() => {
		async function getChatAndBotModules() {
			try {
				const chatData = await (window as any).System.import('@dar-dms/chat');
				setChatData(chatData);
			} catch {
				console.error('can not import chat or bot module');
				setChatData({});
				return;
			}
		}

		getChatAndBotModules();
	}, []);

	if (isChatData(chatData)) {
		return <ChatBotProvider chatData={chatData}>{children}</ChatBotProvider>;
	}

	return children as ReactElement;
};

interface ChatBotProviderProps {
	chatData: ChatData;
}

const ChatBotProvider: FC<ChatBotProviderProps> = ({ chatData, children }) => {
	const { AIBotChat } = chatData;
	const { onChatClick } = useChat();

	return (
		<ChatContext.Provider value={{ onChatClick }}>
			{children}
			<AIBotChat />
		</ChatContext.Provider>
	);
};
