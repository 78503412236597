import { useEffect } from 'react';

export function useResize(onResize: () => void) {
	useEffect(() => {
		onResize();
		window.addEventListener('resize', onResize);

		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, []);
}
