import React, { ReactElement } from "react";

import { StyledMenuItemIconContainer } from "./menu-item-icon-container-styles";
import { TypographyWithHint } from "../../../components/shared/typography-with-hint/typography-with-hint";
import { MenuItemIconContainerProps } from "./menu-item-icon-container-types";

const MENU_ITEM_ICON_SIZE = 20;

export function MenuItemIconContainer({
  icon: MenuItemIcon,
  shouldShortenText,
  shortWordMaxLength,
  title,
  titleRef,
}: MenuItemIconContainerProps): ReactElement {
  return (
    <StyledMenuItemIconContainer.Wrapper>
      <MenuItemIcon role='presentation' width={MENU_ITEM_ICON_SIZE} height={MENU_ITEM_ICON_SIZE} />
      {shouldShortenText ? (
        <TypographyWithHint
          shortWordMaxLength={shortWordMaxLength}
          text={title}
          render={(text) => 
            <StyledMenuItemIconContainer.Title ref={titleRef}>
              {text}
            </StyledMenuItemIconContainer.Title>
          }
        />
      ) : (
        <StyledMenuItemIconContainer.Title ref={titleRef}>
          {title}
        </StyledMenuItemIconContainer.Title>
      )}
    </StyledMenuItemIconContainer.Wrapper>
  );
}