import React, { PropsWithChildren, ReactElement, useMemo } from 'react';
import { useQuery } from 'react-query';

import { useBadges } from '../../hooks/useBadges';
import { getEmployeeWorkspaces } from '../../services/companies';
import { useAuth } from '../../topbar/providers/auth-provider';
import { BadgeContext } from './badge-context';

export function BadgeProvider({ children }: PropsWithChildren<{}>): ReactElement {
	const { profile, logout } = useAuth();
	const { data } = useQuery('fetch-workspaces', () => getEmployeeWorkspaces(profile?.id), {
		onError: () => {
			logout();
		},
		enabled: !!profile?.id,
	});
	const holdings = useMemo(
		() => (data?.length ? [...new Set(data?.filter((v) => v.holdingId).map((v) => v.holdingId))] : []),
		[data]
	);

	const badges = useBadges(profile?.id, holdings);

	return <BadgeContext.Provider value={badges}>{children}</BadgeContext.Provider>;
}
