import React from 'react';

import { responsiveFontSizes } from '@mui/material';
import { adaptV4Theme, createTheme } from '@mui/material/styles';

const BORDER_RADIUS = {
	sm: '10px',
	xs: '8px',
};

declare module '@mui/material/styles/createPalette' {
	interface PaletteColor {
		50?: React.CSSProperties['color'];
		100?: React.CSSProperties['color'];
		200?: React.CSSProperties['color'];
		300?: React.CSSProperties['color'];
		400?: React.CSSProperties['color'];
		500?: React.CSSProperties['color'];
		600?: React.CSSProperties['color'];
		700?: React.CSSProperties['color'];
		800?: React.CSSProperties['color'];
		900?: React.CSSProperties['color'];
	}
	interface Color {
		800?: React.CSSProperties['color'];
		900?: React.CSSProperties['color'];
	}
	interface TypeText {
		placeholder?: React.CSSProperties['color'];
	}
	interface Palette {
		status?: PaletteColor;
	}
	interface PaletteOptions {
		status?: PaletteColorOptions;
	}
}

declare module '@mui/material/styles/createTypography' {
	interface FontStyle {
		fontWeightDemibold: React.CSSProperties['fontWeight'];
	}
}

// TODO: Update whole primary color in theme
const newPrimary = '#039BE6';
const newPrimaryHover = '#0692D9';
const newPrimaryActive = '#088DD2';

let theme = createTheme(
	adaptV4Theme({
		spacing: 4,
		palette: {
			status: {
				100: '#3781CB',
				300: '#976AD1',
				400: '#2DB77B',
				500: '#ED4C36',
				600: '#FFA530',
				700: '#1785B3',
			},
			info: {
				light: '#62F3FB', // 400
				main: '#2FDEF9', // 500
				dark: '#22AFD6', // 600
				100: '#D5FEF7',
				200: '#ABFEF5',
				300: '#81FDFA',
				400: '#62F3FB',
				500: '#2FDEF9',
				600: '#22AFD6',
				700: '#1785B3',
				800: '#0E6090',
				900: '#094577',
			},
			primary: {
				light: '#F1855C', // 400
				main: '#EA7042', // 500
				dark: '#E06537', // 600
				100: '#3C95D6',
				200: '#1B74B4',
				300: '#0B69AC',
				400: '#F1855C',
				500: '#EA7042',
				600: '#E06537',
				700: '#007F9B',
			},
			warning: {
				light: '#FFBD42', // 400
				main: '#FFA530', // 500
				dark: '#FF9021', // 600
				100: '#FFF8EB',
				200: '#FFE8BF',
				300: '#FFD68A',
				400: '#FFBD42',
				500: '#FFA530',
				600: '#FF9021',
				700: '#FF7B0F',
				800: '#E8650E',
				900: '#7A2E15',
			},
			error: {
				light: '#ED4D36', // 400
				main: '#E02E14', // 500
				dark: '#BB2611', // 600
				100: '#FDE6E3',
				200: '#F9C1B9',
				300: '#F38B7C',
				400: '#ED4D36',
				500: '#E02E14',
				600: '#BB2611',
				700: '#9F200E',
				800: '#831A0C',
				900: '#6C150A',
			},
			success: {
				light: '#57DB95', // 400
				main: '#2DB77B', // 500
				dark: '#00875A', // 600
				100: '#E9FEEE',
				200: '#C7F9D4',
				300: '#91F3B5',
				400: '#57DB95',
				500: '#2DB77B',
				600: '#00875A',
				700: '#007458',
				800: '#00624A',
				900: '#004938',
			},
			secondary: {
				light: '#ACB6BF', // 400
				main: '#8A96A1', // 500
				dark: '#465564', // 600
			},
			grey: {
				50: '#FFFFFF',
				100: '#F5F8FA',
				200: '#E6ECF1',
				300: '#CED7DF',
				400: '#ACB6BF',
				500: '#8A96A1',
				600: '#465564',
				700: '#1D2B36',
				800: '#101F2B',
				900: '#050E14',
			},
			common: {
				black: '#000000',
			},
		},
	})
);

theme.palette = {
	...theme.palette,
	primary: {
		main: '#039BE6',
		light: '#8BD1F4',
		dark: '#0072B8',
		contrastText: '#ffffff',
	},
	text: {
		primary: theme.palette.grey[700],
		secondary: theme.palette.grey[600],
		disabled: theme.palette.grey[400],
		// hint: theme.palette.grey[500],
		placeholder: theme.palette.grey[500],
	},
	background: {
		paper: theme.palette.grey[50],
		default: theme.palette.grey[50],
	},
	action: {
		active: theme.palette.primary.dark,
		hover: theme.palette.primary.light,
		hoverOpacity: 0.08,
		selected: 'rgba(255, 255, 255, 0.16)',
		selectedOpacity: 0.16,
		disabled: theme.palette.grey[400],
		disabledBackground: theme.palette.grey[300],
		disabledOpacity: 0.38,
		focus: theme.palette.primary.dark,
		focusOpacity: 0.12,
		activatedOpacity: 0.24,
	},
};

theme.typography = {
	...theme.typography,
	fontSize: 14,
	fontWeightLight: 400,
	fontWeightRegular: 400,
	fontWeightMedium: 500,
	fontWeightDemibold: 500,
	fontWeightBold: 800,
	fontFamily: [
		'Euclid',
		'-apple-system',
		'BlinkMacSystemFont',
		'"Segoe UI"',
		'Roboto',
		'"Helvetica Neue"',
		'Arial',
		'sans-serif',
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"',
	].join(','),
};

theme.typography = {
	...theme.typography,
	h1: {
		fontFamily: theme.typography.fontFamily,
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: '28px',
		lineHeight: 36 / 28,
	},
	h2: {
		fontFamily: theme.typography.fontFamily,
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: '24px',
		lineHeight: 32 / 24,
	},
	h3: {
		fontFamily: theme.typography.fontFamily,
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: '20px',
		lineHeight: 26 / 20,
	},
	h4: {
		fontFamily: theme.typography.fontFamily,
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: '18px',
		lineHeight: 24 / 18,
	},
	h5: {
		fontFamily: theme.typography.fontFamily,
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: '16px',
		lineHeight: 20 / 16,
	},
	h6: {
		fontFamily: theme.typography.fontFamily,
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: '14px',
		lineHeight: 18 / 14,
	},
	body1: {
		fontFamily: theme.typography.fontFamily,
		fontWeight: theme.typography.fontWeightRegular,
		fontSize: '14px',
		lineHeight: 18 / 14,
	},
	body2: {
		fontFamily: theme.typography.fontFamily,
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: '14px',
		lineHeight: 18 / 14,
	},
	subtitle1: {
		fontFamily: theme.typography.fontFamily,
		fontWeight: theme.typography.fontWeightRegular,
		fontSize: '12px',
		lineHeight: 16 / 12,
	},
	subtitle2: {
		fontFamily: theme.typography.fontFamily,
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: '12px',
		lineHeight: 16 / 12,
	},
	button: {
		fontFamily: theme.typography.fontFamily,
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: '14px',
		lineHeight: 18 / 14,
	},
};

theme.components = {
	MuiCssBaseline: {
		styleOverrides: {
			'@global': {
				body: {
					fontFamily: theme.typography.body1.fontFamily,
				},
			},
		},
	},
	MuiButton: {
		defaultProps: {
			disableElevation: true,
			disableFocusRipple: true,
			disableTouchRipple: true,
			variant: 'contained',
		},
		styleOverrides: {
			/* Styles applied to the root element. */
			root: {
				boxSizing: 'border-box',
				minWidth: 64,
				margin: 0,
				height: 32,
				outline: 'none',
				padding: theme.spacing(1, 3),
				textTransform: 'none',
				fontFamily: theme.typography.button.fontFamily,
				fontSize: theme.typography.button.fontSize,
				lineHeight: '24px',
				borderRadius: 12,
			},
			/* Styles applied to the root element if `size="small"` and `variant="contained"`. */
			sizeSmall: {
				height: 24,
				padding: theme.spacing(0.5, 2),
				fontSize: '12px',
				lineHeight: '18px',
			},
			/* Styles applied to the root element if `size="large"` and `variant="contained"`. */
			sizeLarge: {
				height: 40,
				padding: theme.spacing(2, 4),
				fontSize: theme.typography.button.fontSize,
				lineHeight: '24px',
			},
			/* Styles applied to the root element if `color="inherit"`. */
			colorInherit: {
				color: theme.palette.common.white,
				backgroundColor: newPrimary,
				'& path': {
					fill: theme.palette.common.white,
				},
				'&:hover': {
					backgroundColor: newPrimaryHover,
					borderColor: newPrimaryHover,
					color: theme.palette.common.white,
					'& path': {
						fill: theme.palette.common.white,
					},
				},
				'&:active': {
					backgroundColor: newPrimaryActive,
					borderColor: newPrimaryActive,
					color: theme.palette.common.white,
					'& svg path': {
						fill: theme.palette.common.white,
					},
				},
				'&$disabled': {
					backgroundColor: theme.palette.grey[300],
					borderColor: theme.palette.grey[300],
					color: theme.palette.common.white,
					'& path': {
						fill: theme.palette.common.white,
					},
				},
			},
			/* Styles applied to the root element if `variant="contained"`. */
			contained: {
				color: theme.palette.common.white,
				backgroundColor: newPrimary,
				'& path': {
					fill: theme.palette.common.white,
				},
				'&:hover': {
					backgroundColor: newPrimaryHover,
					borderColor: newPrimaryHover,
				},
				'&:active': {
					backgroundColor: newPrimaryActive,
					borderColor: newPrimaryActive,
				},
				'&$disabled': {
					backgroundColor: theme.palette.grey[300],
					borderColor: theme.palette.grey[300],
					color: theme.palette.common.white,
				},
			},
			/* Styles applied to the root element if `variant="contained"` and `color="primary"`. */
			containedPrimary: {
				color: theme.palette.common.white,
				backgroundColor: newPrimary,
				border: `1px solid ${newPrimary}`,
				'& path': {
					fill: theme.palette.common.white,
				},
				'&:hover': {
					backgroundColor: newPrimaryHover,
					borderColor: newPrimaryHover,
				},
				'&:active': {
					backgroundColor: newPrimaryActive,
					borderColor: newPrimaryActive,
				},
				'&.Mui-disabled': {
					backgroundColor: theme.palette.grey[300],
					borderColor: theme.palette.grey[300],
					color: theme.palette.common.white,
				},
			},
			/* Styles applied to the root element if `variant="contained"` and `color="secondary"`. */
			containedSecondary: {
				color: theme.palette.grey[700],
				backgroundColor: theme.palette.grey[50],
				border: `1px solid ${theme.palette.grey[300]}`,
				borderColor: theme.palette.grey[300],
				'& path': {
					fill: theme.palette.common.black,
				},
				'&:hover': {
					backgroundColor: theme.palette.grey[100],
					borderColor: theme.palette.grey[300],
				},
				'&:active': {
					backgroundColor: theme.palette.grey[200],
					borderColor: theme.palette.grey[300],
				},
				'&$disabled': {
					color: theme.palette.grey[400],
					backgroundColor: theme.palette.grey[50],
					'& path': {
						fill: theme.palette.grey[400],
					},
				},
			},
			/* Styles applied to the root element if `variant="text"`. */
			text: {
				color: theme.palette.primary.dark,
				backgroundColor: 'transparent',
				border: '1px solid transparent',
				borderColor: 'transparent',
				'& path': {
					fill: theme.palette.primary[200],
				},
				'&:hover': {
					color: theme.palette.primary[300],
					backgroundColor: 'transparent',
					borderColor: 'transparent',
					'& path': {
						fill: theme.palette.primary[300],
					},
				},
				'&:active': {
					color: theme.palette.primary[200],
					backgroundColor: 'transparent',
					borderColor: 'transparent',
					'& svg path': {
						fill: theme.palette.primary[200],
					},
				},
				'&$disabled': {
					color: theme.palette.grey[400],
					backgroundColor: 'transparent',
					borderColor: 'transparent',
					'& path': {
						fill: theme.palette.grey[400],
					},
				},
			},
			/* Styles applied to the root element if `variant="text"` and `color="primary"`. */
			textPrimary: {
				color: theme.palette.primary[200],
				backgroundColor: 'transparent',
				'& path': {
					fill: theme.palette.primary[200],
				},
				'&:hover': {
					color: theme.palette.primary[300],
					backgroundColor: 'transparent',
					borderColor: 'transparent',
					'& path': {
						fill: theme.palette.primary[300],
					},
				},
				'&:active': {
					color: theme.palette.primary[200],
					backgroundColor: 'transparent',
					borderColor: 'transparent',
					'& svg path': {
						fill: theme.palette.primary[200],
					},
				},
				'&$disabled': {
					color: theme.palette.grey[400],
					backgroundColor: 'transparent',
					borderColor: 'transparent',
					'& path': {
						fill: theme.palette.grey[400],
					},
				},
			},
			/* Styles applied to the root element if `variant="text"` and `color="secondary"`. */
			textSecondary: {
				color: theme.palette.grey[700],
				backgroundColor: 'transparent',
				border: '1px solid transparent',
				borderColor: 'transparent',
				'& path': {
					fill: theme.palette.common.black,
				},
				'&:hover': {
					color: theme.palette.grey[700],
					backgroundColor: theme.palette.grey[100],
					borderColor: theme.palette.grey[100],
					'& path': {
						fill: theme.palette.common.black,
					},
				},
				'&:active': {
					color: theme.palette.grey[700],
					backgroundColor: theme.palette.grey[200],
					borderColor: theme.palette.grey[200],
					'& svg path': {
						fill: theme.palette.common.black,
					},
				},
				'&$disabled': {
					color: theme.palette.grey[400],
					backgroundColor: 'transparent',
					borderColor: 'transparent',
					'& path': {
						fill: theme.palette.grey[400],
					},
				},
			},
			/* Styles applied to the root element if `variant="outlined"`. */
			outlined: {
				color: newPrimary,
				backgroundColor: 'transparent',
				border: `1px solid ${newPrimary}`,
				borderColor: newPrimary,
				'& path': {
					fill: newPrimary,
				},
				'&:hover': {
					color: theme.palette.common.white,
					backgroundColor: newPrimaryHover,
					borderColor: newPrimaryHover,
					'& path': {
						fill: theme.palette.common.white,
					},
				},
				'&:active': {
					color: theme.palette.common.white,
					backgroundColor: newPrimaryActive,
					borderColor: newPrimaryActive,
					'& path': {
						fill: theme.palette.common.white,
					},
				},
				'&$disabled': {
					color: theme.palette.common.white,
					backgroundColor: theme.palette.grey[300],
					borderColor: theme.palette.grey[300],
					'& path': {
						fill: theme.palette.common.white,
					},
				},
			},
			/* Styles applied to the root element if `variant="outlined"` and `color="primary"`. */
			outlinedPrimary: {
				color: newPrimary,
				backgroundColor: 'transparent',
				border: `1px solid ${newPrimary}`,
				borderColor: newPrimary,
				'& path': {
					fill: newPrimary,
				},
				'&:hover': {
					color: theme.palette.common.white,
					backgroundColor: newPrimaryHover,
					borderColor: newPrimaryHover,
					'& path': {
						fill: theme.palette.common.white,
					},
				},
				'&:active': {
					color: theme.palette.common.white,
					backgroundColor: newPrimaryActive,
					borderColor: newPrimaryActive,
					'& path': {
						fill: theme.palette.common.white,
					},
				},
				'&$disabled': {
					color: theme.palette.common.white,
					backgroundColor: theme.palette.grey[300],
					borderColor: theme.palette.grey[300],
					'& path': {
						fill: theme.palette.common.white,
					},
				},
			},
			/* Styles applied to the root element if `variant="outlined"` and `color="secondary"`. */
			outlinedSecondary: {
				color: theme.palette.grey[700],
				backgroundColor: theme.palette.grey[50],
				border: `1px solid ${theme.palette.grey[300]}`,
				borderColor: theme.palette.grey[300],
				'& path': {
					fill: theme.palette.common.black,
				},
				'&:hover': {
					color: theme.palette.grey[700],
					backgroundColor: theme.palette.grey[100],
					borderColor: theme.palette.grey[300],
					'& path': {
						fill: theme.palette.common.black,
					},
				},
				'&:active': {
					color: theme.palette.grey[700],
					backgroundColor: theme.palette.grey[200],
					borderColor: theme.palette.grey[300],
					'& path': {
						fill: theme.palette.common.black,
					},
				},
				'&$disabled': {
					color: theme.palette.grey[400],
					backgroundColor: theme.palette.grey[50],
					borderColor: theme.palette.grey[300],
					'& path': {
						fill: theme.palette.grey[400],
					},
				},
			},
			/* Styles applied to the startIcon element if supplied. */
			startIcon: {
				display: 'inherit',
				marginRight: 8,
				marginLeft: 0,
				'&$iconSizeSmall': {
					marginLeft: 0,
				},
			},
			/* Styles applied to the endIcon element if supplied. */
			endIcon: {
				display: 'inherit',
				marginRight: 0,
				marginLeft: 8,
				'&$iconSizeSmall': {
					marginRight: 0,
				},
			},
		},
	},
	MuiButtonGroup: {
		defaultProps: {
			disableRipple: true,
			disableElevation: true,
			disableFocusRipple: true,
		},
		styleOverrides: {
			/* Styles applied to the children if `align="horizontal"`. */
			groupedHorizontal: {
				'&:not(:first-child)': {
					marginLeft: -1,
				},
				'&:not(:last-child)': {
					borderRight: '1px solid transparent',
				},
			},
			/* Styles applied to the children if `align="vertical"`. */
			groupedVertical: {
				'&:not(:first-child)': {
					marginTop: -1,
				},
				'&:not(:last-child)': {
					borderBottom: '1px solid transparent',
				},
			},
			/* Styles applied to the children if `variant="text"`. */
			groupedText: {
				border: '1px solid transparent',
			},
			/* Styles applied to the children if `variant="text"` and `orientation="horizontal"`. */
			groupedTextHorizontal: {
				'&:not(:last-child)': {
					borderRight: '1px solid transparent',
				},
			},
			/* Styles applied to the children if `variant="text"` and `orientation="vertical"`. */
			groupedTextVertical: {
				'&:not(:last-child)': {
					borderBottom: '1px solid transparent',
				},
			},
			/* Styles applied to the children if `variant="text"` and `color="primary"`. */
			groupedTextPrimary: {
				'&:not(:last-child)': {
					borderColor: 'transparent',
				},
			},
			/* Styles applied to the children if `variant="text"` and `color="secondary"`. */
			groupedTextSecondary: {
				'&:not(:last-child)': {
					borderColor: 'transparent',
				},
			},
			/* Styles applied to the children if `variant="outlined"`. */
			groupedOutlined: {
				'&$disabled': {
					color: theme.palette.grey[400],
					backgroundColor: 'transparent',
					borderColor: theme.palette.grey[300],
					'& path': {
						fill: theme.palette.grey[400],
					},
				},
			},
			/* Styles applied to the children if `variant="outlined"` and `color="secondary"`. */
			groupedOutlinedSecondary: {
				'&:hover, &:active': {
					borderColor: theme.palette.grey[300],
				},
			},
			/* Styles applied to the children if `variant="contained"` and `orientation="horizontal"`. */
			groupedContainedHorizontal: {
				'&:not(:last-child)': {
					'&$disabled': {
						border: `1px solid ${theme.palette.grey[300]}`,
						borderRight: '1px solid transparent',
					},
				},
			},
			/* Styles applied to the children if `variant="contained"` and `orientation="vertical"`. */
			groupedContainedVertical: {
				'&:not(:last-child)': {
					'&$disabled': {
						border: `1px solid ${theme.palette.grey[300]}`,
						borderBottom: '1px solid transparent',
					},
				},
			},
			/* Styles applied to the children if `variant="contained"` and `color="primary"`. */
			groupedContainedPrimary: {
				'&:not(:last-child)': {
					borderColor: theme.palette.primary.main,
					'&:hover': {
						borderColor: theme.palette.primary[400],
					},
					'&:active': {
						borderColor: theme.palette.primary[600],
					},
				},
			},
			/* Styles applied to the children if `variant="contained"` and `color="secondary"`. */
			groupedContainedSecondary: {
				'&:not(:last-child)': {
					borderColor: theme.palette.grey[300],
				},
				'&:hover': {
					borderColor: theme.palette.grey[300],
				},
				'&:active': {
					borderColor: theme.palette.grey[300],
				},
			},
		},
	},
	// MuiInputLabel: {
	//   defaultProps: {
	//     shrink: true,
	//   },
	//   styleOverrides: {
	//     /* Styles applied to the root element. */
	//     root: {
	//       fontSize: theme.typography.fontSize,
	//       fontWeight: theme.typography.fontWeightMedium,
	//       lineHeight: '18px',
	//       color: theme.palette.grey[600],
	//       border: 'none',
	//       '&$focused': {
	//         color: theme.palette.grey[600],
	//       },
	//       '&$disabled': {
	//         color: theme.palette.grey[600],
	//       },
	//       '&$error': {
	//         border: 'none',
	//         color: theme.palette.grey[600],
	//       },
	//     },
	//     asterisk: {
	//       border: 'none',
	//       color: theme.palette.error[500],
	//     },
	//     /* Styles applied to the children if `shrink={true}`. */
	//     shrink: {
	//       transform: 'translate(0, -4px)',
	//     },
	//     /* Styles applied to the children if `size="small"`. */
	//     // TODO: commented out when migrated to v5
	//     // marginDense: {
	//     //   fontSize: '12px',
	//     //   lineHeight: '18px',
	//     // },
	//   },
	// },
	// MuiInputBase: {
	//   styleOverrides: {
	//     root: {
	//       '&$disabled': {
	//         color: theme.palette.grey[700],
	//       },
	//     },
	//     input: {
	//       padding: 0,
	//       '&::placeholder': {
	//         color: theme.palette.text.placeholder,
	//       },
	//     },
	//     // TODO: commented out when migrated to v5
	//     // marginDense: {
	//     //   paddingTop: 8,
	//     // },
	//   },
	// },
	MuiInput: {
		defaultProps: {
			disableUnderline: true,
		},
		styleOverrides: {
			/* Styles applied to the root element. */
			root: {
				backgroundColor: theme.palette.grey[50],
				border: `1px solid ${theme.palette.grey[300]}`,
				borderRadius: theme.shape.borderRadius,
				padding: '10px 8px',
				fontSize: '16px',
				lineHeight: '18px',
				color: theme.palette.text.primary,
				height: 40,
				'&:hover': {
					border: `1px solid ${theme.palette.primary[200]}`,
				},
				'&$focused': {
					border: `2px solid ${theme.palette.primary[200]}`,
					padding: '9px 7px',
				},
				'&$disabled': {
					backgroundColor: theme.palette.grey[200],
					'& input::placeholder': {
						color: theme.palette.text.disabled,
					},
				},
			},
			error: {
				border: `1px solid ${theme.palette.error[600]}`,
			},
			/* Styles applied to the children if `multiline={true}`. */
			multiline: {
				height: 'auto',
			},
			/* Styles applied to the children if `size="small"`. */
			// TODO: commented out when migrated to v5
			// marginDense: {
			//   padding: '8px 8px',
			//   fontSize: theme.typography.fontSize,
			//   lineHeight: '16px',
			//   paddingTop: theme.spacing(2),
			//   height: 32,
			// },
			/* Styles applied to the children if `disabled={true}`. */
			disabled: {
				'&:hover': {
					'&$root': {
						border: `1px solid ${theme.palette.grey[300]}`,
					},
				},
			},
			/* Styles applied to the root element if the component is a descendant of `FormControl`. */
			formControl: {
				'label + &': {
					marginTop: 18,
				},
			},
		},
	},
	// MuiFormHelperText: {
	//   styleOverrides: {
	//     /* Styles applied to the root element. */
	//     root: {
	//       // TODO: commented out when migrated to v5
	//       // color: theme.palette.text.hint,
	//       fontFamily: theme.typography.fontFamily,
	//       fontSize: '12px',
	//       lineHeight: '18px',
	//       marginTop: 2,
	//       border: 'none',
	//       '&$disabled': {
	//         color: theme.palette.text.disabled,
	//       },
	//       '&$error': {
	//         border: 'none',
	//         color: theme.palette.error[600],
	//       },
	//     },
	//   },
	// },
	// MuiCheckbox: {
	//   defaultProps: {
	//     color: 'primary',
	//   },
	//   styleOverrides: {
	//     /* Styles applied to the root element. */
	//     root: {
	//       padding: 8,
	//       color: theme.palette.grey[500],
	//       backgroundColor: 'transparent',
	//       border: 'none',
	//       borderColor: `transparent`,
	//       '& path': {
	//         fill: theme.palette.grey[500],
	//       },
	//       '&:hover': {
	//         color: theme.palette.primary[200],
	//         backgroundColor: theme.palette.grey[300],
	//         '& path': {
	//           fill: theme.palette.primary[200],
	//         },
	//       },
	//       '&$disabled': {
	//         color: theme.palette.grey[300],
	//         '& path': {
	//           fill: theme.palette.grey[300],
	//         },
	//       },
	//       '&$checked': {
	//         color: theme.palette.primary[200],
	//         '&:hover': {
	//           backgroundColor: theme.palette.grey[300],
	//         },
	//         '& path': {
	//           fill: theme.palette.primary[200],
	//         },
	//       },
	//     },
	//     /* Styles applied to the root element if `color="primary"`. */
	//     colorPrimary: {
	//       padding: 8,
	//       color: theme.palette.grey[500],
	//       backgroundColor: 'transparent',
	//       border: 'none',
	//       borderColor: `transparent`,
	//       borderRadius: '50%',
	//       '& path': {
	//         fill: theme.palette.grey[500],
	//       },
	//       '&:hover': {
	//         color: theme.palette.primary[200],
	//         backgroundColor: theme.palette.grey[300],
	//         borderColor: `transparent`,
	//         borderRadius: '50%',
	//       },
	//       '&$checked': {
	//         color: theme.palette.primary[200],
	//         borderColor: `transparent`,
	//         borderRadius: '50%',
	//         '&:hover': {
	//           backgroundColor: theme.palette.grey[300],
	//         },
	//       },
	//       '&$disabled': {
	//         color: theme.palette.grey[300],
	//         backgroundColor: 'transparent',
	//         borderColor: `transparent`,
	//         '& path': {
	//           fill: theme.palette.grey[300],
	//         },
	//       },
	//     },
	//     /* Styles applied to the root element if `color="secondary"`. */
	//     colorSecondary: {
	//       color: theme.palette.grey[500],
	//       backgroundColor: 'transparent',
	//       border: 'none',
	//       borderColor: 'transparent',
	//       borderRadius: '50%',
	//       '& path': {
	//         fill: theme.palette.grey[500],
	//       },
	//       '&:hover': {
	//         color: theme.palette.secondary.light,
	//         borderRadius: '50%',
	//       },
	//       '&$checked': {
	//         color: theme.palette.secondary.main,
	//         '&:hover': {
	//           backgroundColor: theme.palette.grey[300],
	//         },
	//       },
	//       '&$disabled': {
	//         color: theme.palette.grey[300],
	//       },
	//     },
	//   },
	// },
	MuiRadio: {
		defaultProps: {
			color: 'primary',
		},
		styleOverrides: {
			/* Styles applied to the root element. */
			root: {
				padding: 0,
				marginRight: '8px',
				color: theme.palette.grey[500],
				backgroundColor: 'transparent',
				border: 'none',
				borderColor: `transparent`,
				'& path': {
					fill: theme.palette.grey[500],
				},
				'&:hover': {
					color: theme.palette.primary[200],
					backgroundColor: 'unset',
					'& path': {
						fill: theme.palette.primary[200],
					},
				},
				'&$disabled': {
					color: theme.palette.grey[300],
					'& path': {
						fill: theme.palette.grey[300],
					},
				},
				'&$checked': {
					color: theme.palette.primary[200],
					'&:hover': {
						backgroundColor: theme.palette.grey[300],
					},
					'& path': {
						fill: theme.palette.primary[200],
					},
				},
			},
			/* Styles applied to the root element if `color="primary"`. */
			colorPrimary: {
				padding: 0,
				marginRight: '8px',
				color: theme.palette.grey[500],
				backgroundColor: 'transparent',
				border: 'none',
				borderColor: `transparent`,
				borderRadius: '50%',
				'& path': {
					fill: theme.palette.grey[500],
				},
				'&:hover': {
					color: theme.palette.primary[200],
					backgroundColor: 'unset',
					borderColor: `transparent`,
					borderRadius: '50%',
				},
				'&$checked': {
					color: theme.palette.primary[200],
					borderColor: `transparent`,
					borderRadius: '50%',
					'&:hover': {
						backgroundColor: theme.palette.grey[300],
					},
				},
				'&$disabled': {
					color: theme.palette.grey[300],
					backgroundColor: 'transparent',
					borderColor: `transparent`,
					'& path': {
						fill: theme.palette.grey[300],
					},
				},
			},
			/* Styles applied to the root element if `color="secondary"`. */
			colorSecondary: {
				color: theme.palette.grey[500],
				backgroundColor: 'transparent',
				border: 'none',
				borderColor: 'transparent',
				borderRadius: '50%',
				'& path': {
					fill: theme.palette.grey[500],
				},
				'&:hover': {
					color: theme.palette.secondary.light,
					borderRadius: '50%',
				},
				'&$checked': {
					color: theme.palette.secondary.main,
					'&:hover': {
						backgroundColor: theme.palette.grey[300],
					},
				},
				'&$disabled': {
					color: theme.palette.grey[300],
				},
			},
		},
	},
	MuiSelect: {
		styleOverrides: {
			// TODO: changed from root to select, maybe it won't work
			select: {
				'&$disabled': {
					backgroundColor: theme.palette.grey[200],
					color: theme.palette.text.disabled,
				},
			},
			icon: {
				fill: theme.palette.grey[700],
				'&$disabled': {
					fill: '#A5AAC0',
				},
			},
			iconOpen: {
				transform: 'none',
			},
		},
	},
	MuiPaper: {
		styleOverrides: {
			elevation1: {
				backgroundColor: theme.palette.common.white,
				border: '1px solid #E0E7EE',
				boxSizing: 'border-box',
				boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.14)',
				borderRadius: 6,
			},
			elevation8: {
				backgroundColor: theme.palette.common.white,
				border: '1px solid #E0E7EE',
				boxSizing: 'border-box',
				boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.14)',
				borderRadius: 6,
			},
		},
	},
	MuiMenu: {
		styleOverrides: {
			/* Styles applied to the `Paper` component. */
			paper: {
				maxWidth: 400,
				maxHeight: 480,
				overflowY: 'auto',
			},
		},
	},
	MuiMenuItem: {
		styleOverrides: {
			/* Styles applied to the root element. */
			root: {
				minHeight: 40,
				paddingTop: 11,
				paddingBottom: 11,
				paddingLeft: 7,
				paddingRight: 7,
				fontSize: theme.typography.fontSize,
				lineHeight: '18px',
				'&:hover': {
					backgroundColor: theme.palette.grey[100],
				},
			},
		},
	},
	MuiFormLabel: {
		styleOverrides: {
			/* Styles applied to the root element. */
			root: {
				color: theme.palette.text.primary,
				...theme.typography.h6,
				lineHeight: '32px',
				padding: 0,
				'&$focused': {
					color: theme.palette.grey[600],
				},
				'&$disabled': {
					color: theme.palette.text.disabled,
				},
				'&$error': {
					color: theme.palette.error.main,
				},
			},
			/* Styles applied to the root element if the color is secondary. */
			colorSecondary: {
				'&$focused': {
					color: theme.palette.secondary.main,
				},
			},
			/* Styles applied to the asterisk element. */
			asterisk: {
				'&$error': {
					color: theme.palette.error.main,
				},
			},
		},
	},
	MuiFormControlLabel: {
		styleOverrides: {
			/* Styles applied to the root element. */
			root: {
				marginLeft: 0,
				padding: '8px 22px 8px 12px',
				borderRadius: '12px',
				outline: `1px solid #2628421F`,
				'&:has(span.Mui-checked)': {
					outline: `2px solid ${theme.palette.primary.main}`,
				},
				'&:hover': {
					background: '#2628420A',
				},
				'&:last-child': {
					marginRight: 0,
				},
			},
			/* Styles applied to the label's Typography component. */
			label: {
				fontSize: theme.typography.fontSize,
				lineHeight: '18px',
				color: theme.palette.text.primary,
			},
			/* Pseudo-class applied to the root element if `disabled={true}`. */
			disabled: {
				color: theme.palette.grey[300],
			},
		},
	},
	MuiIconButton: {
		defaultProps: {
			disableRipple: true,
			disableFocusRipple: true,
			disableTouchRipple: true,
		},
		styleOverrides: {
			root: {
				borderRadius: '50%',
				padding: 6,
				color: theme.palette.grey[700],
				backgroundColor: 'transparent',
				border: '1px solid transparent',
				borderColor: 'transparent',
				'& path': {
					fill: theme.palette.grey[700],
				},
				'&:hover': {
					borderRadius: '50%',
					backgroundColor: theme.palette.grey[100],
					borderColor: theme.palette.grey[100],
				},
				'&:active': {
					borderRadius: '50%',
					backgroundColor: theme.palette.grey[200],
					borderColor: theme.palette.grey[200],
				},
				'&$disabled': {
					backgroundColor: 'transparent',
					borderColor: 'transparent',
					'& path': {
						fill: theme.palette.grey[400],
					},
				},
			},
			colorInherit: {
				color: theme.palette.primary[200],
				backgroundColor: 'transparent',
				borderColor: 'transparent',
				borderRadius: theme.shape.borderRadius,
				'& path': {
					fill: theme.palette.primary[200],
				},
				'&:hover': {
					color: theme.palette.primary[300],
					borderRadius: theme.shape.borderRadius,
					backgroundColor: 'transparent',
					borderColor: 'transparent',
					'& path': {
						fill: theme.palette.primary[300],
					},
				},
				'&:active': {
					color: theme.palette.primary[200],
					borderRadius: theme.shape.borderRadius,
					backgroundColor: 'transparent',
					borderColor: 'transparent',
					'& svg path': {
						fill: theme.palette.primary[200],
					},
				},
				'&$disabled': {
					backgroundColor: 'transparent',
					borderColor: 'transparent',
					'& path': {
						fill: theme.palette.grey[400],
					},
				},
			},
			// /* Styles applied to the root element if `color="primary"`. */
			colorPrimary: {
				color: theme.palette.grey[50],
				backgroundColor: newPrimary,
				borderRadius: theme.shape.borderRadius,
				borderColor: theme.palette.primary[500],
				'& path': {
					fill: theme.palette.common.white,
				},
				'&:hover': {
					borderRadius: theme.shape.borderRadius,
					backgroundColor: theme.palette.primary[400],
					borderColor: theme.palette.primary[400],
				},
				'&:active': {
					borderRadius: theme.shape.borderRadius,
					backgroundColor: theme.palette.primary[600],
					borderColor: theme.palette.primary[600],
				},
				'&$disabled': {
					backgroundColor: theme.palette.grey[300],
					borderColor: theme.palette.grey[300],
					'& path': {
						fill: theme.palette.common.white,
					},
				},
			},
			colorSecondary: {
				color: theme.palette.grey[700],
				backgroundColor: theme.palette.grey[50],
				border: `1px solid ${theme.palette.grey[300]}`,
				borderRadius: theme.shape.borderRadius,
				borderColor: theme.palette.grey[300],
				'& path:': {
					fill: theme.palette.common.black,
				},
				'&:hover': {
					borderRadius: theme.shape.borderRadius,
					backgroundColor: theme.palette.grey[100],
					borderColor: theme.palette.grey[300],
				},
				'&:active': {
					borderRadius: theme.shape.borderRadius,
					backgroundColor: theme.palette.grey[200],
					borderColor: theme.palette.grey[300],
				},
				'&$disabled': {
					backgroundColor: theme.palette.grey[50],
					borderColor: theme.palette.grey[300],
					'& path': {
						fill: theme.palette.grey[400],
					},
				},
			},
		},
	},
	MuiSwitch: {
		defaultProps: {
			disableRipple: true,
			disableFocusRipple: true,
			disableTouchRipple: true,
			color: 'primary',
		},
		styleOverrides: {
			/* Styles applied to the root element. */
			root: {
				width: 39,
				height: 24,
				padding: 0,
				overflow: 'visible',
			},
			/* Styles applied to the internal `SwitchBase` component's `root` class. */
			switchBase: {
				padding: 0,
				border: 'none',
				top: 1.5,
				left: 1.5,
				borderColor: theme.palette.common.white,
				backgroundColor: theme.palette.common.white,
				borderRadius: '50%',
				'&:hover': {
					borderColor: theme.palette.common.white,
					backgroundColor: theme.palette.common.white,
					'& + $track': {
						borderColor: theme.palette.primary[200],
					},
				},
				'&:active + $track': {
					borderColor: theme.palette.primary[200],
				},
				'&$checked': {
					transform: 'translateX(15px)',
					color: theme.palette.common.white,
					'& + $track': {
						backgroundColor: theme.palette.primary[200],
						borderColor: theme.palette.primary[300],
						opacity: 1,
					},
					'&:hover + $track': {
						backgroundColor: theme.palette.primary[100],
						borderColor: theme.palette.primary[300],
						opacity: 1,
					},
					'&:active + $track': {
						backgroundColor: theme.palette.primary[300],
						borderColor: theme.palette.primary[300],
						opacity: 1,
					},
					'&$disabled': {
						'& + $track': {
							backgroundColor: '#A6CAE3',
							borderColor: '#85B9DE',
						},
						'& $thumb': {
							backgroundColor: theme.palette.common.white,
						},
					},
				},
				'&$disabled': {
					'& + $track': {
						backgroundColor: theme.palette.grey[200],
						borderColor: theme.palette.grey[300],
						opacity: 1,
					},
					'& $thumb': {
						backgroundColor: theme.palette.common.white,
					},
				},
			},
			/* Styles applied to the internal SwitchBase component's root element if `color="primary"`. */
			colorPrimary: {
				'&$disabled': {
					'& + $track': {
						backgroundColor: '#A6CAE3',
						borderColor: '#85B9DE',
						opacity: 1,
					},
					'& $thumb': {
						backgroundColor: theme.palette.common.white,
					},
				},
				'&$checked': {
					'& + $track': {
						backgroundColor: theme.palette.primary[200],
						borderColor: theme.palette.primary[300],
						opacity: 1,
					},
				},
			},
			/* Styles applied to the internal SwitchBase component's root element if `color="secondary"`. */
			colorSecondary: {
				'&$checked': {
					color: theme.palette.primary[200],
					'&:hover': {
						backgroundColor: theme.palette.primary[100],
					},
					'& + $track': {
						backgroundColor: theme.palette.primary[200],
						borderColor: theme.palette.primary[300],
						opacity: 1,
					},
				},
				'&$disabled': {
					'& + $track': {
						backgroundColor: '#A6CAE3',
						borderColor: '#85B9DE',
						opacity: 1,
					},
					'& $thumb': {
						backgroundColor: theme.palette.common.white,
					},
				},
			},
			/* Styles applied to the root element if `size="small"`. */
			sizeSmall: {
				width: 26,
				height: 16,
				padding: 0,
				'& $thumb': {
					width: 14,
					height: 14,
				},
				'& $switchBase': {
					top: 1,
					left: 1,
					padding: 0,
					'&$checked': {
						transform: 'translateX(10px)',
					},
				},
			},
			/* Styles used to create the thumb passed to the internal `SwitchBase` component `icon` prop. */
			thumb: {
				width: 21,
				height: 21,
				boxShadow: 'none',
				backgroundColor: theme.palette.common.white,
				'&$disabled': {
					backgroundColor: '#DAE0E7',
				},
			},
			/* Styles applied to the track element. */
			track: {
				borderRadius: 13,
				border: '1px solid #B7BFC8',
				borderColor: '#B7BFC8',
				backgroundColor: theme.palette.grey[300],
				opacity: 1,
				transition: theme.transitions.create(['background-color', 'border']),
				width: '100%',
				height: '100%',
			},
		},
	},
	MuiTable: {
		defaultProps: {
			stickyHeader: true,
		},
	},
	MuiTableContainer: {
		styleOverrides: {
			root: {
				border: `1px solid ${theme.palette.grey[300]}`,
				borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
			},
		},
	},
	MuiTableRow: {
		styleOverrides: {
			root: {
				borderBottom: `1px solid ${theme.palette.grey[300]}`,
				backgroundColor: theme.palette.grey[50],
				'&$hover:hover, &$selected, &$selected:hover': {
					backgroundColor: theme.palette.grey[200],
					cursor: 'pointer',
				},
				'&:last-child': {
					borderBottom: 'none',
				},
			},
			head: {
				borderBottom: `1px solid ${theme.palette.grey[300]}`,
			},
		},
	},
	MuiTableCell: {
		styleOverrides: {
			root: {
				height: 48,
				padding: theme.spacing(4),
				fontSize: '14px',
				lineHeight: '18px',
				fontWeight: theme.typography.fontWeightRegular,
				borderBottom: 'none',
				color: theme.palette.text.primary,
				'&:first-child': {
					paddingLeft: theme.spacing(4),
				},
				'&:last-child': {
					paddingRight: theme.spacing(4),
				},
			},
			head: {
				backgroundColor: theme.palette.grey[100],
				fontSize: '14px',
				lineHeight: '18px',
				fontWeight: theme.typography.fontWeightMedium,
			},
			stickyHeader: {
				backgroundColor: theme.palette.grey[100],
				left: 'none',
			},
			alignRight: {
				flexDirection: 'row',
			},
		},
	},
	MuiTablePagination: {
		defaultProps: {
			labelRowsPerPage: 'Show:',
			labelDisplayedRows: ({ from, to, count }) => `${from}-${to} results of ${count}`,
		},
		styleOverrides: {
			root: {
				border: `1px solid ${theme.palette.grey[300]}`,
				borderTop: 'none',
				borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
			},
			input: {
				border: `1px solid ${theme.palette.grey[300]}`,
				height: '40px',
				borderRadius: theme.shape.borderRadius,
			},
			// TODO: commented out when migrated to v5
			// caption: {
			//   fontSize: '13px',
			// },
			select: {
				paddingLeft: '12px',
				paddingRight: '16px',
				textAlign: 'center',
			},
		},
	},
	MuiLink: {
		styleOverrides: {
			root: {
				color: theme.palette.primary[200],
				'&:hover': {
					color: theme.palette.primary[300],
				},
			},
		},
	},
	MuiPopover: {
		styleOverrides: {
			root: {
				pointerEvents: 'all',
			},
		},
	},
	// MuiAutocomplete: {
	//   defaultProps: {
	//     autoComplete: true,
	//     selectOnFocus: true,
	//     handleHomeEndKeys: true,
	//     noOptionsText: 'Nothing found',
	//   },
	//   styleOverrides: {
	//     root: {
	//       width: 384,
	//     },
	//     inputRoot: {
	//       flexWrap: 'nowrap',
	//       '&[class*="MuiInput-root"]': {
	//         paddingBottom: 10,
	//       },
	//     },
	//     input: {
	//       padding: 0,
	//     },
	//     clearIndicator: {
	//       color: theme.palette.common.black,
	//       backgroundColor: 'transparent',
	//       borderRadius: 0,
	//       '&:hover': {
	//         color: theme.palette.common.black,
	//         backgroundColor: 'transparent',
	//         borderColor: 'transparent',
	//       },
	//     },
	//     popupIndicator: {
	//       color: theme.palette.common.black,
	//       backgroundColor: 'transparent',
	//       borderRadius: 0,
	//       marginRight: theme.spacing(1),
	//       '&:hover': {
	//         color: theme.palette.common.black,
	//         backgroundColor: 'transparent',
	//         borderColor: 'transparent',
	//       },
	//     },
	//     option: {
	//       minHeight: 40,
	//       paddingTop: 11,
	//       paddingBottom: 11,
	//       paddingLeft: 7,
	//       paddingRight: 7,
	//       fontSize: theme.typography.fontSize,
	//       lineHeight: '18px',
	//       '&[aria-selected="true"]': {
	//         backgroundColor: theme.palette.grey[100],
	//       },
	//       '&[data-focus="true"]': {
	//         backgroundColor: theme.palette.grey[100],
	//       },
	//       '&:active': {
	//         backgroundColor: theme.palette.grey[100],
	//       },
	//       '&[aria-disabled="true"]': {
	//         opacity: theme.palette.action.disabledOpacity,
	//         pointerEvents: 'none',
	//       },
	//     },
	//   },
	// },
	MuiTypography: {
		styleOverrides: {
			/* Styles applied to the root element if `variant="body2"`. */
			body2: theme.typography.body2,
			/* Styles applied to the root element if `variant="body1"`. */
			body1: theme.typography.body1,
			/* Styles applied to the root element if `variant="caption"`. */
			caption: theme.typography.caption,
			/* Styles applied to the root element if `variant="button"`. */
			button: theme.typography.button,
			/* Styles applied to the root element if `variant="h1"`. */
			h1: theme.typography.h1,
			/* Styles applied to the root element if `variant="h2"`. */
			h2: theme.typography.h2,
			/* Styles applied to the root element if `variant="h3"`. */
			h3: theme.typography.h3,
			/* Styles applied to the root element if `variant="h4"`. */
			h4: theme.typography.h4,
			/* Styles applied to the root element if `variant="h5"`. */
			h5: theme.typography.h5,
			/* Styles applied to the root element if `variant="h6"`. */
			h6: theme.typography.h6,
			/* Styles applied to the root element if `variant="subtitle1"`. */
			subtitle1: theme.typography.subtitle1,
			/* Styles applied to the root element if `variant="subtitle2"`. */
			subtitle2: theme.typography.subtitle2,
		},
	},
	MuiTooltip: {
		defaultProps: {
			arrow: true,
		},
		styleOverrides: {
			/* Styles applied to the tooltip (label wrapper) element. */
			tooltip: {
				backgroundColor: theme.palette.grey[700],
				padding: theme.spacing(2),
				fontSize: '12px',
				lineHeight: '19px',
				fontWeight: theme.typography.fontWeightRegular,
			},
			/* Styles applied to the arrow element. */
			arrow: {
				width: '12px',
				height: '8.5px' /* = width / sqrt(2) = (length of the hypotenuse) */,
				color: theme.palette.grey[700],
			},
			/* Styles applied to the tooltip (label wrapper) element if the tooltip is opened by touch. */
			touch: {
				padding: theme.spacing(2),
				fontSize: '12px',
				lineHeight: '19px',
				fontWeight: theme.typography.fontWeightRegular,
			},
			/* Styles applied to the tooltip (label wrapper) element if `placement` contains "left". */
			tooltipPlacementLeft: {
				transformOrigin: 'right center',
				margin: '0 8px',
				[theme.breakpoints.up('sm')]: {
					margin: '0 8px',
				},
			},
			/* Styles applied to the tooltip (label wrapper) element if `placement` contains "right". */
			tooltipPlacementRight: {
				transformOrigin: 'left center',
				margin: '0 8px',
				[theme.breakpoints.up('sm')]: {
					margin: '0 8px',
				},
			},
			/* Styles applied to the tooltip (label wrapper) element if `placement` contains "top". */
			tooltipPlacementTop: {
				transformOrigin: 'center bottom',
				margin: '8px 0',
				[theme.breakpoints.up('sm')]: {
					margin: '8px 0',
				},
			},
			/* Styles applied to the tooltip (label wrapper) element if `placement` contains "bottom". */
			tooltipPlacementBottom: {
				transformOrigin: 'center top',
				margin: '8px 0',
				[theme.breakpoints.up('sm')]: {
					margin: '8px 0',
				},
			},
		},
	},
	MuiAlert: {
		defaultProps: {
			variant: 'standard',
		},
		styleOverrides: {
			/* Styles applied to the root element. */
			root: {
				padding: theme.spacing(6, 4, 6, 6),
				height: '68px',
				fontSize: '18px',
				lineHeight: '20px',
				fontWeight: theme.typography.fontWeightMedium,
				alignItems: 'center',
				boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.12)',
			},
			/* Styles applied to the root element if `variant="standard"` and `color="success"`. */
			standardSuccess: {
				color: theme.palette.text.primary,
				border: '1px solid #7FDEB6',
				backgroundColor: '#E5F8EE',
			},
			/* Styles applied to the root element if `variant="standard"` and `color="info"`. */
			standardInfo: {
				color: theme.palette.text.primary,
				border: '1px solid #5DD8EC',
				backgroundColor: '#D9F5FB',
			},
			/* Styles applied to the root element if `variant="standard"` and `color="warning"`. */
			standardWarning: {
				color: theme.palette.text.primary,
				border: '1px solid #FAE0B5',
				backgroundColor: '#FEF8F0',
			},
			/* Styles applied to the root element if `variant="standard"` and `color="error"`. */
			standardError: {
				color: theme.palette.text.primary,
				border: '1px solid rgba(248, 169, 173, 0.86)',
				backgroundColor: '#FDEDEE',
			},
			/* Styles applied to the root element if `variant="outlined"` and `color="success"`. */
			outlinedSuccess: {
				color: theme.palette.text.primary,
				border: '1px solid #7FDEB6',
			},
			/* Styles applied to the root element if `variant="outlined"` and `color="info"`. */
			outlinedInfo: {
				color: theme.palette.text.primary,
				border: '1px solid #5DD8EC',
			},
			/* Styles applied to the root element if `variant="outlined"` and `color="warning"`. */
			outlinedWarning: {
				color: theme.palette.text.primary,
				border: '1px solid #FAE0B5',
			},
			/* Styles applied to the root element if `variant="outlined"` and `color="error"`. */
			outlinedError: {
				color: theme.palette.text.primary,
				border: '1px solid rgba(248, 169, 173, 0.86)',
			},
			/* Styles applied to the root element if `variant="filled"` and `color="success"`. */
			filledSuccess: {
				backgroundColor: '#7FDEB6',
				'& $action': {
					'& path, &:active path': {
						fill: theme.palette.grey[50],
					},
				},
			},
			/* Styles applied to the root element if `variant="filled"` and `color="info"`. */
			filledInfo: {
				backgroundColor: '#5DD8EC',
				'& $action': {
					'& path, &:active path': {
						fill: theme.palette.grey[50],
					},
				},
			},
			/* Styles applied to the root element if `variant="filled"` and `color="warning"`. */
			filledWarning: {
				backgroundColor: '#FAE0B5',
				'& $action': {
					'& path, &:active path': {
						fill: theme.palette.grey[50],
					},
				},
			},
			/* Styles applied to the root element if `variant="filled"` and `color="error"`. */
			filledError: {
				backgroundColor: 'rgba(248, 169, 173, 0.86)',
				'& $action': {
					'& path, &:active path': {
						fill: theme.palette.grey[50],
					},
				},
			},
			/* Styles applied to the icon wrapper element. */
			icon: {
				padding: 0,
			},
			/* Styles applied to the message wrapper element. */
			message: {
				padding: 0,
				display: 'flex',
			},
			/* Styles applied to the action wrapper element if `action` is provided. */
			action: {
				marginRight: 0,
				paddingLeft: theme.spacing(6),
				'&, &:hover': {
					'& path, &:active path': {
						fill: theme.palette.grey[500],
					},
				},
			},
		},
	},
	MuiAlertTitle: {
		styleOverrides: {
			root: {
				fontSize: '18px',
				lineHeight: '20px',
				fontWeight: theme.typography.fontWeightBold,
				marginTop: 0,
				marginBottom: 0,
				marginRight: theme.spacing(6),
			},
		},
	},
	MuiDialog: {
		defaultProps: {
			maxWidth: false,
		},
		styleOverrides: {
			paperWidthFalse: {
				maxHeight: 500,
				minWidth: 450,
				maxWidth: 960,
				width: 500,
			},
		},
	},
	MuiDialogTitle: {
		styleOverrides: {
			root: {
				padding: theme.spacing(5, 6),
				background: theme.palette.primary[700],
				'& h2': {
					fontSize: '20px',
					lineHeight: '26px',
					fontWeight: 500,
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					color: theme.palette.common.white,
				},
			},
		},
	},
	MuiDialogContent: {
		styleOverrides: {
			root: {
				padding: '36px 24px 64px',
			},
		},
	},
	MuiDialogContentText: {
		styleOverrides: {
			root: {
				fontSize: '14px',
				lineHeight: '18px',
				fontWeight: theme.typography.fontWeightRegular,
				color: theme.palette.grey[600],
			},
		},
	},
	MuiDialogActions: {
		styleOverrides: {
			root: {
				borderTop: `1px solid ${theme.palette.grey[300]}`,
				background: theme.palette.grey[100],
				padding: theme.spacing(3, 6),
			},
		},
	},
	MuiSnackbarContent: {
		styleOverrides: {
			root: {
				padding: theme.spacing(0, 6),
				height: '40px',
				fontSize: '16px',
				lineHeight: '20px',
				fontWeight: theme.typography.fontWeightMedium,
				alignItems: 'center',
			},
			message: {
				padding: theme.spacing(0.5, 0),
			},
			action: {
				marginRight: -6,
			},
		},
	},
	MuiTabs: {
		styleOverrides: {
			/* Styles applied to the root element. */
			root: {
				minHeight: 41,
			},
			indicator: {
				backgroundColor: '#387E7F',
				borderRadius: 1,
				height: 3,
				display: 'none',
			},
		},
	},
	MuiTab: {
		defaultProps: {
			disableTouchRipple: true,
			disableFocusRipple: true,
			disableRipple: true,
		},
		styleOverrides: {
			/* Styles applied to the root element. */
			root: {
				...theme.typography.button,
				borderBottom: `1px solid ${theme.palette.grey[300]}`,
				fontWeight: theme.typography.fontWeightRegular,
				minHeight: 40,
				minWidth: 'auto',
				padding: '10px 16px',
				[theme.breakpoints.up('sm')]: {
					padding: '6px 24px',
				},
				[theme.breakpoints.up('sm')]: {
					minWidth: 'auto',
				},
				'&:hover': {
					color: theme.palette.grey[500],
				},
				'&$selected': {
					fontWeight: theme.typography.fontWeightMedium,
					border: `1px solid ${theme.palette.grey[300]}`,
					borderBottom: 'none',
					borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
				},
			},
			/* Styles applied to the root element if both `icon` and `label` are provided. */
			labelIcon: {
				minHeight: 40,
				paddingTop: 10,
			},
			/* Styles applied to the root element if the parent [`Tabs`](/api/tabs/) has `textColor="inherit"`. */
			textColorInherit: {
				color: 'inherit',
				opacity: 0.7,
				'&$selected': {
					opacity: 1,
				},
				'&$disabled': {
					opacity: 0.5,
				},
			},
			/* Styles applied to the root element if the parent [`Tabs`](/api/tabs/) has `textColor="primary"`. */
			textColorPrimary: {
				color: theme.palette.text.primary,
				'&$selected': {
					color: theme.palette.text.primary,
				},
				'&$disabled': {
					color: theme.palette.text.disabled,
				},
			},
			/* Styles applied to the root element if the parent [`Tabs`](/api/tabs/) has `textColor="secondary"`. */
			textColorSecondary: {
				color: theme.palette.text.primary,
				'&$selected': {
					color: theme.palette.text.primary,
				},
				'&$disabled': {
					color: theme.palette.text.disabled,
				},
			},
			/* Pseudo-class applied to the root element if `disabled={true}` (controlled by the Tabs component). */
			disabled: {},
			/* Styles applied to the root element if `wrapped={true}`. */
			wrapped: {
				fontSize: theme.typography.pxToRem(12),
				lineHeight: 1.5,
			},
		},
	},
	MuiChip: {
		styleOverrides: {
			root: {
				height: 40,
				borderRadius: 40,
				border: `1px solid ${theme.palette.grey[300]}`,
				backgroundColor: theme.palette.grey[50],
				color: theme.palette.text.primary,
				fontSize: '1rem',
				lineHeight: '1.25rem',
				'& $avatar': {
					marginLeft: 5,
					marginRight: -6,
					width: 20,
					height: 20,
					color: theme.palette.mode === 'light' ? theme.palette.grey[700] : theme.palette.grey[300],
					fontSize: theme.typography.pxToRem(12),
				},
				'& $avatarColorPrimary': {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.grey[50],
				},
				'& $avatarColorSecondary': {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.grey[50],
				},
				'& $avatarSmall': {
					marginLeft: 4,
					marginRight: -4,
					width: 16,
					height: 16,
					fontSize: theme.typography.pxToRem(12),
				},
			},
			colorPrimary: {
				backgroundColor: theme.palette.grey[50],
				color: theme.palette.text.primary,
			},
			colorSecondary: {
				backgroundColor: theme.palette.grey[50],
				color: theme.palette.text.primary,
			},
			clickable: {
				padding: 1,
				'&:hover': {
					backgroundColor: theme.palette.grey[100],
				},
				'&:focus': {
					backgroundColor: theme.palette.grey[50],
					color: theme.palette.primary[200],
					border: `2px solid ${theme.palette.primary[200]}`,
					padding: 0,
				},
				'&:active': {
					backgroundColor: theme.palette.grey[200],
					boxShadow: 'none',
				},
			},
			clickableColorPrimary: {
				'&:hover': {
					backgroundColor: theme.palette.grey[100],
				},
				'&:focus': {
					backgroundColor: theme.palette.grey[50],
				},
				'&:active': {
					backgroundColor: theme.palette.grey[200],
					boxShadow: 'none',
				},
			},
			clickableColorSecondary: {
				'&:hover': {
					backgroundColor: theme.palette.grey[100],
				},
				'&:focus': {
					backgroundColor: theme.palette.grey[50],
				},
				'&:active': {
					backgroundColor: theme.palette.grey[200],
					boxShadow: 'none',
				},
			},
		},
	},
	MuiAccordion: {
		styleOverrides: {
			root: {
				border: 0,
				boxShadow: 'none',
				borderTop: `1px solid ${theme.palette.grey[300]}`,
				'&:last-child': {
					borderBottom: `1px solid ${theme.palette.grey[300]}`,
				},
				'&:before': {
					display: 'none',
				},
				'&$expanded': {
					margin: 'auto',
				},
			},
		},
	},
	MuiAccordionSummary: {
		// TODO: commented out when migrated to v5
		// IconButtonProps: {
		//   edge: 'start',
		// },
		styleOverrides: {
			root: {
				borderTop: 'none',
				borderBottom: 'none',
				background: 'none',
				marginBottom: -1,
				minHeight: 'auto',
				flexDirection: 'row-reverse',
				height: 48,
				fontWeight: theme.typography.fontWeightDemibold,
				fontSize: '14px',
				lineHeight: '24px',
				'&$expanded': {
					minHeight: 'auto',
				},
				'&&&&& svg path': {
					fill: theme.palette.primary[300],
				},
				'&$focused': {
					backgroundColor: 'transparent',
				},
			},
			// TODO: commented out when migrated to v5
			// expandIcon: {
			//   padding: theme.spacing(1),
			//   border: 'none',
			//   transform: 'rotate(-90deg)',
			//   '&:hover': {
			//     border: 'none',
			//   },
			//   '&$expanded': {
			//     transform: 'rotate(0deg)',
			//   },
			// },
		},
	},
	MuiAccordionDetails: {
		styleOverrides: {
			root: {
				flexDirection: 'column',
				padding: theme.spacing(2),
				fontSize: '14px',
				lineHeight: '18px',
			},
		},
	},
	MuiListSubheader: {
		styleOverrides: {
			/* Styles applied to the root element. */
			root: {
				lineHeight: '24px',
				color: theme.palette.grey[500],
				fontWeight: theme.typography.fontWeightRegular,
				textAlign: 'left',
				marginTop: 16,
				marginBottom: 8,
			},
			/* Styles applied to the inner `component` element if `disableGutters={false}`. */
			gutters: {
				paddingLeft: 32,
			},
			/* Styles applied to the root element if `inset={true}`. */
			inset: {
				paddingLeft: 72,
			},
			/* Styles applied to the root element if `disableSticky={false}`. */
			sticky: {
				position: 'sticky',
				top: 0,
				zIndex: 1,
				backgroundColor: 'inherit',
			},
		},
	},
	MuiCircularProgress: {
		styleOverrides: {
			root: {
				color: '#8B8C9E',
			},
			colorPrimary: {
				color: '#8B8C9E',
			},
		},
	},
	// ADDED AFTER MIGRATION
	MuiCheckbox: {
		defaultProps: {
			disableRipple: true,
			color: 'primary',
		},
		styleOverrides: {
			root: {
				marginRight: '8px',
				padding: '4px',
				borderRadius: BORDER_RADIUS.xs,
				color: theme.palette.grey[400],

				'& svg': {
					width: '1.1em',
					height: '1.1em',
				},

				'&:hover': {
					backgroundColor: theme.palette.grey[200],
				},
			},
			checked: {
				'& path': {
					fill: theme.palette.primary.main,
				},
			},
		},
	},
	MuiTextField: {
		defaultProps: {
			variant: 'outlined',
		},
		styleOverrides: {
			root: {
				'& .topbar-MuiFormHelperText-root': { marginBottom: 0 },
				'& fieldset': {
					border: 'none',
				},
			},
		},
	},
	MuiFormHelperText: {
		styleOverrides: {
			root: {
				marginLeft: 0,
			},
		},
	},
	MuiInputLabel: {
		styleOverrides: {
			root: {
				position: 'static',
				color: '#6d6e85',
				transform: 'unset',
				lineHeight: '18px',
				marginBottom: '4px',
			},
			focused: {
				color: '#039be6',
			},
			error: {
				color: '#d6331f',
			},
		},
	},
	MuiInputBase: {
		styleOverrides: {
			root: {
				width: '280px',
				height: '40px',
				backgroundColor: '#f6f8ff',
				borderRadius: '10px !important',

				'& fieldset': {
					border: '2px solid #f6f8ff',
					borderRadius: BORDER_RADIUS.sm,
					top: 0,
				},

				'& input': {
					padding: '10px 12px',
				},

				'& legend': {
					display: 'none',
				},

				'&:hover': {
					backgroundColor: '#ecf1fe',
					'& fieldset': {
						borderColor: '#ecf1fe',
					},

					'&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
						borderColor: '#ecf1fe',
					},
				},

				'&.Mui-focused': {
					'& fieldset': {
						borderColor: '#039be6',
					},

					'&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
						borderColor: theme.palette.primary.main,
					},
				},

				'&.Mui-error': {
					'& fieldset': {
						borderColor: '#d6331f',
					},
					'&.Mui-focused.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
						borderColor: '#d6331f',
					},
				},
			},
			formControl: {
				borderRadius: BORDER_RADIUS.sm,
			},
			fullWidth: {
				width: '100%',
			},
			sizeSmall: {
				height: '32px',
				input: {
					padding: '8px 6px',
				},
			},
		},
	},
	MuiAutocomplete: {
		styleOverrides: {
			listbox: {
				padding: '8px 0px',
				borderRadius: BORDER_RADIUS.sm,
				'& .MuiAutocomplete-option': {
					padding: '4px 12px',
					cursor: 'pointer',
					minHeight: '40px',

					'&:hover': {
						backgroundColor: 'rgb(245, 248, 250)',
					},
					'&.Mui-focused': {
						backgroundColor: 'rgb(245, 248, 250)',
					},
					"&[aria-selected='true']": {
						backgroundColor: '#f5f8fa',
					},
				},
			},
			root: {
				'&.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot': {
					paddingRight: 'unset',
				},
				'&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot': {
					paddingRight: 'unset',
				},

				'& .MuiOutlinedInput-root .MuiAutocomplete-input': {
					padding: '10px 12px',
				},
				'& .MuiOutlinedInput-root.MuiInputBase-root svg': {
					marginRight: '8px',
				},
			},
		},
	},
	MuiOutlinedInput: {
		styleOverrides: {
			notchedOutline: {
				borderColor: 'rgb(236, 241, 254)',
				padding: '8px 0',
				borderRadius: BORDER_RADIUS.sm,
			},
			root: {
				'&.MuiOutlinedInput-root.MuiInputBase-root': {
					paddingLeft: '12px',
				},
			},
		},
	},
};

theme = responsiveFontSizes(theme);

export default theme;
