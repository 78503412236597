import { Dispatch, SetStateAction, useCallback, useState } from 'react';

type SetValue<T> = Dispatch<SetStateAction<T>>;
type Value<T> = T | null;

function isJsonString(str: string) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}
const getValueFromStorage = <T>(key: string): T | null => {
	const value = localStorage.getItem(key);
	if (!value) return null;
	if (!isJsonString(value)) return (value as unknown) as T;
	return JSON.parse(value);
};

export default <T>(key: string): [Value<T>, SetValue<T>] => {
	const [value, setValue] = useState<T | null>(getValueFromStorage(key));

	const _setValue = useCallback(
		(v: T) => {
			localStorage.setItem(key, JSON.stringify(v));
			setValue(v);
		},
		[key]
	);

	return [value, _setValue];
};
