import { TFunction } from 'i18next';
import { FunctionComponent, SVGProps } from 'react';

import { UserRoles, WorkflowType } from '@dar/api-interfaces';

export enum ProjectModule {
	projects = 'projects',
	plans = 'plans',
}

export enum ProjectTabPath {
	baseline = 'baseline',
	backlog = 'backlog',
	sprints = 'sprints',
	roadmap = 'roadmap',
	list = 'list',
	board = 'board',
	team = 'team',
	kpi = 'kpi',
	budget = 'budget',
	documents = 'documents',
}

export interface NavProps {
	backPath?: string;
	backText?: string;
	variant: 'light' | 'dark';
	module: ProjectModule;
	isSettings?: boolean;
}

export interface ProjectSettingsNavProps {
	module: ProjectModule;
}

export interface ProjectTabNavProps {
	module: ProjectModule;
}

export interface Route {
	itemId?: string;
	title: string;
	forCollapse?: string;
	Icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
	children?: Route[];
	route?: string;
	collapseRoute?: string | string[];
	isShow?: boolean;
}
export interface GetProjectSettingsRoutes {
	module: ProjectModule;
	t: TFunction;
}

export interface GetProjectTabsRoutes {
	module: ProjectModule;
	t: TFunction;
	projectId: string;
	isCross: boolean;
	role: UserRoles;
	workflowType: WorkflowType;
}
