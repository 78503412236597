import React from 'react';
import { useHistory } from 'react-router-dom';

import { SideMenuContent, SideMenuListItem } from '@dartech/dms-ui';
import { Divider } from '@mui/material';

import useStyles from '../style';
import { TabsContentProps } from './interfaces';

const TabsContent = ({ routes, isMini, variant }: TabsContentProps) => {
	const { classes } = useStyles({ isMini, variant });
	const history = useHistory();

	const onClick = (route: string) => {
		if (route?.length) {
			history.push(route);
		}
	};

	return (
		<SideMenuContent>
			{routes.tabRoutes.map(({ itemId, title, route, Icon, isShow }) => {
				if (isShow) {
					return (
						<SideMenuListItem
							title={title}
							itemId={itemId}
							icon={<Icon className={classes.menuIcon} />}
							customClickHandler={(e, route) => onClick(route)}
							route={route}
							filledIcon={<Icon className={classes.filledMenuIcon} />}
						/>
					);
				} else {
					return null;
				}
			})}
			<Divider className={classes.divider} sx={{ margin: '12px -12px' }} />
			{routes.bottomRoutes.map(({ itemId, title, route, Icon, isShow }) => {
				if (isShow) {
					return (
						<SideMenuListItem
							title={title}
							itemId={itemId}
							icon={<Icon className={classes.menuIcon} />}
							customClickHandler={(e, route) => onClick(route)}
							route={route}
							filledIcon={<Icon className={classes.filledMenuIcon} />}
						/>
					);
				} else {
					return null;
				}
			})}
		</SideMenuContent>
	);
};

export { TabsContent };
