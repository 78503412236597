import React, { PropsWithChildren, ReactElement, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink, useHistory, useLocation } from 'react-router-dom';

import styled from '@emotion/styled';
import {
  LEAVE_PAGE_CONFIRMATION_TEXT_TYPE,
  PROCESSES_REQUESTS_EXPORT_STATUS_LOCAL_STORAGE_KEY
} from '@topbar/src/constants/general';
import { isVideoConferenceUrl } from '@topbar/src/utils/isVideoConferenceUrl';

import { LinkProps, LinkRef } from './link-types';
import {
  LeaveModuleConfirmationModal
} from '@topbar/src/components/nav/internal/leave-module-confirmation-modal/leave-module-confirmation-modal';

const StyledLink = styled(ReactRouterLink)`
  &:hover {
    cursor: pointer;
  }
`;

const TARGET_BLANK = '_blank';

export const Link = React.memo(
  React.forwardRef<LinkRef, PropsWithChildren<LinkProps>>(
    (
      {
        children,
        className,
        confirmationType = LEAVE_PAGE_CONFIRMATION_TEXT_TYPE.TAB,
        href,
        target = '_self',
        handleClick
      },
      ref
    ): ReactElement => {
      const history = useHistory();
      const location = useLocation();
      const { t } = useTranslation();
      // @ts-ignore
      const isVideoConference = useMemo(() => isVideoConferenceUrl(location), [location]);
      const [isConfirmModuleChangeModalOpen, setConfirmModuleChangeModalOpen] = useState(false);

      const handlePageChange = useCallback((isConfirmed = false) => {
        if (target === TARGET_BLANK) {
          handleClick?.();
          window.open(href, TARGET_BLANK);
          return;
        }

        const isProcessesRequestsExportActive = localStorage.getItem(PROCESSES_REQUESTS_EXPORT_STATUS_LOCAL_STORAGE_KEY) === 'true';

        if (window.location.pathname.includes('/processes/') && isProcessesRequestsExportActive) {
          if (isConfirmed) {
            localStorage.setItem(PROCESSES_REQUESTS_EXPORT_STATUS_LOCAL_STORAGE_KEY, 'false');
          } else {
            setConfirmModuleChangeModalOpen(true);
            return;
          }
        }

        if (!isVideoConference || confirm(t(confirmationType))) {
          handleClick?.();
          if (isVideoConference) {
            return window.open(href, '_blank');
          }
          history.push(href);
        }
      }, [confirmationType, history, href, isVideoConference, target, handleClick, setConfirmModuleChangeModalOpen]);

      const onClick = useCallback(
        (event: React.MouseEvent<HTMLAnchorElement>, isConfirmed = false) => {
          event.preventDefault();
          event.stopPropagation();

          handlePageChange();
        },
        [handlePageChange]
      );

      return (
        <>
          <StyledLink ref={ref} to={href} target={target} onClick={onClick} className={className}>
            {children}
          </StyledLink>

          <LeaveModuleConfirmationModal
            open={isConfirmModuleChangeModalOpen}
            onClose={() => setConfirmModuleChangeModalOpen(false)}
            onLeave={() => {
              handlePageChange(true);
              setConfirmModuleChangeModalOpen(false);
            }}
            onStay={() => setConfirmModuleChangeModalOpen(false)}
            title={t('leaveProcessesModuleModal.title')}
            description={t('leaveProcessesModuleModal.description')}
            stayButtonText={t('leaveProcessesModuleModal.stayButton')}
            leaveButtonText={t('leaveProcessesModuleModal.leaveButton')}
          />
        </>
      );
    }
  )
);
