import { EventEmitter } from 'events';
import WebsocketHeartbeatJs from 'websocket-heartbeat-js';

interface SocketClientConfig {
	url: string;
}

export const SOCKET_EVENT_MESSAGE = 'message';

export class SocketClient {
	private static instance: SocketClient | undefined;

	socket: WebsocketHeartbeatJs;

	eventEmitter = new EventEmitter();

	token: string;

	constructor(private config: SocketClientConfig) {}

	static getInstance(config: SocketClientConfig) {
		if (!this.instance) {
			this.instance = new SocketClient(config);
		}

		return this.instance;
	}

	init() {
		this.socket = new WebsocketHeartbeatJs({
			url: `${this.config.url}`,
			pingMsg: JSON.stringify({ type: 'heartbeat' }),
			pingTimeout: 3600 * 1000,
			pongTimeout: 3600 * 1000,
		});

		this.socket.onclose = () => this.onClose();
		this.socket.onopen = () => this.onOpen();
		this.socket.onmessage = (e) => this.onMessage(e);
		this.socket.onerror = () => this.onError();
	}

	onError() {
		console.log('WEBSOCKET ERROR');
	}

	onClose() {
		// console.error('WEBSOCKET CLOSED');
	}

	async onOpen() {
		console.log('WEBSOCKET OPENED');
		this.socket.send(this.token);
	}

	close() {
		this.socket.close();
	}

	open(token: string) {
		if (!this.socket || this.socket.ws.readyState === WebSocket.CLOSED || this.socket.ws.readyState === WebSocket.CLOSING) {
			this.token = token;
			try {
				this.init();
			} catch (error) {
				console.error("Can't initialize websocket client", error);
			}
		}
	}

	onMessage(e: MessageEvent) {
		try {
			const message = JSON.parse(e.data);
			this.eventEmitter.emit(SOCKET_EVENT_MESSAGE, message);
		} catch (err) {
			console.error(err);
		}
	}
}
