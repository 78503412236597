import { Location } from 'history';

const routes = ['/productivity/meetings/static', '/productivity/meetings/room'];

export function isVideoConferenceUrl(location: Location<unknown> | typeof window.location): boolean {
	const queryParams = new URLSearchParams(location.search);
	const isConferenceEnd = queryParams.get('isConferenceEnd') === 'true' || false;
	const isMeeting = !!routes.find((item) => location.pathname.includes(item));
	return isMeeting && !isConferenceEnd;
}
