import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { Spinner } from '@dartech/dms-ui';
import { Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';

import { AuthProvider, useAuth } from './hooks/useAuth';
import { ModalProvider } from './hooks/useModalManager';
import { Modals } from './modals/modals';

const AccessDenied = lazy(() => import('./pages/access-denied/access-denied'));
const DocumentPreview = lazy(() => import('./pages/document-preview/document-preview'));
const Login = lazy(() => import('./pages/login/login'));

const DocflowRoutes = () => {
	const { path } = useRouteMatch();
	const { isAuthenticated } = useAuth();

	return (
		<Switch>
			<Route exact path={`${path}/:actionType/:documentId/:userId`} component={Login} />
			{isAuthenticated && <Route exact path={`${path}/document/:actionType/:documentId/:userId`} component={DocumentPreview} />}
			<Route path='*' component={AccessDenied} />
		</Switch>
	);
};

export const Docflow = () => {
	const initialModals = Object.values(Modals);

	return (
		<AuthProvider>
			<Worker workerUrl='https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js'>
				<ModalProvider initialModals={initialModals}>
					<Suspense fallback={null}>
						<DocflowRoutes />
					</Suspense>
				</ModalProvider>
			</Worker>
		</AuthProvider>
	);
};
