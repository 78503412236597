import styled from '@emotion/styled';

import { Button } from '../components/shared/button/button';
import { sidebarTheme } from '../sidebar-theme';

const Container = styled.div<{ $expanded: boolean }>`
	padding: ${sidebarTheme.spacing['positive-150']} ${sidebarTheme.spacing['positive-100']};
	background: #ffffff;
	border-bottom: ${sidebarTheme.border.default};
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 56px;

	&:hover {
		background: #f7f7f8;
		cursor: pointer;
	}

	&:active {
		background: #eeeef0;
	}

	${(props) =>
		props.$expanded &&
		`
		background: #eeeef0;
  `}

	&:focus-visible {
		background: #f7f7f8;
		border: 2px solid #039be6;
		padding: calc(${sidebarTheme.spacing['positive-150']} - 2px);
	}
`;

const CurrentWorkspaceContainer = styled.div`
	display: flex;
	height: 32px;
	gap: ${sidebarTheme.spacing['positive-100']};
	align-items: center;
`;

const ActionBlock = styled.div`
	display: flex;
	align-items: center;
`;

const WorkspaceMenuToggle = styled(Button)`
	height: fit-content;
`;

export const StyledMenuList = {
	Container,
	CurrentWorkspaceContainer,
	WorkspaceMenuToggle,
	ActionBlock,
};
