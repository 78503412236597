import { t } from 'i18next';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { Nav } from './nav';
import { BudgetSettingsRoutes } from './utils';

const BudgetSettingsNav = () => {
	const { pathname } = useLocation();
	const path = pathname.split('/');

	const backPath = `/budget/detail/${path[3]}`;

	return <Nav routes={BudgetSettingsRoutes} variant='light' backPath={backPath} backText={t('budgetNav.back_to_budgets')} />;
};

export { BudgetSettingsNav };
