import { Workspace } from '@dar/api-interfaces';

export enum Size {
	DEFAULT = 32,
	SMALL = 24,
	BIG = 40,
}

export type WorkspaceAvatarProps = {
	workspaceAvatarSrc: Workspace['organization']['logo'];
	workspaceName: Workspace['organization']['shortName'];
	hasNotifications?: boolean;
	size?: Size;
};
