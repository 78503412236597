import React, { lazy } from 'react';

import { useAuth } from './context/auth-context/auth-context';

const PublicRoutes = lazy(() => import('./pages/public'));
const PrivateRoutes = lazy(() => import('./pages/private'));

export const Router = () => {
	const {
		isAuthenticated,
	}: {
		isAuthenticated: boolean;
	} = useAuth();

	return <React.Fragment>{isAuthenticated ? <PrivateRoutes /> : <PublicRoutes />}</React.Fragment>;
};
