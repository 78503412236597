export const STYLES_PREFIX = 'topbar';

export const COOKIE_SETTINGS_KEY = 'cookie-settings';

export enum CUSTOM_EVENTS {
	BADGE_UPDATE = 'BADGE_UPDATE',
	CHAT = 'CHAT',
	BOT = 'BOT',
}

export interface IBotControlContext {
	isResizeScreen: boolean;
	toggleResizeScreen: () => void;
	isBotWidgetOpen?: boolean;
	toggleBotWidget?: () => void;
}

export interface IProviderProps {
	value: IBotControlContext;
}
