import { BehaviorSubject } from 'rxjs';

import { Region } from '@dar-dms/utils';
import { Subscription } from '@dar/api-interfaces';

import { DEFAULT_TRIAL_DAYS } from '../constants/workspace';

export class BillingService {
	private subscription = new BehaviorSubject<Subscription>(JSON.parse(localStorage.getItem('subscription')));
	public subscription$ = this.subscription.asObservable();

	public assignSubscription(subscription: Subscription) {
		localStorage.setItem('subscription', JSON.stringify(subscription));
		this.subscription.next(subscription);
	}

	public getPermission({ module, component }: { module: string; component: string }): boolean {
		const currentTariff = this.subscription.getValue()?.tariff;
		if (!currentTariff) return false;
		if (currentTariff['module.list']?.split(',').includes(module)) {
			const availableModules = Object.keys(currentTariff.modules);
			if (!availableModules.includes(module)) return true;
			const availableComponents = currentTariff.modules[module].comps.split(',');
			if (availableComponents.includes(component)) return true;
		}
		return false;
	}

	public getModules(): string[] {
		return this.subscription.getValue()?.tariff?.['module.list']?.split(',') || [];
	}

	public getModuleComponents(): { [module: string]: boolean } {
		return this.subscription.getValue()?.tariff?.modules
			? Object.values(this.subscription.getValue()?.tariff?.modules).reduce((acc, cur) => {
					const field = cur.comps.split(',').reduce((subAcc, subCur) => {
						return {
							...subAcc,
							[subCur]: true,
						};
					}, {});
					return {
						...acc,
						...field,
					};
			  }, {})
			: {};
	}

	public getTarifDays(region: Region) {
		const trialPeriodDays = this.subscription.getValue()?.trialPeriodDays;
		const defaultTrialDay = DEFAULT_TRIAL_DAYS(region);

		if (trialPeriodDays) {
			return {
				totalDays: trialPeriodDays,
				extraDays: trialPeriodDays ? trialPeriodDays - defaultTrialDay : 0,
				defaultTrialDay,
			};
		}
		return {
			totalDays: 0,
			extraDays: 0,
			defaultTrialDay: 0,
		};
	}
}
