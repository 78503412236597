import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Button } from '../../../components/shared/button/button';
import { Typography } from '../../../components/shared/typography/typography';
import { sidebarTheme } from '../../../sidebar-theme';

const Item = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 50%;

	& button {
		border: none;
	}
`;

const WorkspaceSelectContainer = styled(Item)`
	height: 100%;
	justify-content: space-between;

	& > * {
		width: 100%;
	}
`;

const CurrentWorkspaceInfoContainer = styled(Item)<{ $hasAction: boolean }>`
	padding: ${sidebarTheme.spacing['positive-150']};
	border-left: ${sidebarTheme.border.default};
	width: calc(50% - 1px);
	justify-content: center;

	${(props) =>
		!props.$hasAction &&
		`
		justify-content: center;
	`}
`;

const CurrentWorkspaceInfo = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`;

const AddWorkspaceButton = styled(Button)`
	display: flex;
	line-height: 18px;
	padding: ${sidebarTheme.spacing['positive-75']} ${sidebarTheme.spacing['positive-150']};
	width: 100%;
	border-radius: ${sidebarTheme.borderRadius.xs};

	&:hover {
		cursor: pointer;
		background: #2628420a;
	}

	&:active {
		background: #26284214;
	}

	&:focus-visible {
		background: #f0f0f7;
		outline: 2px solid #039be6;
	}

	svg {
		margin-right: ${sidebarTheme.spacing['positive-50']};
	}
`;

const AddWorkspaceContainer = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: calc(${sidebarTheme.spacing['positive-100']} - 1px);
	padding-bottom: ${sidebarTheme.spacing['positive-100']};
	padding-left: ${sidebarTheme.spacing['positive-150']};
	padding-right: ${sidebarTheme.spacing['positive-150']};
	border-top: 1px solid #26284214;
`;

const AddWorkspaceLabel = styled(Typography)`
	align-self: flex-end;
	color: #262842;
`;

const CurrentWorkspaceDescriptionContainer = styled(Box)`
	display: flex;
	flex-direction: column;
	padding: ${sidebarTheme.spacing['positive-100']} 0px;
	margin-bottom: ${sidebarTheme.spacing['positive-100']};
	align-items: center;

	& > div:nth-child(1) {
		max-width: 40px;
	}
`;

const CurrentWorkspaceName = styled(Typography)`
	margin-top: ${sidebarTheme.spacing['positive-100']};
`;

const CurrentWorkspaceAdditionalInfo = styled(Typography)`
	color: #6d6e85;
`;

const ActionItemsContainer = styled(Box)`
	margin-bottom: ${sidebarTheme.spacing['positive-100']};
	width: 100%;
	height: 68px;

	&:empty {
		display: none;
	}
`;

const CurrentWorkspaceActionItem = styled(Button)`
	display: flex;
	line-height: 18px;
	padding: ${sidebarTheme.spacing['positive-75']} ${sidebarTheme.spacing['positive-150']};
	width: 100%;
	border-radius: ${sidebarTheme.borderRadius.xs};

	&:hover {
		cursor: pointer;
		background: #2628420a;
	}

	&:active {
		background: #26284214;
	}

	&:focus-visible {
		background: #f0f0f7;
		outline: 2px solid #039be6;
	}

	svg {
		margin-right: ${sidebarTheme.spacing['positive-50']};
	}

	& + & {
		margin-top: ${sidebarTheme.spacing['positive-50']};
	}
`;

const UpgradeButton = styled(Button)`
	display: flex;
	padding: ${sidebarTheme.spacing['positive-125']} ${sidebarTheme.spacing['positive-150']};
	width: 100%;
	background: #039be6;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: ${sidebarTheme.borderRadius.sm};

	&:hover {
		cursor: pointer;
		background: #0692d9;
	}

	&:active {
		background: #088dd2;
	}

	&:focus-visible {
		outline: 2px solid #088dd2;
	}

	svg {
		margin-right: ${sidebarTheme.spacing['positive-100']};
	}
`;

const UpgradeButtonLabel = styled(Typography)`
	color: #ffffff;
`;

export const StyledWorkspaceMenu = {
	Item,
	CurrentWorkspaceInfoContainer,
	CurrentWorkspaceInfo,
	AddWorkspaceContainer,
	AddWorkspaceLabel,
	WorkspaceSelectContainer,
	CurrentWorkspaceDescriptionContainer,
	CurrentWorkspaceName,
	CurrentWorkspaceAdditionalInfo,
	CurrentWorkspaceActionItem,
	ActionItemsContainer,
	UpgradeButton,
	UpgradeButtonLabel,
	AddWorkspaceButton,
};
