import { useTranslation } from 'react-i18next';

import { isVideoConferenceUrl } from './isVideoConferenceUrl';
import { PROCESSES_REQUESTS_EXPORT_STATUS_LOCAL_STORAGE_KEY } from '@topbar/src/constants/general';

export enum TYPE_ENUM {
	LOG_OUT = 'meetings.leave_meeting_alert3',
	WORKSPACE = 'meetings.leave_meeting_alert2',
	TAB = 'meetings.leave_meeting_alert1',
}

export const useConfirm = () => {
	const { t } = useTranslation();

	const onConfirm = (callback, TYPE: TYPE_ENUM = TYPE_ENUM.TAB) => {
    const isProcessesRequestsExportActive = localStorage.getItem(PROCESSES_REQUESTS_EXPORT_STATUS_LOCAL_STORAGE_KEY) === 'true';
    const needConfirmation = isVideoConferenceUrl(window.location) || (window.location.pathname.includes('/processes/') && isProcessesRequestsExportActive);
		if (!needConfirmation) {
			return callback(false);
		}
		// eslint-disable-next-line no-restricted-globals
		if (confirm(t(TYPE))) {
      if (isProcessesRequestsExportActive) {
        localStorage.setItem(PROCESSES_REQUESTS_EXPORT_STATUS_LOCAL_STORAGE_KEY, 'false');
      }
			callback(true);
		}
	};

	return { onConfirm };
};
