const TOKEN = {
	ACCESS: 'auth_token',
};

export const clearTokens = () => {
	localStorage.removeItem(TOKEN.ACCESS);
};

export const setTokens = ({ access }: { access: string }) => {
	localStorage.setItem(TOKEN.ACCESS, access);
};

export const getTokens = () => {
	const access = localStorage.getItem(TOKEN.ACCESS);

	return {
		access,
	};
};
