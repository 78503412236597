import { WorkspaceShortInfo } from '@dar/api-interfaces';

import { environment } from '../environments/environment';
import { httpClient } from './clients';

export const getHintStatus = (profileId: string) => {
	return httpClient.get<boolean>(`${environment().dmsBaseApi}/hcms/main-api/employee/hint/${profileId}`).then((res) => res?.data);
};

export const editHintStatus = (profileId: string, body: { skip: boolean; viewed: boolean }) => {
	return httpClient
		.put(`${environment().dmsBaseApi}/hcms/main-api/employee/hint/${profileId}`, undefined, {
			params: {
				skip: body.skip,
				viewed: body.viewed,
			},
		})
		.then((res) => {
			return res?.data;
		});
};

export const getLastVisitedWorkspace = () => {
	return httpClient
		.get<WorkspaceShortInfo>(`${environment().hcmsApiRoot}/main-api/structure/last-visited-workspace`)
		.then((res) => res.data);
};
