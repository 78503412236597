/* eslint-disable prefer-rest-params */
import { EventEmitter } from 'events';
import React from 'react';
import { BehaviorSubject } from 'rxjs';

export const language = new BehaviorSubject(null);

const LocalicationProvider = ({ children }) => {
	const originalSetLocalStorage = localStorage.setItem;
	const originalRemoveLocalStorage = localStorage.removeItem;

	localStorage.setItem = function (key, value) {
		if (key == 'i18nextLng') {
			language.next(value);
		}
		originalSetLocalStorage.apply(this, arguments);
	};

	localStorage.removeItem = function (key) {
		if (key == 'i18nextLng') {
			language.next(null);
		}
		originalRemoveLocalStorage.apply(this, arguments);
	};

	return children;
};

export { LocalicationProvider };
