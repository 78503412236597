export enum SigningType {
	DIGITAL = 'DIGITAL',
	SES = 'SES',
	ESF_NCA = 'ESF_NCA',
}

export enum SubscriptionType {
	SIGNATORY = 'SIGNATORY',
	COUNTERPARTY = 'COUNTERPARTY',
	ALL = 'ALL',
}

export enum DocumentActionType {
	Sign = 'sign',
	Approve = 'approve',
}

export enum DocumentStatus {
	DRAFT = 'DRAFT',
	REJECTED = 'REJECTED',
	IN_PROGRESS = 'IN_PROGRESS',
	PENDING_YOUR_SIGNATURE = 'PENDING_YOUR_SIGNATURE',
	SIGNED = 'SIGNED',
}

export interface InitiatorForCounterpartyDto {
	userId: string;
	workspaceId: string;
	groupId: string;
	name: string;
	photo: string;
}

export interface SignatureFieldResponseDTO {
	id: number;
	title: string;
	x: number;
	y: number;
	width: number;
	height: number;
	page: number;
}

export interface SignatoryForCounterpartyDto {
	userId: string;
	workspaceId: string;
	groupId: string;
	isSigned: boolean;
	signingDate: string;
	name: string;
	photo: string;
	signingIpAddress: string;
	signatureFields: SignatureFieldResponseDTO[];
}

export interface CounterpartyApprovalViewDto {
	id: number;
	email: string;
	isApproved: boolean;
	dateApproved: string;
}

export interface UserDTO {
	userId: string;
	workspaceId: string;
	groupId: string;
	isSigned: boolean;
	signingDate: string;
}

export interface CounterpartyShortDto {
	id: string;
	name: string;
	companyCode: string;
	signatoryName: string;
	email: string;
	isSigned: boolean;
	signingDate: string;
	signingIpAddress: string;
	approvals: CounterpartyApprovalViewDto[];
	signatureFields: SignatureFieldResponseDTO[];
	coordinator: UserDTO;
}

export interface DocumentForCounterpartyDTO {
	id: number;
	attachmentId: string;
	type: string;
	title: string;
	signingType: SigningType;
	subscriptionType: SubscriptionType;
	initiator: InitiatorForCounterpartyDto;
	signatories: SignatoryForCounterpartyDto[];
	counterparties: CounterpartyShortDto[];
	number: string;
	startDate: string;
	expiryDate: string;
	description: string;
	createdAt: string;
	updatedAt: string;
	status: DocumentStatus;
	isInitiator: boolean;
	processId: string;
	isByTemplate: boolean;
}

export type SesSignPayload = {
	// Signature Box ID mapped to Signature ID
	[signatureFieldId: string]: number;
};

export interface AttachmentDTO {
	id: string;
	bucketId: string;
	contentType: string;
	link: string;
	size: number;
	extension: string;
	name: string;
	creatorId: string;
	workspaceId: string;
	folderName: string;
	createdAt: string;
	updatedAt: string;
	templateFields: Record<string, number>;
	signedName: string;
	signedLink: string;
	isPublic: boolean;
}

export interface AuthDto {
	uri: string;
	documentId: string;
	counterpartyId: string;
	auth_type: string;
	auth_token: string;
	expiry_date: string;
}

export interface CounterpartyApprovalViewDto {
	id: number;
	email: string;
	isApproved: boolean;
	dateApproved: string;
}

export interface PageSignatureDTO {
	totalPages: number;
	totalElements: number;
	size: number;
	content: SignatureDTO[];
	number: number;
	sort: SortObject;
	pageable: PageableObject;
	numberOfElements: number;
	first: boolean;
	last: boolean;
	empty: boolean;
}

export interface SignatureDTO {
	id: number;
	createdAt: string;
	data: string;
	ipAddress: string;
}

export interface SortObject {
	empty: boolean;
	sorted: boolean;
	unsorted: boolean;
}

export interface PageableObject {
	offset: number;
	sort: SortObject;
	pageNumber: number;
	paged: boolean;
	unpaged: boolean;
	pageSize: number;
}

export interface PageSize {
	width: number;
	height: number;
}

export type SignatoryUser = {
	userId: string;
	workspaceId?: string;
	groupId: string;
};

export type SignableEntity = {
	id: number;
	label: string;
	description?: string;
	avatar: string;
	isCounterparty: boolean;
	user?: SignatoryUser;
	counterPartyId?: string;
	isSelected?: boolean;
	isActive?: boolean;
	color: string;
	hasError?: boolean;
};

export interface Signature {
	id: number;
	x: number;
	y: number;
	page: number;
	width: number;
	height: number;
	hasError?: boolean;
	signable?: SignableEntity;
	data?: SignatureDTO;
}

export interface UserSignature extends Signature {
	userId?: string;
	isSigned?: boolean;
}

export interface SignatureParticipant {
	signatureFields?: Signature[];
	userId?: string;
	id?: string;
	isSigned?: boolean;
}

export interface TemplateAttachmentField {
	[key: string]: number;
}
export interface TemplateAttachment {
	id: string;
	bucketId: string;
	contentType: string;
	link: string;
	size: number;
	extension: string;
	name: string;
	creatorId: string;
	workspaceId: string;
	folderName: string;
	createdAt: string;
	updatedAt: string;
	templateFields: TemplateAttachmentField;
}

export interface Attachment {
	id: string;
	bucketId: string;
	contentType: string;
	link: string;
	size: number;
	extension: string;
	name: string;
	creatorId: string;
	workspaceId: string;
	folderName: string;
	createdAt: string;
	updatedAt: string;
	templateFields?: TemplateAttachmentField;
	signedLink?: string;
}
