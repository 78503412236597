import React, { FC, createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BehaviorSubject } from 'rxjs';

import { ReactComponent as LogoDarleanPrimary } from '@topbar/src/assets/logo-darlean-primary.svg';
import { ReactComponent as LogoDarleanSecondary } from '@topbar/src/assets/logo-darlean-secondary.svg';
import { environment } from '@topbar/src/environments/environment';

import styles from '../topbar.module.scss';

interface BrandType {
	host: string;
	title: string;
	logoPrimary: React.ReactElement;
	logoSecondary: React.ReactElement;
	isNavMini?: boolean;
	setisNavMini?: (v: (prev: any) => boolean) => void;
}

const BRANDS: { [key: string]: BrandType } = {
	[environment().hostBrandMapping['5qbe']]: {
		title: '5Qbe',
		host: environment().hostBrandMapping['5qbe'],
		logoPrimary: <LogoDarleanPrimary className={styles.logoPrimary} />,
		logoSecondary: <LogoDarleanSecondary />,
		isNavMini: false,
		setisNavMini: (v) => v,
	},
	[environment().hostBrandMapping['darlean']]: {
		title: 'DAR Lean',
		host: environment().hostBrandMapping['darlean'],
		logoPrimary: <LogoDarleanPrimary className={styles.logoPrimary} />,
		logoSecondary: <LogoDarleanSecondary />,
		isNavMini: false,
		setisNavMini: (v) => v,
	},
	[environment().hostBrandMapping['dms']]: {
		title: 'DMS',
		host: environment().hostBrandMapping['dms'],
		logoPrimary: <LogoDarleanPrimary className={styles.logoPrimary} />,
		logoSecondary: <LogoDarleanSecondary />,
		isNavMini: false,
		setisNavMini: (v) => v,
	},
	[environment().hostBrandMapping['localhost']]: {
		title: 'DMS',
		host: environment().hostBrandMapping['localhost'],
		logoPrimary: <LogoDarleanPrimary className={styles.logoPrimary} />,
		logoSecondary: <LogoDarleanSecondary />,
		isNavMini: false,
		setisNavMini: (v) => v,
	},
};

const BrandContext = createContext<BrandType>({
	host: null,
	title: null,
	logoPrimary: null,
	logoSecondary: null,
	isNavMini: false,
	setisNavMini: (v) => v,
});

export const isNavMini$ = new BehaviorSubject(false);

export const BrandProvider: FC = ({ children }) => {
	const history = useHistory();
	const [host, setHost] = useState<string | null>(null);
	const [title, setTitle] = useState<string | null>(null);
	const [logoPrimary, setLogoPrimary] = useState<React.ReactElement | null>(null);
	const [logoSecondary, setLogoSecondary] = useState<React.ReactElement | null>(null);
	const [isNavMini, setisNavMini] = useState<boolean>(false);

	useEffect(() => {
		const host = window.location.host;
		if (!host || !Object.keys(BRANDS).includes(host)) return;

		setHost(host);
		setTitle(BRANDS[host]?.title);
		setLogoPrimary(BRANDS[host]?.logoPrimary);
		setLogoSecondary(BRANDS[host]?.logoSecondary);
	}, [history]);

	useEffect(() => {
		isNavMini$.next(isNavMini);
	}, [isNavMini]);

	return (
		<BrandContext.Provider value={{ host, title, logoPrimary, logoSecondary, isNavMini, setisNavMini }}>
			{children}
		</BrandContext.Provider>
	);
};

export const useBrand = () => useContext(BrandContext);
