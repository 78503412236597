import { CommunityItem, Workspace } from '@dar/api-interfaces';

import { environment } from '../environments/environment';
import { getWorkspace } from '../utils/workspace';
import { httpClient } from './clients';

export function getCurrentCommunity({ workspaceToken }: { workspaceToken: string }) {
	const workspace: Workspace | null = getWorkspace();

	return workspace
		? httpClient
				.get<CommunityItem>(`${environment().communityApi}/members/current`, {
					params: {
						workspaceId: workspace.id,
					},
					headers: {
						'Workspace-Authorization': workspaceToken,
					},
				})
				.then((res) => res.data)
		: null;
}
