import { animate } from 'motion';
import { useEffect, useRef } from 'react';

type UseSidebarAnimations = {
	collapsed: boolean;
	logoId: string;
	sidebarContainerId: string;
};

export function useSidebarAnimations({ collapsed, sidebarContainerId, logoId }: UseSidebarAnimations): void {
	const shouldHaveDuration = useRef(false);

	useEffect(() => {
		if (sidebarContainerId && logoId) {
			const sidebarContainer = document.getElementById(sidebarContainerId);
			const logo = document.getElementById(logoId);
			const svgTextLogo = logo?.getElementsByTagName('g')?.[0];
			const headerLink = logo?.parentElement;

			if (collapsed) {
				if (sidebarContainer) {
					animate(
						sidebarContainer,
						{ width: '56px', overflow: 'hidden' },
						{ duration: shouldHaveDuration.current ? 0.5 : 0, easing: 'linear' }
					);
				}

				if (svgTextLogo) {
					animate(svgTextLogo, { opacity: '0' }, { duration: shouldHaveDuration.current ? 0.4 : 0 });
				}

				if (headerLink) {
					animate(headerLink, { transform: 'translateX(8px)' }).finished.then(() => {
						animate(headerLink, { width: '20px' });
					});
				}
			} else {
				if (sidebarContainer) {
					animate(
						sidebarContainer,
						{ width: '248px', overflow: 'visible' },
						{ duration: shouldHaveDuration.current ? 0.5 : 0, easing: 'linear' }
					);
				}

				if (svgTextLogo) {
					animate(svgTextLogo, { opacity: 1 }, { duration: shouldHaveDuration.current ? 0.4 : 0 });
				}

				if (headerLink) {
					animate(headerLink, { transform: 'translateX(0px)' }).finished.then(() => {
						animate(headerLink, { width: 'fit-content' });
					});
				}
			}

			shouldHaveDuration.current = true;
		}
	}, [collapsed, logoId, sidebarContainerId]);
}
