import React, { useMemo, useRef } from 'react';

import { Menu } from '@mui/material';

import { MenuItem } from '../../../menu-item/menu-item';
import {
	MODAL_BORDER_WIDTH,
	MODAL_LIST_VERTICAL_PADDING,
	MODAL_PADDING,
	onDragStyles,
	useOwnStyles,
} from './menu-list-modal-styles';
import { MenuListModalProps } from './menu-list-modal-types';

const MenuListModal = ({ anchorEl, onClose, open, items }: MenuListModalProps) => {
	const { classes: ownClasses } = useOwnStyles();
	const additionalItemsList = useRef<HTMLUListElement>(null);
	const menuRef = useRef(null);

	const modalListHeight = useMemo(
		() => items.pinnedMenuItems.length * 32 + items.additionalMenuItems.length * 32 + MODAL_LIST_VERTICAL_PADDING * 2,
		[items]
	);

	const modalHeight = useMemo(() => modalListHeight + MODAL_PADDING * 2 + MODAL_BORDER_WIDTH * 2, [modalListHeight]);

	return (
		<Menu
			id='menu-list-modal'
			anchorEl={anchorEl}
			open={open}
			onClose={onClose}
			PaperProps={{
				style: { height: `${modalHeight}px` },
			}}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
			classes={ownClasses}
			ref={menuRef}
		>
			<ul ref={additionalItemsList} style={onDragStyles}>
				{[...items.pinnedMenuItems, ...items.additionalMenuItems].map((item) => (
					<li key={item.id}>
						<MenuItem {...item} shouldShortenText />
					</li>
				))}
			</ul>
		</Menu>
	);
};

export { MenuListModal };
