import styled from '@emotion/styled';

const FrontCard = styled('section')({
	position: 'relative',
	maxWidth: '360px',
	background: '#0072B8',
	overflow: 'hidden',
	padding: '32px 24px',

	'@media (max-width: 1280px)': {
		display: 'none',
	},

	'& .bubble-top': {
		position: 'absolute',
		top: '-35%',
		left: '50%',
		width: '400px',
		height: '400px',
		transform: 'rotate(135deg)',
		borderRadius: '50%',
		background: 'linear-gradient(273deg, #039BE6 -1.29%, rgba(3, 155, 230, 0.00) 58.15%)',
	},

	'& .bubble-bottom': {
		position: 'absolute',
		bottom: '-40%',
		right: '55px',
		width: '500px',
		height: '500px',
		transform: 'rotate(-95deg)',
		borderRadius: '50%',
		background:
			'linear-gradient(273deg, #039BE6 -1.29%, rgba(3, 155, 230, 0.00) 58.15%), linear-gradient(273deg, #4673C3 -1.29%, rgba(70, 115, 195, 0.00) 58.15%)',
	},

	'& .arrow': {
		position: 'absolute',
		width: '175px',
		bottom: '-20px',
		left: 0,
	},

	'& .logo': {
		width: '120px',
	},

	'& .container': {
		marginTop: '144px',
	},

	'& .illustration': {
		position: 'relative',
		zIndex: 1,
	},

	'& .title': {
		lineHeight: '24px',
		fontSize: '20px',
		fontWeight: '500',
		color: '#ffffff',
		marginBottom: '8px',
	},

	'& .description': {
		color: '#E6F5FD',
		marginBottom: '24px',
	},
});

export default { FrontCard };
