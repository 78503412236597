import { useLocation } from 'react-router-dom';

import { UserRoles, WorkflowType } from '@dar/api-interfaces';
import { ReactComponent as CommunicationRegularIcon } from '@topbar/src/assets/icons/communication_regular.svg';
import { ReactComponent as DetailIcon } from '@topbar/src/assets/icons/list_detail.svg';
import { ReactComponent as OrganizationRegular } from '@topbar/src/assets/icons/organization_regular.svg';
import { ReactComponent as PeopleTeamRegularIcon } from '@topbar/src/assets/icons/people_team_regular.svg';
import { ReactComponent as BacklogIcon } from '@topbar/src/assets/icons/project_tabs/backlog.svg';
import { ReactComponent as BaselineIcon } from '@topbar/src/assets/icons/project_tabs/baseline.svg';
import { ReactComponent as BoardIcon } from '@topbar/src/assets/icons/project_tabs/board.svg';
import { ReactComponent as BudgetIcon } from '@topbar/src/assets/icons/project_tabs/budget.svg';
import { ReactComponent as FilesIcon } from '@topbar/src/assets/icons/project_tabs/files.svg';
import { ReactComponent as KpiIcon } from '@topbar/src/assets/icons/project_tabs/kpi.svg';
import { ReactComponent as ListIcon } from '@topbar/src/assets/icons/project_tabs/list.svg';
import { ReactComponent as RoadmapIcon } from '@topbar/src/assets/icons/project_tabs/roadmap.svg';
import { ReactComponent as SprintsIcon } from '@topbar/src/assets/icons/project_tabs/sprints.svg';
import { ReactComponent as TeamIcon } from '@topbar/src/assets/icons/project_tabs/team.svg';
import { ReactComponent as SettingsIcon } from '@topbar/src/assets/icons/settings_regular.svg';

import { GetProjectSettingsRoutes, GetProjectTabsRoutes, ProjectModule, ProjectTabPath, Route } from './interfaces';

export const checkRoleRelated = (role: UserRoles) => {
	return {
		isAdmin: role === UserRoles.Admin,
		isContributor: role === UserRoles.Contributor,
		isViewer: role === UserRoles.Viewer,
	};
};

export const getProjectSettingsRoutes = ({ module, t }: GetProjectSettingsRoutes): Route[] => {
	const { pathname } = useLocation();
	const paths = pathname.split('/').slice(1);
	const project_id = paths[2];
	const routes = [
		{
			title: t('projectNav.details'),
			itemId: 'projectDetails',
			Icon: DetailIcon,
			filledIcon: DetailIcon,
			route: `/${module}/detail/${project_id}/settings/details`,
		},
		{
			title: t('projectNav.team_&_roles'),
			Icon: PeopleTeamRegularIcon,
			filledIcon: PeopleTeamRegularIcon,
			itemId: 'projectTeam',
			route: `/${module}/detail/${project_id}/settings/teams`,
		},
		{
			title: t('projectNav.issue_types'),
			Icon: OrganizationRegular,
			filledIcon: OrganizationRegular,
			itemId: 'projectIssueTypes',
			route: `/${module}/detail/${project_id}/settings/issueType`,
		},
		// {
		// 	title: t('projectNav.issue_statuses'),
		// 	Icon: StatusRegularIcon,
		// 	filledIcon: StatusRegularIcon,
		// 	itemId: 'projectStatuses',
		// 	route: `/projects/detail/${project_id}/settings/statuses`,
		// },
	];

	module === ProjectModule.projects &&
		routes.push({
			title: t('projectNav.goals'),
			Icon: CommunicationRegularIcon,
			filledIcon: CommunicationRegularIcon,
			itemId: 'projectGoals',
			route: `/${module}/detail/${project_id}/settings/goals`,
		});

	return routes;
};

export const getProjectTabsRoutes = ({ module, t, projectId, isCross, role, workflowType }: GetProjectTabsRoutes) => {
	const isPlan = module === ProjectModule.plans;
	const isScrum = workflowType === WorkflowType.scrum;
	const { isContributor, isAdmin } = checkRoleRelated(role);
	const tabRoutes: Route[] = [
		{
			title: t('projectNav.baseline'),
			itemId: 'baseline',
			Icon: BaselineIcon,
			route: `/${module}/detail/${projectId}/${ProjectTabPath.baseline}`,
			isShow: !isPlan,
		},
		{
			title: t('projectNav.backlog'),
			itemId: 'backlog',
			Icon: BacklogIcon,
			route: `/${module}/detail/${projectId}/${ProjectTabPath.backlog}`,
			isShow: isScrum,
		},
		{
			title: t('projectNav.all_sprints'),
			itemId: 'sprints',
			Icon: SprintsIcon,
			route: `/${module}/detail/${projectId}/${ProjectTabPath.sprints}`,
			isShow: isScrum,
		},
		{
			title: t('projectNav.roadmap'),
			itemId: 'roadmap',
			Icon: RoadmapIcon,
			route: `/${module}/detail/${projectId}/${ProjectTabPath.roadmap}`,
			isShow: true,
		},
		{
			title: t('projectNav.list'),
			itemId: 'list',
			Icon: ListIcon,
			route: `/${module}/detail/${projectId}/${ProjectTabPath.list}`,
			isShow: true,
		},
		{
			title: t('projectNav.board'),
			itemId: 'board',
			Icon: BoardIcon,
			route: `/${module}/detail/${projectId}/${ProjectTabPath.board}`,
			isShow: true,
		},
		{
			title: t('projectNav.team'),
			itemId: 'team',
			Icon: TeamIcon,
			route: `/${module}/detail/${projectId}/${ProjectTabPath.team}`,
			isShow: !(isPlan || isContributor || isCross),
		},
		{
			title: t('projectNav.kpi'),
			itemId: 'kpi',
			Icon: KpiIcon,
			route: `/${module}/detail/${projectId}/${ProjectTabPath.kpi}`,
			isShow: true,
		},
		{
			title: t('projectNav.budget'),
			itemId: 'budget',
			Icon: BudgetIcon,
			route: `/${module}/detail/${projectId}/${ProjectTabPath.budget}`,
			isShow: !(isCross || isScrum),
		},
	];

	const bottomRoutes: Route[] = [
		{
			title: t(isPlan ? 'projectNav.plan_files' : 'projectNav.project_files'),
			itemId: 'files',
			Icon: FilesIcon,
			route: `/${module}/detail/${projectId}/${ProjectTabPath.documents}`,
			isShow: !isCross,
		},
		{
			title: t(isPlan ? 'modules.plan_settings' : 'modules.project_settings'),
			itemId: 'settings',
			Icon: SettingsIcon,
			route: `/${module}/detail/${projectId}/settings/details`,
			isShow: isAdmin,
		},
	];

	return { tabRoutes, bottomRoutes };
};
