export const paths = ['/signup', '/create-account', '/email-otp'];
export const loginPaths = ['/signin', '/reset', '/reset-password'];
export const cardPaths = [
	...paths,
	'/workspace/how-will-use',
	'/workspace/manage-tools',
	'/workspace/business-name',
	'/workspace/survey',
];

export const backButtonPaths = ['/workspace/survey', '/workspace/manage-tools'];
