import { t } from 'i18next';
import { useLocation } from 'react-router-dom';

import { Profile } from '@dartech/griffon-auth';
import { ReactComponent as ArrowSyncIcon } from '@topbar/src/assets/icons/arrow_sync_circle_regular.svg';
import { ReactComponent as CalendarIcon } from '@topbar/src/assets/icons/calendar_ltr_regular.svg';
import { ReactComponent as LockClosedIcon } from '@topbar/src/assets/icons/lock_closed_regular.svg';
import { ReactComponent as PeopleTeamIcon } from '@topbar/src/assets/icons/people_team_regular.svg';
import { ReactComponent as SettingsIcon } from '@topbar/src/assets/icons/settings_regular.svg';
import { PERMISSIONS_TYPES } from '@topbar/src/constants/permissions';

import { ReactComponent as BudgetTabIco } from '../../assets/budget_tab_ico.svg';
import { ReactComponent as DashboardTabIco } from '../../assets/dashboard_tab_ico.svg';
import { IRoute } from './nav-interface';
import { IData } from './nav-interface';

export const settingsMenuConfig: IData[] = [
	{
		icon: SettingsIcon,
		route: '/admin',
		title: 'Workspace settings',
		permissions: [],
	},
	{
		icon: LockClosedIcon,
		route: '/',
		title: 'Permissions',
		settings: {
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'right',
			},
			transformOrigin: {
				vertical: 'center',
				horizontal: 'left',
			},
		},
		permissions: [],
		children: [
			{
				title: 'Team',
				route: '/people/settings',
				icon: PeopleTeamIcon,
				permissions: [],
			},
			{
				title: 'Processes',
				route: '/processes/settings/roles',
				icon: ArrowSyncIcon,
				permissions: [],
			},
			// {
			// 	title: 'Projects',
			// 	route: '/',
			// 	icon: LayerIcon,
			// },
		],
	},
	{
		icon: CalendarIcon,
		route: '/admin/calendar',
		title: 'Work calendar',
		permissions: [PERMISSIONS_TYPES.WORKSPACE_CALENDAR],
	},
];

export const BudgetSettingsRoutes = (profile: Profile, t: (v: string) => string): IRoute[] => {
	const { pathname } = useLocation();
	const paths = pathname.split('/').slice(1);
	const budget_id = paths[2];
	return [
		{
			title: t('budgetNav.details'),
			itemId: 'budgetDetails',
			icon: BudgetTabIco,
			filledIcon: BudgetTabIco,
			route: `/budget/settings/${budget_id}/detail`,
		},
		{
			title: t('budgetNav.team_&_roles'),
			icon: DashboardTabIco,
			filledIcon: DashboardTabIco,
			itemId: 'budgetTeam',
			route: `/budget/settings/${budget_id}/team`,
		},
	];
};
