import { animate } from 'motion';
import React, { FC, useCallback, useContext, useEffect } from 'react';
import Div100vh from 'react-div-100vh';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { SideMenu } from '@dartech/dms-ui';
import { Divider, Typography } from '@mui/material';
import { BotContext } from '@topbar/src/contexts/bot-context';
import { ChatContext } from '@topbar/src/contexts/chat-context';
import { getProject } from '@topbar/src/services/projects';
import { useAuth } from '@topbar/src/topbar/providers/auth-provider';
import { useBrand } from '@topbar/src/topbar/providers/brand-provider';

import { ChatBotSection } from '../sidebar/chat-bot-section/chat-bot-section';
import { SidebarFooter } from '../sidebar/footer/sidebar-footer';
import { Profile } from '../sidebar/profile';
import { HEADER_LOGO_ID } from '../sidebar/sidebar';
import { SidebarHeader } from '../sidebar/sidebar-header/sidebar-header';
import { ReactComponent as Logo } from '../sidebar/svg/darlean-logo.svg';
import { Workspace } from '../sidebar/workspace/workspace';
import { NavProps } from './interfaces';
import { Back, SettingsContent, TabsContent } from './internal';
import useStyles from './style';
import { getProjectSettingsRoutes, getProjectTabsRoutes } from './utils';

const Nav: FC<NavProps> = ({ variant, backPath, backText, module, isSettings }) => {
	const { profile, workspace } = useAuth();
	const { isNavMini, setisNavMini } = useBrand();
	const { classes } = useStyles({ isMini: isNavMini, variant });
	const { pathname } = useLocation();
	const { t } = useTranslation();
	const chatContext = useContext(ChatContext);
	const botContext = useContext(BotContext);

	const onMinimize = () => {
		setisNavMini((prev) => !prev);
	};

	const onChatClick = () => {
		chatContext.onChatClick();
		if (botContext?.botControl.isBotWidgetOpen) {
			onBotClick();
		}
	};

	const shouldShowBot = typeof botContext?.onBotClick === 'function';

	const onBotClick = useCallback(() => {
		if (shouldShowBot) {
			botContext?.onBotClick();
		}
	}, [botContext, shouldShowBot]);

	const projectId = pathname.split('/')[3];

	const { data } = useQuery({
		queryKey: 'project',
		queryFn: () => getProject(projectId),
		enabled: !!projectId,
	});

	const isShowSettingsTitle = isSettings && !isNavMini;

	useEffect(() => {
		const logo = document.getElementById(HEADER_LOGO_ID);
		const svgTextLogo = logo?.getElementsByTagName('g')?.[0];
		const headerLink = logo?.parentElement;

		if (svgTextLogo) {
			animate(svgTextLogo, { opacity: isNavMini ? '0' : '1' }, { duration: 0.4 });
		}

		if (headerLink) {
			animate(headerLink, { transform: `translateX(${isNavMini ? 8 : 0}px)` }).finished.then(() => {
				animate(headerLink, { width: isNavMini ? '20px' : 'fit-content' });
			});
		}
	}, [isNavMini]);

	return (
		<Div100vh className={`sticky ${classes.container} ${isNavMini ? 'mini' : ''} `}>
			<SidebarHeader link={{ to: '/overview', title: t('logo-link-title') }}>
				<Logo title={t('logo-title')} role='img' id={HEADER_LOGO_ID} />
			</SidebarHeader>
			<SideMenu isMini={isNavMini} variant={variant}>
				<Workspace collapsed={isNavMini} currentWorkspace={workspace} />
				{backPath && (
					<>
						<Back isMini={isNavMini} variant={variant} backPath={backPath} backText={backText} />
						{isShowSettingsTitle && (
							<Typography className={classes.settingsTitle}>
								{module === 'projects' ? t('modules.project_settings') : t('modules.plan_settings')}
							</Typography>
						)}
						<Divider className={classes.divider} />
					</>
				)}
				{isSettings ? (
					<SettingsContent
						isMini={isNavMini}
						routes={getProjectSettingsRoutes({ module, t })}
						variant={variant}
						projectId={projectId}
					/>
				) : (
					<TabsContent
						isMini={isNavMini}
						routes={getProjectTabsRoutes({
							module,
							t,
							projectId,
							isCross: data?.crossProject,
							role: data?.role,
							workflowType: data?.workflowType,
						})}
						variant={variant}
					/>
				)}
				{shouldShowBot && <ChatBotSection onChatBotClick={onBotClick} collapsed={isNavMini} />}
				<Profile profile={profile} workspace={workspace} collapsed={isNavMini} />
				<SidebarFooter collapsed={isNavMini} onChatClick={onChatClick} toggleCollapse={onMinimize} />
			</SideMenu>
		</Div100vh>
	);
};

export { Nav };
