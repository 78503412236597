import axios from 'axios';
import { AxiosResponse } from 'axios';

import { toast } from '@dartech/dms-ui';
import { environment } from '@topbar/src/environments/environment';
import i18n from '@topbar/src/i18';

const commonExceptionHandler = (error: any) => {
	const bypassAlertOnErrorCodes = error?.config?.bypassAlertOnErrorCodes;
	if (!bypassAlertOnErrorCodes || !bypassAlertOnErrorCodes.includes(`${error?.response?.status || ''}`)) {
		toast.error(error?.detail, {
			duration: 3000,
			title: i18n.t(error?.response?.data?.detail),
			withIcon: true,
		});
	}
	return Promise.reject(error);
};

const unauthorizedClient = axios.create({
	baseURL: `${environment().dmsBaseApi}/docflow/otp`,
});

unauthorizedClient.interceptors.response.use((response: AxiosResponse) => response, commonExceptionHandler);

export default unauthorizedClient;
