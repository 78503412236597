import React from 'react';
import { useHistory } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Typography } from '@mui/material';

import useStyles from '../style';

interface Props {
	isMini: boolean;
	variant: 'light' | 'dark';
	backPath?: string;
	backText?: string;
}

const Back = ({ isMini, variant, backPath, backText }: Props) => {
	const { classes } = useStyles({ isMini, variant });
	const history = useHistory();

	const backHandler = () => {
		history.push(backPath);
	};

	return (
		<Box className={classes.backContainer}>
			<Button className={classes.backButton} variant='outlined' color='secondary' onClick={backHandler}>
				<ArrowBackIcon fontSize='small' />
				{!isMini && <Typography className={classes.backTitle}>{backText}</Typography>}
			</Button>
		</Box>
	);
};

export { Back };
