import React, { PropsWithChildren, ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink, useHistory, useLocation } from 'react-router-dom';

import styled from '@emotion/styled';
import { LEAVE_PAGE_CONFIRMATION_TEXT_TYPE } from '@topbar/src/constants/general';
import { isVideoConferenceUrl } from '@topbar/src/utils/isVideoConferenceUrl';

import { LinkProps, LinkRef } from './link-types';

const StyledLink = styled(ReactRouterLink)`
	&:hover {
		cursor: pointer;
	}
`;

const TARGET_BLANK = '_blank';

export const Link = React.memo(
	React.forwardRef<LinkRef, PropsWithChildren<LinkProps>>(
		(
			{ children, className, confirmationType = LEAVE_PAGE_CONFIRMATION_TEXT_TYPE.TAB, href, target = '_self', handleClick },
			ref
		): ReactElement => {
			const history = useHistory();
			const location = useLocation();
			const { t } = useTranslation();
			// @ts-ignore
			const isVideoConference = useMemo(() => isVideoConferenceUrl(location), [location]);

			const onClick = useCallback(
				(event: React.MouseEvent<HTMLAnchorElement>) => {
					event.preventDefault();
					event.stopPropagation();

					if (target === TARGET_BLANK) {
						handleClick?.();
						window.open(href, TARGET_BLANK);
						return;
					}

					if (!isVideoConference || confirm(t(confirmationType))) {
						handleClick?.();
						if (isVideoConference) {
							return window.open(href, '_blank');
						}
						history.push(href);
					}
				},
				[confirmationType, history, href, isVideoConference, target, handleClick]
			);

			return (
				<StyledLink ref={ref} to={href} target={target} onClick={onClick} className={className}>
					{children}
				</StyledLink>
			);
		}
	)
);
