import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from '@mui/material';

type Props = {
	url?: string;
};

const BackButton: React.FC<Props> = ({ url = null }) => {
	const history = useHistory();
	const { t } = useTranslation();

	const handleGoBack = () => {
		if (url?.length) {
			history.push(url);
			return;
		}
		history.goBack();
	};

	return (
		<Button
			size='large'
			variant='text'
			startIcon={<ArrowBackIcon />}
			onClick={handleGoBack}
			style={{
				height: '100%',
			}}
			color='secondary'
		>
			{t('signup_enter_code.back')}
		</Button>
	);
};

export { BackButton };
