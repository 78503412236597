import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Arrow from '@topbar/src/assets/auth/card-arrow.svg';
import HowWillUseImgDe from '@topbar/src/assets/auth/how-will-use_de.svg';
import HowWillUseImgEn from '@topbar/src/assets/auth/how-will-use_en.svg';
import HowWillUseImgId from '@topbar/src/assets/auth/how-will-use_id.svg';
import HowWillUseImgKz from '@topbar/src/assets/auth/how-will-use_kz.svg';
import HowWillUseImgRu from '@topbar/src/assets/auth/how-will-use_ru.svg';
import StepsDe from '@topbar/src/assets/auth/steps_de.svg';
import StepsEn from '@topbar/src/assets/auth/steps_en.svg';
import StepsId from '@topbar/src/assets/auth/steps_id.svg';
import StepsKz from '@topbar/src/assets/auth/steps_kz.svg';
import StepsRu from '@topbar/src/assets/auth/steps_ru.svg';
import TimesheetsDe from '@topbar/src/assets/auth/timesheets_de.svg';
import TimesheetsEn from '@topbar/src/assets/auth/timesheets_en.svg';
import TimesheetsId from '@topbar/src/assets/auth/timesheets_id.svg';
import TimesheetsKz from '@topbar/src/assets/auth/timesheets_kz.svg';
import TimesheetsRu from '@topbar/src/assets/auth/timesheets_ru.svg';
import AuthImgDe from '@topbar/src/assets/auth/your-project-de.svg';
import AuthImgEn from '@topbar/src/assets/auth/your-project-en.svg';
import AuthImgId from '@topbar/src/assets/auth/your-project-id.svg';
import AuthImgKz from '@topbar/src/assets/auth/your-project-kz.svg';
import AuthImgRu from '@topbar/src/assets/auth/your-project-ru.svg';
import Logo from '@topbar/src/assets/logo-darlean-secondary.svg';
import { useAuth } from '@topbar/src/topbar/providers/auth-provider';

import Styled from './style';

const illustrationMap = (route: string) => {
	switch (route) {
		case '/signup':
		case '/create-account':
		case '/email-otp':
			return {
				img: {
					de: AuthImgDe,
					en: AuthImgEn,
					id: AuthImgId,
					kz: AuthImgKz,
					ru: AuthImgRu,
				},
				title: 'signup.signup_card_title',
				description: 'signup.signup_card_description',
			};
		case '/workspace/how-will-use':
			return {
				img: {
					de: HowWillUseImgDe,
					en: HowWillUseImgEn,
					id: HowWillUseImgId,
					kz: HowWillUseImgKz,
					ru: HowWillUseImgRu,
				},
				title: 'how_will_use.how_will_use_card_title',
				description: 'how_will_use.how_will_use_card_description',
			};
		case '/workspace/manage-tools':
			return {
				img: {
					de: StepsDe,
					en: StepsEn,
					id: StepsId,
					kz: StepsKz,
					ru: StepsRu,
				},
				title: 'onboarding.manage_tools.sidebar.title',
				description: 'onboarding.manage_tools.sidebar.description',
			};
		case '/workspace/business-name':
			return {
				img: {
					de: TimesheetsDe,
					en: TimesheetsEn,
					id: TimesheetsId,
					kz: TimesheetsKz,
					ru: TimesheetsRu,
				},
				title: 'onboarding.business_name.sidebar.title',
				description: 'onboarding.business_name.sidebar.description',
			};
		default:
			return {
				img: {
					de: AuthImgDe,
					en: AuthImgEn,
					id: AuthImgId,
					kz: AuthImgKz,
					ru: AuthImgRu,
				},
				title: 'signup.signup_card_title',
				description: 'signup.signup_card_description',
			};
	}
};

const FrontCard = () => {
	const location = useLocation();
	const {
		t,
		i18n: { language },
	} = useTranslation();
	const { surveyStructure } = useAuth();

	const data = useMemo(() => {
		if (location.pathname === '/workspace/survey') {
			const step = new URLSearchParams(location.search).get('step');
			const surveyStep = surveyStructure?.find((question) => question.key === step);

			if (surveyStep) {
				return {
					img: surveyStep.sidePanel.imageUrl,
					title: surveyStep.sidePanel.title,
					description: surveyStep.sidePanel.description,
				};
			}
		}

		return illustrationMap(location.pathname);
	}, [location.pathname, surveyStructure]);

	return (
		<Styled.FrontCard>
			<img src={Logo} alt='logo-darlean' className='logo' />
			<div className='container'>
				<p className='title'>{t(data.title)}</p>
				<p className='description'>{t(data.description)}</p>
			</div>
			<img src={data.img?.[language] || data.img} alt='illustration' className='illustration' />
			<div className='bubble-top' />
			<div className='bubble-bottom' />
			<img src={Arrow} alt='arrow' className='arrow' />
		</Styled.FrontCard>
	);
};

export default FrontCard;
