import { makeStyles } from 'tss-react/mui';

type Props = {
	isMini: boolean;
	variant: 'dark' | 'light';
};

const useStyles = makeStyles<Props>()((theme, { isMini, variant }) => ({
	listItemWrapper: {
		position: 'relative',
	},
	endIcon: {
		position: 'absolute',
		right: 12,
		top: 8,
	},
	divider: {
		backgroundColor: variant == 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'D9D9D9',
	},
	menuIcon: {
		'& path': {
			fill: variant == 'dark' ? '#fff !important' : '#262842 !important',
		},
	},
	filledMenuIcon: {
		'& path': {
			fill: '#0072B8',
		},
	},
	backContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		padding: '12px',
	},
	backButton: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		gap: '4px',
		padding: isMini ? '8px' : '6px 12px',
		minWidth: 'unset',
		height: 'unset',
		borderRadius: '8px',
	},
	backIcon: {
		background: variant == 'dark' ? '#fff' : 'rgba(38, 40, 66, 0.08)',
		transition: 'transform 0.5s ease-in-out',
		'& span': {
			marginRight: '2px',
		},
		'&.minimized': {
			transform: 'rotate(180deg)',
		},
	},
	backTitle: {
		color: variant == 'dark' ? '#fff' : '#262842',
		fontFamily: "'Euclid Circular B', sans-serif",
		fontStyle: 'normal',
		fontWeight: 500,
		fontSize: '14px',
		lineHeight: '18px,',
	},
	container: {
		borderRight: '1px solid rgba(38, 40, 66, 0.08)',
		display: 'flex',
		width: '248px',
		// width: '100%',
		flexDirection: 'column',
		transition: 'opacity 600ms ease-in-out, width 600ms ease-in-out, top 10ms ease-in-out',
		'&:hover > $logo > $minimizeIcon': {
			opacity: 1,
		},
		'&.mini': {
			width: '64px',
		},
	},
	settingsTitle: {
		padding: '10px 12px 12px',
		color: variant == 'dark' ? '#fff' : '#000000',
		fontWeight: 500,
		fontSize: '16px',
		lineHeight: '18px',
	},
}));

export default useStyles;
