import { Workspace } from '@dar/api-interfaces';

import { WORKSPACE_STORAGE_KEY } from '../constants/workspace';

export const useCompanyID = (): string | null => {
	const workspace = useWorkspace();
	return workspace ? workspace.id : null;
};

export const useWorkspace = (): Workspace | null => {
	return getWorkspace();
};

export const getWorkspace = () => {
	if (localStorage.getItem(WORKSPACE_STORAGE_KEY) === 'undefined' || !localStorage.getItem(WORKSPACE_STORAGE_KEY)) {
		return null;
	} else {
		return JSON.parse(localStorage.getItem(WORKSPACE_STORAGE_KEY));
	}
};
