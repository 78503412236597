import React, { useMemo } from 'react';
import { FC, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { Survey, SurveyQuestion, Workspace } from '@dar/api-interfaces';
import { AuthService, AuthStatus, AuthTokens, Profile } from '@dartech/griffon-auth';
import { environment } from '@topbar/src/environments/environment';

export const authService = AuthService.createInstance({
	griffonApiRoot: environment().griffonApiRoot,
	griffonClientId: environment().griffonClientId,
	griffonClientSecret: environment().griffonClientSecret,
	dmsSettingsApiUrl: environment().dmsSettingsApiUrl,
	dmsLandingApiUrl: environment().landingUrl,
});

type AuthType = {
	authenticated: boolean;
	profile: Profile | null;
	setProfile: (v: Profile | null) => void;
	login: (
		token: AuthTokens | null,
		profile: Profile | null,
		redirectUrl?: string | null,
		disableRedirect?: boolean,
		invitationLogin?: boolean
	) => void;
	logout: () => void;
	invited: boolean;
	setInvited: (v: boolean | null) => void;
	setWorkspace: (value: Workspace) => void;
	workspace: Workspace;
	workspaceProcessing: boolean;
	setWorkspaceProcessing: (value: boolean) => void;
	survey: Survey;
	setSurvey: (v: Survey | null) => void;
	surveyStructure: SurveyQuestion[];
	setSurveyStructure: (v: SurveyQuestion[] | null) => void;
	totalRewardDays: number;
	totalTrialDays: number;
	isTrial: boolean;
	setIsTrial: (v: boolean) => void;
	isFreemium: boolean;
	setIsFreemium: (v: boolean) => void;
};

const setProfile = (v: Profile | null) => v;
const login = (token: AuthTokens) => token;
const logout = () => '';
const setInvited = (v: boolean | null) => v;
const setWorkspace = (v: Workspace) => v;

const AuthContext = createContext<AuthType>({
	authenticated: !!authService.getTokens(),
	profile: null,
	setProfile,
	login,
	logout,
	invited: false,
	setInvited,
	setWorkspace,
	workspace: null,
} as unknown as AuthType);

export const AuthProvider: FC = ({ children }) => {
	const history = useHistory();
	const { pathname, search } = useLocation();
	const { i18n } = useTranslation();
	const [authenticated, setAuthenticated] = useState<boolean | null>(null);
	const [invited, setInvited] = useState<boolean | null>(null);
	const [profile, setProfile] = useState<Profile | null>(null);
	const [workspaceProcessing, setWorkspaceProcessing] = useState(false);
	const [workspace, setWorkspace] = useState<Workspace | null>(JSON.parse(localStorage.getItem('workspace') || null));
	const [survey, setSurvey] = useState<Survey>(null);
	const [surveyStructure, setSurveyStructure] = useState<SurveyQuestion[]>(null);
	const checkRoute = authService.unauthorizedRoutes.includes(pathname.slice(0, pathname.lastIndexOf('/') + 1));

	const [isTrial, setIsTrial] = useState(false);
	const [isFreemium, setIsFreemium] = useState(false);

	const totalRewardDays = useMemo(() => {
		if (surveyStructure) {
			return surveyStructure.reduce((sum, question) => {
				return sum + question.trialDays;
			}, 2);
		}

		return 0;
	}, [surveyStructure]);

	const totalTrialDays = useMemo(() => {
		if (survey && survey.data) {
			const data = JSON.parse(survey.data);
			const answers = data.answers || [];

			if (answers.find((answer) => !!answer['DEPARTMENT'])) {
				return answers.length;
			}

			return answers.length + 1;
		}
		return 1;
	}, [survey]);

	useEffect(() => {
		const sub = authService.loggedIn$.subscribe((v) => {
			setProfile(v ? v.profile : null);
			setAuthenticated(!!v);
			if (checkRoute && authenticated === false) {
				history.push(`/join-meeting/${pathname.split('/').pop()}?authRedirect=${pathname + search}`);
			}
			setInvited(pathname.includes('invitation'));
			if (!v) {
				setWorkspace(null);
			}
		});
		return () => {
			sub.unsubscribe();
		};
	}, [authenticated, checkRoute, history, pathname, profile]);

	const redirect = useCallback(() => history.push('/signin' + search), [search, history]);

	useEffect(() => {
		authService.loginStatus.on(AuthStatus.UNAUTHORIZED, redirect);
		return () => {
			authService.loginStatus.off(AuthStatus.UNAUTHORIZED, redirect);
		};
	}, [history, redirect]);

	useEffect(() => {
		if (profile && workspace && Object.keys(workspace).length !== 0) {
			window.userGuiding.identify(profile.id, {
				first_name: profile.first_name,
				last_name: profile.last_name,
				email: profile.email,
				role: workspace.role,
				organization: workspace.organization?.name || workspace.information?.name,
				localization: i18n.language,
			});
		}
	}, [profile, workspace, i18n.language]);

	const login = useCallback(
		(tokens: AuthTokens, profile: Profile, redirectUrl?: string | null, disableRedirect?: boolean, invitationLogin?: boolean) => {
			authService.persistTokens(tokens);
			authService.persistProfile(profile);
			authService.setLoggedInValue({ profile, tokens });
			setInvited(!!invitationLogin);
			if (disableRedirect) return;
			if (redirectUrl) {
				history.push(redirectUrl);
			}
		},
		[history]
	);

	const logout = useCallback(() => {
		localStorage.setItem('pageUrl', JSON.stringify(pathname));
		history.push('/signin');
		authService.logout();
		authService.setLoggedInValue(null);
		setWorkspace(null);
		localStorage.removeItem('workspace');
	}, [history, pathname]);

	return (
		<AuthContext.Provider
			value={{
				isTrial,
				setIsTrial,
				authenticated,
				profile,
				setProfile,
				login,
				logout,
				invited,
				setInvited,
				setWorkspace,
				workspace,
				setWorkspaceProcessing,
				workspaceProcessing,
				survey,
				setSurvey,
				surveyStructure,
				setSurveyStructure,
				totalRewardDays,
				totalTrialDays,
				isFreemium,
				setIsFreemium,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => useContext(AuthContext);
