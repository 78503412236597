import { useEffect, useState } from 'react';

import { CommunityItem } from '@dar/api-interfaces';
import { WORKSPACE_TOKEN_STORAGE_KEY } from '@topbar/src/constants/workspace';
import useLocalStorage from '@topbar/src/hooks/useLocalStorage';
import { getCurrentCommunity } from '@topbar/src/services/community';

export const UseCurrentCommunity = () => {
	const [currentMember, setCurrentMember] = useState<CommunityItem | null>(null);
	const [workspaceToken] = useLocalStorage<string | null>(WORKSPACE_TOKEN_STORAGE_KEY);

	useEffect(() => {
		async function updateCurrentMember() {
			if (workspaceToken) {
				try {
					const communityData = await getCurrentCommunity({ workspaceToken });
					setCurrentMember(communityData);
				} catch (error) {
					// TODO: Add case for error handling
					setCurrentMember(null);
				}
			}
		}

		updateCurrentMember();
	}, [workspaceToken]);

	return { currentMember };
};
