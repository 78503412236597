import { animate } from 'motion';
import { useEffect } from 'react';

import { getVh } from '../../utils/styles';
import { CHAT_BUTTON_SIZE, CHAT_SECTION_SIZE_PX, SIDEFAR_FOOTER_COLLAPSED_HEIGHT } from '../sidebar-footer-styles';

type UseFooterAnimations = {
	collapsed: boolean;
	sidebarFooterId: string;
	chatSectionId: string;
	shouldHaveChatButton: boolean;
};

export function useFooterAnimations({
	collapsed,
	sidebarFooterId,
	chatSectionId,
	shouldHaveChatButton,
}: UseFooterAnimations): void {
	useEffect(() => {
		const sidebarFooterContainer = document.getElementById(sidebarFooterId);
		const chatSection = document.getElementById(chatSectionId);

		if (sidebarFooterContainer && chatSection) {
			if (collapsed) {
				animate(
					sidebarFooterContainer,
					{
						minHeight: `${SIDEFAR_FOOTER_COLLAPSED_HEIGHT - (shouldHaveChatButton ? 0 : CHAT_BUTTON_SIZE)}px`,
						flexDirection: 'column',
					},
					{ duration: 0 }
				);
				animate(chatSection, {
					flexDirection: 'column',
					height: `${CHAT_SECTION_SIZE_PX - (shouldHaveChatButton ? 0 : CHAT_BUTTON_SIZE)}px`,
					width: 'auto',
				});
			} else {
				animate(sidebarFooterContainer, { minHeight: getVh(6.4), flexDirection: 'row' }, { duration: 0 });
				animate(chatSection, {
					flexDirection: 'row',
					height: 'auto',
					width: `${CHAT_SECTION_SIZE_PX - (shouldHaveChatButton ? 0 : CHAT_BUTTON_SIZE)}px`,
				});
			}
		}
	}, [sidebarFooterId, collapsed, chatSectionId, shouldHaveChatButton]);
}
