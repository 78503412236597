import styled from '@emotion/styled';

import { Size } from './workspace-avatar-types';
import { sidebarTheme } from '../../../sidebar-theme';

const withNotifications = `
  position: relative;

  &::after {
    content: "";
    display: inline-block;
    background: #0072B8;
    border: 2px solid #FFFFFF;
    border-radius: 50%;
    box-sizing: content-box;
    height: 8px;
    padding: 0;
    position: absolute;
    right: -4px;
    top: -4px;
    width: 8px;
    z-index: 1;
  }`;

const Container = styled.div<{ $hasNotifications: boolean; $size: Size }>`
	min-width: ${(props) => `${props.$size}px`};
	height: ${(props) => `${props.$size}px`};
	border-radius: ${sidebarTheme.borderRadius.xxs};
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: Euclid;
	font-size: 12px;
	font-weight: 500;
	line-height: 16px;
	letter-spacing: 0em;
	color: #ffffff;
	text-transform: uppercase;
	background: #4c92c7;

	img {
		width: ${(props) => `${props.$size}px`};
		height: ${(props) => `${props.$size}px`};
	}

	${(props) =>
		props.$hasNotifications &&
		`
    ${withNotifications}
`}
`;

const Logo = styled.img`
	border-radius: ${sidebarTheme.borderRadius.xxs};
`;

export const StyledWorkspaceAvatar = {
	Container,
	Logo,
};
