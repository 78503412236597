
import { MATOMO_KEYS } from '@topbar/src/constants/matomo';
import { getWorkspace } from '@topbar/src/hooks/useCompanyID';

export function trackMenuItemClick(matomoKey: MATOMO_KEYS): void {
	const workspace = getWorkspace();
	window._paq?.push([
		'trackEvent',
		'Nav',
		matomoKey,
		JSON.stringify({ holdingId: workspace?.holdingId, companyId: workspace?.id }),
	]);
}