import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { BadgeContext } from '@topbar/src/contexts/badge-context/badge-context';
import type { ModuleSetting } from '@topbar/src/services/sidebar';

import { useAnchorEl } from '../hooks//use-anchor-el';
import { MenuItem } from '../menu-item/menu-item';
import { MenuItemButton } from '../menu-item/menu-item-button/menu-item-button';
import { MenuItem as IMenuItem, ROUTES } from '../sidebar-constants';
import { ReactComponent as More } from '../svg/more.svg';
import { DraggableList } from './components/draggable-list/draggable-list';
import { MenuListModal } from './components/menu-list-modal/menu-list-modal';
import { useMenuItems } from './hooks/use-menu-items';
import { StyledMenuList } from './menu-list-styles';
import { MenuListProps } from './menu-list-types';
import { menuItemsHelper } from './utils/menu-items/menu-items-helper';
import { moduleSettingsHelper } from './utils/module-settings/module-settings-helper';
import { putModuleSettings } from './utils/module-settings/put';
import { trackMenuItemClick } from './utils/trackMenuItemClick';

const MENU_ITEM_HEIGHT = 32;
const MENU_ITEM_GAP = 4;
const MENU_ITEM_FULL_HEIGHT = MENU_ITEM_HEIGHT + MENU_ITEM_GAP;

export function MenuList({ collapsed, currentWorkspace, profile }: MenuListProps): ReactElement {
	const { t } = useTranslation();
	const location = useLocation();
	const [modulesSettings, setModulesSettings] = useState<readonly ModuleSetting[]>([]);
	const [pinnedMenuItems, setPinnedMenuItems] = useState<IMenuItem[]>([]);

	const { anchorEl, isOpen, onModalClose, onModalOpen } = useAnchorEl();
	const { badges } = useContext(BadgeContext);
	const { defaultMenuItems, additionalMenuItems, activeMenuItem } = useMenuItems(badges, t);
	const activeRoute = [...defaultMenuItems, ...additionalMenuItems].find(({ route }) => location?.pathname?.includes(route));

	const hasActiveMenuItem = useMemo(
		() => activeMenuItem && !pinnedMenuItems.find((item) => item.id === activeMenuItem.id),
		[pinnedMenuItems, activeMenuItem]
	);

	/* NOTE: Leave it, because we are not sure about deleting that code */
	/* useEffect(() => {
		if (notificationsCount) {
			setPinnedMenuItems((prevItems) =>
				prevItems.map((item) => {
					if (item.id === 'TIMESHEETS') {
						let notificationCounter = notificationsCount.TIMESHEETS_FOR_APPROVAL
							? notificationsCount.TIMESHEETS_FOR_APPROVAL
							: 0;

						notificationCounter += notificationsCount.MY_TIMESHEETS ? notificationsCount.MY_TIMESHEETS : 0;

						return {
							...item,
							notificationCounter,
						};
					}

					return item;
				})
			);
		}
	}, [badges]); */

	useEffect(() => {
		moduleSettingsHelper.fetch({
			onSuccess: (modulesSettings) => setModulesSettings(modulesSettings),
			onError: (modulesSettings) => setModulesSettings(modulesSettings),
			data: {
				employeeId: profile.id,
				workspaceId: currentWorkspace.id,
			},
		});
	}, [additionalMenuItems]);

	useEffect(() => {
		const sortedmodulesSettings = moduleSettingsHelper.sortByOrder(modulesSettings);

		setPinnedMenuItems(menuItemsHelper.getByModulesSettings(sortedmodulesSettings, additionalMenuItems));
	}, [modulesSettings]);

	const onPin = useCallback(
		(moduleType: ROUTES) => {
			onModalClose();

			const pinnedModule = moduleSettingsHelper.getByModuleType(modulesSettings, moduleType);

			const newModulesSettings = pinnedModule
				? moduleSettingsHelper.remove(modulesSettings, pinnedModule)
				: moduleSettingsHelper.add(modulesSettings, moduleType);

			putModuleSettings({
				modulesSettings: newModulesSettings,
				onSuccess: () => setModulesSettings(newModulesSettings),
				employeeId: profile.id,
				workspaceId: currentWorkspace.id,
			});
		},
		[modulesSettings, onModalClose]
	);

	const modalListHeight = useMemo(
		() =>
			(hasActiveMenuItem ? MENU_ITEM_FULL_HEIGHT : 0) + (pinnedMenuItems.length + 1) * MENU_ITEM_FULL_HEIGHT - MENU_ITEM_GAP * 2,
		[pinnedMenuItems, hasActiveMenuItem]
	);

	const menuItemsForModal = useMemo(() => {
		const mutatedPinnedMenuItems = pinnedMenuItems.map((item) => ({
			...item,
			hasPin: true,
			onClick: () => trackMenuItemClick(item.matomoKey),
			onPin,
		}));

		const mutatedAdditionalMenuItems = additionalMenuItems
			.filter((item) => !menuItemsHelper.getById(pinnedMenuItems, item.id))
			.map((item) => ({ ...item, hasPin: false, onPin, onClick: () => trackMenuItemClick(item.matomoKey) }));

		return {
			pinnedMenuItems: mutatedPinnedMenuItems,
			additionalMenuItems: mutatedAdditionalMenuItems,
		};
	}, [additionalMenuItems, pinnedMenuItems, onPin]);

	const onReorder = useCallback(
		(startIndex: number, endIndex: number) => {
			if (startIndex === endIndex) {
				return;
			}

			const sortedModulesSettings = moduleSettingsHelper.reorderByIndex({
				startIndex,
				endIndex,
				modulesSettings,
			});

			setPinnedMenuItems(menuItemsHelper.getByModulesSettings(sortedModulesSettings, additionalMenuItems));

			putModuleSettings({
				modulesSettings: sortedModulesSettings,
				onSuccess: () => setModulesSettings(sortedModulesSettings),
				employeeId: profile.id,
				workspaceId: currentWorkspace.id,
			});
		},
		[modulesSettings]
	);

	return (
		<>
			<StyledMenuList.DefaultContainer>
				{defaultMenuItems.map((item) => (
					<li key={item.id}>
						<MenuItem
							{...item}
							onClick={() => trackMenuItemClick(item.matomoKey)}
							isActive={activeRoute?.id === item.id}
							collapsed={collapsed}
						/>
					</li>
				))}
			</StyledMenuList.DefaultContainer>
			<StyledMenuList.PinnedContainer $collapsed={collapsed}>
				<StyledMenuList.PinnedWrapper $height={modalListHeight}>
					{hasActiveMenuItem ? (
						<li key={activeMenuItem.id}>
							<MenuItem
								{...activeMenuItem}
								onClick={() => trackMenuItemClick(activeMenuItem.matomoKey)}
								collapsed={collapsed}
								isActive={true}
							/>
						</li>
					) : null}
					<DraggableList items={pinnedMenuItems} activeRoute={activeRoute} collapsed={collapsed} onReorder={onReorder} />
					<StyledMenuList.ToggleModalListItem key={ROUTES.MORE}>
						<MenuItemButton
							id={ROUTES.MORE}
							isBeta={false}
							notificationCounter={0}
							icon={More}
							title={t('modules.more')}
							collapsed={collapsed}
							shouldShortenText
							onClick={(event) => onModalOpen(event)}
						/>
					</StyledMenuList.ToggleModalListItem>
				</StyledMenuList.PinnedWrapper>
			</StyledMenuList.PinnedContainer>
			<MenuListModal anchorEl={anchorEl} onClose={onModalClose} open={isOpen} items={menuItemsForModal} collapsed={collapsed} />
		</>
	);
}
