import styled from '@emotion/styled';

import { sidebarTheme } from './sidebar-theme';
import { getVh } from './utils/styles';

const Container = styled.aside`
	display: block;
	width: 248px;
	font-family: Euclid;
	display: flex;
	flex-direction: column;
	height: ${getVh(100)};
	border-right: ${sidebarTheme.border.default};
	justify-content: space-between;

	--header-height: 48.8px;
	--workspace-height: 56px;
	--default-menu-items-list-height: 164px;
	--profile-height: 58px;
	--footer-height: 56px;
	--footer-height-collapsed-difference: 44px;

	--pinned-menu-items-list-height: calc(
		${getVh(100)} - var(--header-height) - var(--workspace-height) - var(--default-menu-items-list-height) - var(--profile-height) -
			var(--footer-height)
	);
	--pinned-menu-items-list-height-collapsed: calc(
		var(--pinned-menu-items-list-height) - var(--footer-height-collapsed-difference)
	);

	& button:not(.with-border) {
		border: none;
	}
`;

export const StyledSidebar = {
	Container,
};
