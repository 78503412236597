import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import routes from './app-lazy';

export const registrationRoutes = [
	'/signin',
	'/signup',
	'/email-otp',
	'/create-account',
	'/reset-password-otp',
	'/reset-password',
	'/auth/social',
	'/workspace/how-will-use',
	'/invitation/',
	'/congratulations',
	'/join-meeting/',
	'/workspaces',
];

const Settings = () => {
	return (
		<Switch>
			<Route path='/join-meeting/:meetingId' component={routes.JoinMeeting} />
			{registrationRoutes.map((route) => (
				<Redirect exact from={route} to='/overview' />
			))}
		</Switch>
	);
};

export { Settings };
