import React, { ReactElement } from 'react';

import styled from '@emotion/styled';
import MuiTypography, { TypographyProps as MuiTypographyProps } from '@mui/material/Typography';

import { TypographyFontWeightVariant, TypographyVariant, sidebarTheme } from '../../../sidebar-theme';

const StyledTypography = styled(MuiTypography)<{
	$variant: TypographyVariant;
	$fontWeight: TypographyFontWeightVariant;
}>`
	font-family: Euclid;
	font-size: ${(props) => sidebarTheme.fontSize[props.$variant]};
	line-height: ${(props) => sidebarTheme.lineHeight[props.$variant]};
	font-weight: ${(props) => sidebarTheme.fontWeight[props.$fontWeight]};
`;

interface TypographyProps extends MuiTypographyProps {
	customVariant?: TypographyVariant;
	fontWeight?: TypographyFontWeightVariant;
}
type Ref = HTMLSpanElement | HTMLParagraphElement;

export const Typography = React.forwardRef<Ref, TypographyProps>(
	(
		{ children, customVariant = TypographyVariant.MEDIUM, fontWeight = TypographyFontWeightVariant.DEFAULT, ...rest },
		ref
	): ReactElement => (
		<StyledTypography ref={ref} $variant={customVariant} $fontWeight={fontWeight} {...rest}>
			{children}
		</StyledTypography>
	)
);
