import React, { ReactElement, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { Workspace as IWorkspace } from '@dar/api-interfaces';
import { useWorkspace } from '@topbar/src/hooks/useWorkspace';
import { useAuth } from '@topbar/src/topbar/providers/auth-provider';

import { NotificationCounter } from '../components/shared/notification-counter/notification-counter';
import { TypographyWithHint } from '../components/shared/typography-with-hint/typography-with-hint';
import { Typography } from '../components/shared/typography/typography';
import { useAnchorEl } from '../hooks//use-anchor-el';
import { ReactComponent as ArrowDown } from '../svg/keyboard-arrow-down.svg';
import { WorkspaceAvatar } from './components/workspace-avatar/workspace-avatar';
import { WorkspacesMenu } from './components/workspace-menu/workspace-menu';
import { useTransformedCountersByWorkspaceId } from './hooks/use-transformed-counters-by-workspace-id';
import { useWorkspaceAnimations } from './hooks/use-workspace-animations';
import { useWorkspacesByEmployeeId } from './hooks/use-workspaces-by-employee-id';
import { getCountersFromAllWorkspaces } from './utils/get-counters-from-all-workspaces';
import { sortWorkspaces } from './utils/sort-workspaces';
import { trackWorkspaceMenuClick } from './utils/track-workspace-menu-click';
import { StyledMenuList } from './workspace-styles';
import { WorkspaceProps } from './workspace-types';

const TIMESHEET_REDIRECT_URL = '/people/timesheets';
const relocate = ['upgrade', 'forbidden', '/productivity/meetings/room', '/productivity/meetings/static'];
const ACTION_BLOCK_ID = 'action-block-id';
const WORKSPACE_NAME_ID = 'workspace-name-id';

export function Workspace({ currentWorkspace, collapsed }: WorkspaceProps): ReactElement {
	const { profile, logout } = useAuth();
	const history = useHistory();
	const { anchorEl, isOpen, onModalClose, onModalOpen } = useAnchorEl();
	const { changeWorkspace } = useWorkspace();

	useWorkspaceAnimations({
		workspaceNameId: WORKSPACE_NAME_ID,
		actionBlockId: ACTION_BLOCK_ID,
		collapsed,
	});
	const workspaces = useWorkspacesByEmployeeId({
		onError: logout,
		profileId: profile?.id,
	});
	const transformedCountersByWorkspaceId = useTransformedCountersByWorkspaceId();

	const sortedWorkspaces = useMemo(
		() => sortWorkspaces(transformedCountersByWorkspaceId, workspaces),
		[transformedCountersByWorkspaceId, workspaces]
	);

	const totalCounters = useMemo(
		() => getCountersFromAllWorkspaces(transformedCountersByWorkspaceId),
		[transformedCountersByWorkspaceId]
	);

	const hasNotifications = useMemo(() => totalCounters > 0, [totalCounters]);

	const onWorkspaceSelect = useCallback(
		async (workspace: IWorkspace) => {
			onModalClose();
			await changeWorkspace({ workspace, profile, toRedirect: false });

			// TODO: remove this redirect when backend resolves on its end
			// When user is changing current workspace, while being in timesheet
			// he can still see this timesheet, cause it's params are set in the url
			// so this workaround will redirect user to timesheets main screen
			if (history.location.pathname.includes(TIMESHEET_REDIRECT_URL)) {
				history.push(TIMESHEET_REDIRECT_URL);
				window.location.reload();
			} else if (relocate.find((item) => history.location.pathname.includes(item))) {
				window.location.replace('/overview');
			} else if (workspace.isSurveyFinished) {
				window.location.reload();
			}
		},
		[history, profile, onModalClose]
	);

	const onWorkspaceMenuOpen = useCallback(
		(event: React.MouseEvent<HTMLElement>) => {
			trackWorkspaceMenuClick();
			onModalOpen(event);
		},
		[onModalOpen]
	);

	return (
		<>
			<StyledMenuList.Container onClick={onWorkspaceMenuOpen} $expanded={isOpen}>
				<StyledMenuList.CurrentWorkspaceContainer>
					<WorkspaceAvatar
						workspaceAvatarSrc={currentWorkspace?.organization?.logo || currentWorkspace?.information?.logo}
						workspaceName={currentWorkspace?.organization?.shortName || currentWorkspace?.information?.shortName}
						hasNotifications={hasNotifications && collapsed}
					/>
					<TypographyWithHint
						text={currentWorkspace?.organization?.shortName || currentWorkspace.information.shortName}
						shortWordMaxLength={15}
						render={(text) => <Typography id={WORKSPACE_NAME_ID}>{text}</Typography>}
					/>
				</StyledMenuList.CurrentWorkspaceContainer>
				<StyledMenuList.ActionBlock id={ACTION_BLOCK_ID}>
					{hasNotifications ? <NotificationCounter mr={'8px'} counter={totalCounters} /> : null}
					<StyledMenuList.WorkspaceMenuToggle>
						<ArrowDown />
					</StyledMenuList.WorkspaceMenuToggle>
				</StyledMenuList.ActionBlock>
			</StyledMenuList.Container>
			<WorkspacesMenu
				isOpen={isOpen}
				anchorEl={anchorEl}
				onSelect={onWorkspaceSelect}
				onClose={onModalClose}
				activeWorkspace={currentWorkspace}
				workspaces={sortedWorkspaces}
				countersByWorkspaceId={transformedCountersByWorkspaceId}
			/>
		</>
	);
}
