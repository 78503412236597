import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CheckIcon from '@mui/icons-material/Check';
import { Menu } from '@mui/material';

import { Typography } from '../../../components/shared/typography/typography';
import { StyledLanguageMenu } from './language-menu-styles';

const LanguageMenu = ({ anchorEl, onClose, onSelect, langs }) => {
	const languageMenuOpen = Boolean(anchorEl);
	const {
		i18n: { language },
	} = useTranslation();

	const defaultLanguage = useMemo(() => langs.find((item) => item.id.includes(language)), [language, langs]);

	return (
		<Menu
			id='menu-languages'
			disableEnforceFocus
			disableRestoreFocus
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			open={languageMenuOpen}
			onClose={onClose}
			MenuListProps={{
				style: { padding: '8px 0', width: '100%' },
			}}
			PaperProps={{
				style: {
					borderRadius: '10px',
					border: '1px solid #2628421F',
					width: '200px',
					zIndex: 1200,
					transform: 'translate(105%, 15%)',
				},
			}}
			defaultValue={defaultLanguage?.id}
		>
			{langs.map((item, index) => (
				<StyledLanguageMenu.MenuItem
					key={index}
					value={item?.id}
					selected={item?.id === language}
					onClick={() => onSelect(item?.id)}
				>
					<Typography>{item?.name}</Typography>
					{defaultLanguage?.id === item?.id && <CheckIcon style={{ color: '#6D7C8B' }} />}
				</StyledLanguageMenu.MenuItem>
			))}
		</Menu>
	);
};

export { LanguageMenu };
