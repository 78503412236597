import React, { PropsWithChildren, ReactElement, useEffect, useState } from 'react';

import { BotContext } from '@topbar/src/contexts/bot-context';
import { useBot } from '@topbar/src/hooks/useBot';
import { IBotControlContext, IProviderProps } from '@topbar/src/shared/constants';

import { ChatContext } from '../../contexts/chat-context';
import { useChat } from '../../hooks/useChat';

type AibotData = {
	AIBotChat: React.FC;
	AIBotWidget: React.FC<{}>;
	BotControlContextProvider: React.FC<IProviderProps>;
	useBotControl: () => IBotControlContext;
};

type ChatBotProviderProps = {};

function isAibotData(aibotData: any): aibotData is AibotData {
	return 'AIBotChat' in aibotData;
}

export function ChatBotProviderWrapper({ children }: PropsWithChildren<ChatBotProviderProps>): ReactElement {
	const [aibotData, setAibotData] = useState<AibotData | {}>({});

	useEffect(() => {
		async function getAibotModule() {
			try {
				const test = await (window as any).System.import('@dar-dms/aibot');
				return test;
			} catch {
				return {};
			}
		}

		getAibotModule()
			.then(setAibotData)
			.catch(() => {
				setAibotData({});
			});
	}, []);

	if (isAibotData(aibotData)) {
		return <ChatBotProvider aibotData={aibotData}>{children}</ChatBotProvider>;
	}

	return children as ReactElement;
}

function ChatBotProvider({ aibotData, children }: PropsWithChildren<{ aibotData: AibotData }>): ReactElement {
	const { AIBotChat, AIBotWidget, BotControlContextProvider, useBotControl } = aibotData;
	const { onChatBotClick, onChatClick } = useChat();
	const { onBotClick, botControl } = useBot(useBotControl);

	return (
		<ChatContext.Provider value={{ onChatClick, onChatBotClick }}>
			<BotContext.Provider value={{ onBotClick, botControl }}>
				<BotControlContextProvider value={botControl}>
					{children}
					<AIBotWidget />
					<AIBotChat />
				</BotControlContextProvider>
			</BotContext.Provider>
		</ChatContext.Provider>
	);
}
