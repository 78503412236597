import { ModuleSetting } from '@topbar/src/services/sidebar';

export function addModuleSetting(
	modulesSettings: readonly ModuleSetting[],
	moduleType: ModuleSetting['moduleType']
): readonly ModuleSetting[] {
	return [
		...modulesSettings,
		{
			moduleType: moduleType,
			order: modulesSettings.length + 1,
		},
	];
}
