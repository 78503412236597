import axios from 'axios';

import { ChatUnreadCount } from '@dar/api-interfaces';

import { WORKSPACE_STORAGE_KEY } from '../constants/workspace';
import { environment } from '../environments/environment';

export const updateWorkchatProfile = () => {
	const token = JSON.parse(localStorage.getItem('dms-auth')).id_token;
	const companyId =
		localStorage.getItem(WORKSPACE_STORAGE_KEY) === 'undefined' || localStorage.getItem(WORKSPACE_STORAGE_KEY) === null
			? null
			: JSON.parse(localStorage.getItem(WORKSPACE_STORAGE_KEY)).id;
	const headers = {
		Authorization: `Bearer ${token}`,
	};
	return axios
		.post<unknown>(`${environment().commsApiUrl}/users/workchat-update`, null, {
			headers,
			params: {
				companyId,
			},
		})
		.then((res) => res.data)
		.catch((err) => console.error('CHAT PROFILE UPDATE', err));
};

export const getUnreadMessages = () => {
	const token = JSON.parse(localStorage.getItem('dms-auth')).id_token;
	const headers = {
		Authorization: `Bearer ${token}`,
	};
	return axios
		.get<ChatUnreadCount[]>(`${environment().commsApiUrl}/users/workchat-messages`, {
			headers,
		})
		.then((res) => res.data);
};
