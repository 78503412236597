import React, { ReactElement } from 'react';
import { makeStyles } from 'tss-react/mui';

import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from '@mui/material';

import { sidebarTheme } from '../../../sidebar-theme';

type TooltipProps = MuiTooltipProps & { isHidden?: boolean };

const useStyles = makeStyles<Pick<TooltipProps, 'isHidden'>>()((_, { isHidden }) => ({
	tooltip: {
		padding: `${sidebarTheme.spacing['positive-100']} ${sidebarTheme.spacing['positive-150']}`,
		fontFamily: 'Euclid',
		fontSize: '14px',
		lineHeight: '18px',
		whiteSpace: 'pre-wrap',
		display: isHidden ? 'none' : 'block',
	},
}));

export function Tooltip({ children, isHidden, ...rest }: TooltipProps): ReactElement {
	const { classes } = useStyles({ isHidden });

	return (
		<MuiTooltip {...rest} classes={classes}>
			{children}
		</MuiTooltip>
	);
}
