import { useResize } from './use-resize';

export function useViewportHeight() {
	function setVh() {
		document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
	}

	setVh();

	useResize(setVh);
}
