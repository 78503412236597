import { TrialSubscriptionRequest, Workspace } from '@dar/api-interfaces';

import { environment } from '../environments/environment';
import { httpClient } from './clients';

export const createSubscripttion = (request: TrialSubscriptionRequest) => {
	return httpClient
		.post(`${environment().dmsBaseApi}/nemeton-proxy-api/subscription/trial`, null, {
			params: request,
		})
		.then((res) => res.data);
};

export const getCurrentSubscription = (workspace: Workspace) => {
	const { dmsBaseApi } = environment();
	return httpClient
		.get(`${dmsBaseApi}/nemeton-proxy-api/subscription/current`, {
			params: {
				customerId: workspace.id,
			},
		})
		.then((res) => res.data);
};

export const freeSubscription = (request: TrialSubscriptionRequest) => {
	const { dmsBaseApi } = environment();
	return httpClient.post(`${dmsBaseApi}/nemeton-proxy-api/subscription/free`, null, { params: request }).then((res) => res.data);
};
