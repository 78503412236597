import React, { ReactElement, useMemo, useRef } from 'react';

import { useMenuItemAnimations } from '../hooks/use-animations';
import { MenuItemIconContainer } from '../components/menu-item-icon-container/menu-item-icon-container';
import { MenuItemTooltip } from '../components/menu-item-tooltip/menu-item-tooltip';
import { getShortWorkMaxLength } from '../utils/getShortWordMaxLength';
import { MenuItemButtonProps } from './menu-item-button-types';
import { StyledMenuItemButton } from './menu-item-button-styles';

export function MenuItemButton({
	collapsed,
	icon: MenuItemIcon,
	id,
	isBeta,
	title,
	notificationCounter,
	onClick,
	shouldShortenText = false,
}: MenuItemButtonProps): ReactElement {
	const titleRef = useRef<HTMLSpanElement>(null);

	const hasNotifications = useMemo(() => notificationCounter > 0, [notificationCounter]);
	const shortWordMaxLength = useMemo(() => getShortWorkMaxLength(isBeta, hasNotifications), [
		hasNotifications,
		isBeta,
	]);

	useMenuItemAnimations({
		collapsed,
		title: titleRef.current,
	});

	return (
    <MenuItemTooltip title={title} isHidden={!collapsed}>
			<StyledMenuItemButton.Container
				$hasNotifications={collapsed && hasNotifications}
				id={String(id)}
				onClick={onClick}
			>
        <MenuItemIconContainer 
          title={title}
          titleRef={titleRef}
          shortWordMaxLength={shortWordMaxLength}
          shouldShortenText={shouldShortenText}
          icon={MenuItemIcon}
        />
			</StyledMenuItemButton.Container>
    </MenuItemTooltip>
	);
}
