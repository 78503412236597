import { ModuleSetting } from '@topbar/src/services/sidebar';

import { sortModuleSettingsByOrder } from './sort-by-order';

type Indexes = {
	startIndex: number;
	endIndex: number;
};

type OrderByDirectionProps = Indexes & {
	moduleSetting: ModuleSetting;
};

function reorderByNegativeDirection({ startIndex, endIndex, moduleSetting }: OrderByDirectionProps): ModuleSetting {
	if (moduleSetting.order === startIndex) {
		return { ...moduleSetting, order: endIndex };
	}

	if (moduleSetting.order >= endIndex && moduleSetting.order < startIndex) {
		return { ...moduleSetting, order: moduleSetting.order + 1 };
	}

	return moduleSetting;
}

function reorderByPositiveDirection({ startIndex, endIndex, moduleSetting }: OrderByDirectionProps): ModuleSetting {
	if (moduleSetting.order === startIndex) {
		return { ...moduleSetting, order: endIndex };
	}

	if (moduleSetting.order <= endIndex && moduleSetting.order > startIndex) {
		return { ...moduleSetting, order: moduleSetting.order - 1 };
	}

	return moduleSetting;
}

type ReorderModuleSettingsByDirectionProps = Indexes & {
	modulesSettings: readonly ModuleSetting[];
};

enum Direction {
	NEGATIVE = -1,
	POSITIVE = 1,
}

export function reorderModuleSettingsByIndex({ startIndex, endIndex, modulesSettings }: ReorderModuleSettingsByDirectionProps) {
	const direction = endIndex - startIndex > 0 ? Direction.POSITIVE : Direction.NEGATIVE;

	const reorderedModuleSettings = modulesSettings.map((item) =>
		direction === Direction.NEGATIVE
			? reorderByNegativeDirection({
					startIndex,
					endIndex,
					moduleSetting: item,
			  })
			: reorderByPositiveDirection({
					startIndex,
					endIndex,
					moduleSetting: item,
			  })
	);

	const sortedmodulesSettings = sortModuleSettingsByOrder(reorderedModuleSettings);

	return sortedmodulesSettings;
}
