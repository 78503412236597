import React, { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { SideMenuContent, SideMenuListItem } from '@dartech/dms-ui';
import { Box } from '@mui/material';
import { ReactComponent as DangerIcon } from '@topbar/src/assets/danger_icon.svg';
import { getProject } from '@topbar/src/services/projects';

import useStyles from '../style';
import { projectSettingsBehaviorSubject$ } from './behavior-subject';
import { SettingsContentProps } from './interfaces';

const SettingsContent = ({ isMini, routes, variant, projectId }: SettingsContentProps) => {
	const { classes } = useStyles({ isMini, variant });
	const history = useHistory();

	const { data, refetch } = useQuery({
		queryKey: 'project',
		queryFn: () => getProject(projectId),
		enabled: !!projectId,
	});

	const onClick = (e, route) => {
		if (route?.length) {
			history.push(route);
		}
	};

	const isDeactivatedUsersInTeam = useMemo(() => {
		if (data) {
			return data.team.some((item) => item.deactivate);
		}
		return false;
	}, [data]);

	const isDeactivatedManager = useMemo(() => {
		if (data) {
			return data.team.find((item) => item.userId === data.projectManager)?.deactivate;
		}
		return false;
	}, [data]);

	useEffect(() => {
		if (projectSettingsBehaviorSubject$) {
			const subscription = projectSettingsBehaviorSubject$.subscribe((value) => {
				if (value) {
					refetch();
				}
			});

			return () => subscription.unsubscribe();
		}
	}, []);

	return (
		<SideMenuContent>
			{routes.map(({ itemId, title, route, Icon }) => {
				if (itemId) {
					return (
						<Box className={classes.listItemWrapper}>
							<SideMenuListItem
								title={title}
								itemId={itemId}
								icon={<Icon className={classes.menuIcon} />}
								customClickHandler={onClick}
								route={route}
								filledIcon={<Icon className={classes.filledMenuIcon} />}
							/>
							{itemId === 'projectDetails' && isDeactivatedManager && <DangerIcon className={classes.endIcon} />}
							{itemId === 'projectTeam' && isDeactivatedUsersInTeam && <DangerIcon className={classes.endIcon} />}
						</Box>
					);
				}
				return null;
			})}
		</SideMenuContent>
	);
};

export { SettingsContent };
