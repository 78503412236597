import React, { createContext, useCallback, useContext, useState } from 'react';
import { useMutation } from 'react-query';

import { verifyApprovalOTP } from '../../services/approvals';
import { verifyCounterpartyOTP } from '../../services/counterparties';
import { clearTokens, getTokens, setTokens } from '../../utils/storage-helper';

interface AuthContextType {
	logout: () => void;
	setToken: (token: string | null) => void;
	handleCounterpartyLogin: ({
		counterId,
		docId,
		password,
	}: {
		counterId: string;
		docId: number;
		password: string;
	}) => Promise<void>;
	handleApprovalLogin: ({
		approvalId,
		docId,
		password,
	}: {
		approvalId: number;
		docId: number;
		password: string;
	}) => Promise<void>;
	isAuthenticated: boolean;
	isLoginLoading: boolean;
}

const AuthContext = createContext<AuthContextType | {}>({});

function AuthProvider(props: any): JSX.Element {
	const { access: initialAccessToken } = getTokens();
	const [token, setToken] = useState(initialAccessToken);

	const handleSetTokens = ({ access }: { access: string }): void => {
		setToken(access);
		setTokens({ access });
	};

	const { mutateAsync: handleCounterpartyLogin, isLoading: isCounterpartyLoading } = useMutation(
		({ counterId, docId, password }: { counterId: string; docId: number; password: string }) =>
			verifyCounterpartyOTP(counterId, docId, password),
		{
			onSuccess: (payload) => {
				handleSetTokens({ access: payload.auth_token });
			},
		}
	);

	const { mutateAsync: handleApprovalLogin, isLoading: isApprovalLoading } = useMutation(
		({ approvalId, docId, password }: { approvalId: number; docId: number; password: string }) =>
			verifyApprovalOTP(approvalId, docId, password),
		{
			onSuccess: (payload) => {
				handleSetTokens({ access: payload.auth_token });
			},
		}
	);

	const logout = () => {
		clearTokens();
		setToken(null);
	};

	return (
		<AuthContext.Provider
			value={{
				logout,
				setToken,
				handleCounterpartyLogin,
				handleApprovalLogin,
				isAuthenticated: !!token,
				isLoginLoading: isCounterpartyLoading || isApprovalLoading,
			}}
			{...props}
		/>
	);
}

function useAuth(): AuthContextType {
	const context = useContext(AuthContext);
	if (context === undefined || (typeof context === 'object' && Object.keys(context).length === 0)) {
		throw new Error(`useAuth must be used within an AuthProvider`);
	}
	return context as AuthContextType;
}

export { AuthProvider, useAuth };
