import { animate } from 'motion';
import { useEffect } from 'react';

type UseMenuItemAnimations = {
	collapsed: boolean;
	additionalStateContainerId?: string;
	title: HTMLElement;
};

export function useMenuItemAnimations({ collapsed, additionalStateContainerId, title }: UseMenuItemAnimations): void {
	useEffect(() => {
		const additionalStateContainer = document.getElementById(additionalStateContainerId);

		const target = additionalStateContainer
			? [title, additionalStateContainer]
			: title;

		if (title) {
			if (collapsed) {
				animate(target, { opacity: 0 }, { duration: 0.4 }).finished.then(() => {
					animate(target, { visibility: 'hidden' });
				});
			} else {
				animate(target, { visibility: 'visible' }).finished.then(() => {
					animate(target, { opacity: 1 }, { duration: 0.6 });
				});
			}
		}
	}, [collapsed, title, additionalStateContainerId]);
}
