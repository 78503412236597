import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';

import { getTokens, setTokens } from '../utils/storage-helper';

interface AuthContextType {
	isAuthenticated: boolean;
	setAuthenticated: (value: boolean, token?: string) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
	children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
	const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

	useEffect(() => {
		const { access } = getTokens();
		setIsAuthenticated(!!access);
	}, []);

	const setAuthenticated = (value: boolean, token?: string) => {
		if (value && token) {
			setTokens({ access: token });
		} else if (!value) {
			setTokens({ access: null });
		}
		setIsAuthenticated(value);
	};

	return <AuthContext.Provider value={{ isAuthenticated, setAuthenticated }}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextType => {
	const context = useContext(AuthContext);
	if (context === undefined) {
		throw new Error('useAuth must be used within an AuthProvider');
	}
	return context;
};
