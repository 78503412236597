import qs from 'qs';

import { EntityTypes, NotificationCounters, NotificationMessage, TotalNotificationCounters } from '@dar/api-interfaces';

import { environment } from '../environments/environment';
import { httpClient } from './clients';

export const NOTIFICATION_PAGE_SIZE = 10;

const getNotifications = (userId: string, read?: boolean, entities?: EntityTypes[], page = 0) => {
	return httpClient
		.get<NotificationMessage[]>(`${environment().dmsBaseApi}/notification-read-api/notification/filter`, {
			params: {
				userId,
				size: NOTIFICATION_PAGE_SIZE,
				page,
				...(entities && entities.length && { entities }),
				...(read !== null && read !== undefined && { read }),
			},
			paramsSerializer: (params) => {
				return qs.stringify(params, { arrayFormat: 'repeat' });
			},
		})
		.then((res) => res.data);
};

const markAsReadByBatch = (notificationIds: string[], read: boolean, userId: string) => {
	return httpClient.post(`${environment().dmsBaseApi}/main-api/readNotificationsById/${read}`, notificationIds);
};

const markAllAsRead = (userId: string) => {
	return httpClient.post(`${environment().dmsBaseApi}/main-api/read/all`);
};

const deleteNotification = (notificationId: string, userId: string) => {
	return httpClient.delete(`${environment().dmsBaseApi}/main-api/deleteNotificationById/${notificationId}`);
};

const deleteAllNotifications = (userId: string) => {
	return httpClient.delete(`${environment().dmsBaseApi}/main-api/deleteAllNotifications`);
};

const getNotificationsCounters = (userId: string, companyId: string) => {
	return httpClient
		.get<{ companyId: string; counters: NotificationCounters; userId: string }>(
			`${environment().dmsBaseApi}/notification-read-api/notification/new-counter`,
			{
				params: {
					companyId,
					userId,
				},
			}
		)
		.then((res) => res?.data?.counters);
};

const getNotificationsCountersByHoldings = (holdings: string[]) => {
	return httpClient
		.get<TotalNotificationCounters>(`${environment().dmsBaseApi}/notification-read-api/notification/new-counter/total`, {
			params: {
				holdings: holdings.join(','),
			},
		})
		.then((res) => res?.data);
};

export const notificationsService = {
	getNotifications,
	markAsReadByBatch,
	markAllAsRead,
	deleteNotification,
	deleteAllNotifications,
	getNotificationsCounters,
	getNotificationsCountersByHoldings,
};
