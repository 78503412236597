export class ChatService {
	private url: string;
	private callback: () => void;

	public setUrl(url: string) {
		this.url = url;
	}

	public getUrl() {
		return this.url;
	}

	public setCallback(callback: () => void) {
		this.callback = callback;
	}

	public goToMessage(userId: string) {
		if (this.callback) {
			this.callback();
			this.url = `${this.url}/messages/${userId}`;
		}
	}
}
