import React from 'react';
import { Box, Button, Dialog, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@dartech/dms-ui/dist/v2/icons/navigation/Close';
import WarningIcon from '@dartech/dms-ui/dist/v2/icons/alert/Warning';

import useLeaveModuleConfirmationModalStyles from './styles';

interface Props {
  open: boolean;
  title: string;
  description: string;
  stayButtonText: string;
  leaveButtonText: string;
  onClose: () => void;
  onStay: () => void;
  onLeave: () => void;
}

const LeaveModuleConfirmationModal = ({
  open,
  title,
  description,
  stayButtonText,
  leaveButtonText,
  onClose,
  onStay,
  onLeave
}: Props) => {
  const { classes } = useLeaveModuleConfirmationModalStyles();

  return (
    <Dialog
      open={open}
      PaperProps={{ className: classes.dialogPaper }}
      BackdropProps={{
        className: classes.backdrop
      }}
    >
      <Box className={classes.closeButtonWrapper}>
        <IconButton onClick={onClose}>
          <CloseIcon color="#8B8C9E"/>
        </IconButton>
      </Box>

      <Box className={classes.dialogContent}>
        <Box className={classes.dialogIconWrapper}><WarningIcon color="#FFA530"/></Box>
        <Typography className={classes.dialogTitle}>{title}</Typography>
        <Typography className={classes.dialogDescription}>{description}</Typography>
      </Box>

      <Box className={classes.dialogActions}>
        <Button onClick={onLeave} color='secondary' variant='contained' size='large'>
          {leaveButtonText}
        </Button>
        <Button onClick={onStay} color='primary' variant='contained' size='large'>
          {stayButtonText}
        </Button>
      </Box>
    </Dialog>
  );
};
export { LeaveModuleConfirmationModal };
