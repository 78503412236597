import client from './client';
import unauthorizedClient from './unauthorizedClient';

type VerifyOTPResponse = {
	uri: string;
	documentId: string;
	counterpartyId: string;
	auth_type: string;
	auth_token: string;
	expiry_date: string;
};

type GetApprovalResponse = {
	id: number;
	email: string;
	isApproved: boolean;
	dateApproved: string;
}[];

type PostApprovalRequestBody = {
	counterpartyId: string;
	documentId: number;
	emails: string[];
};

type PostApprovalResponse = {
	id: number;
	email: string;
	isApproved: boolean;
	dateApproved: string;
}[];

async function sendCounterpartyOTP(counterId: string, docId: number) {
	try {
		const response = await unauthorizedClient.get(`/documents/${docId}/counterparties/${counterId}/send-otp`);
		return response.data;
	} catch (error) {
		throw error;
	}
}

async function verifyCounterpartyOTP(counterId: string, docId: number, password: string): Promise<VerifyOTPResponse> {
	try {
		const response = await unauthorizedClient.post(`/documents/${docId}/counterparties/${counterId}/verify-otp`, null, {
			params: { password },
		});
		return response.data;
	} catch (error) {
		throw error;
	}
}

async function getApprovals(counterpartyId: string, documentId: number): Promise<GetApprovalResponse> {
	try {
		const response = await client.get(`/counterparties/approvals`, {
			params: { counterpartyId, documentId },
		});
		return response.data;
	} catch (error) {
		throw error;
	}
}

async function postApprovals(body: PostApprovalRequestBody): Promise<PostApprovalResponse> {
	try {
		const response = await client.post(`/counterparties/approvals`, body);
		return response.data;
	} catch (error) {
		throw error;
	}
}

async function deleteApproval(id: number): Promise<void> {
	try {
		await client.delete(`/counterparties/approvals/${id}`);
	} catch (error) {
		throw error;
	}
}

export { sendCounterpartyOTP, verifyCounterpartyOTP, getApprovals, postApprovals, deleteApproval };
