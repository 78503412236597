import { ModuleSetting, updatePinnedMenuItems } from '@topbar/src/services/sidebar';

type PutModuleSettings = {
	modulesSettings: readonly ModuleSetting[];
	onSuccess: () => void;
	employeeId: string;
	workspaceId: string;
};

export async function putModuleSettings({ modulesSettings, onSuccess, employeeId, workspaceId }: PutModuleSettings) {
	try {
		await updatePinnedMenuItems(modulesSettings, workspaceId, employeeId);

		onSuccess();
	} catch {
		// TODO: Add error handling
	}
}
