import styled from '@emotion/styled';
import { Box } from '@mui/material';

const Container = styled.ul`
	list-style-type: none;
	padding: ${(props) => props.theme.spacing['positive-150']} ${(props) => props.theme.spacing['positive-100']};
	position: relative;

	& li:not(:last-child) {
		margin-bottom: ${(props) => props.theme.spacing['positive-50']};
	}
`;

const DefaultContainer = styled(Container)`
	min-height: 164px;
	margin-block-end: 0;
	margin-block-start: 0;
`;

const PinnedContainer = styled(Container)<{ $collapsed: boolean; height?: string }>`
	border-top: ${(props) => props.theme.border.default};
	height: ${(props) => (props.height ? props.height : 'auto')};
	overflow-y: auto;
	overflow-x: hidden;
	will-change: height;
	max-height: var(--pinned-menu-items-list-height);

	${(props) =>
		props.$collapsed &&
		`
		max-height: var(--pinned-menu-items-list-height-collapsed);
	`}
`;

const ToggleModalListItem = styled.li`
	position: absolute;
	bottom: 0;
	width: 100%;
`;

const PinnedWrapper = styled(Box)<{ $height: number }>`
	height: ${(props) => props.$height}px;
	position: relative;
`;

export const StyledMenuList = {
	DefaultContainer,
	PinnedContainer,
	ToggleModalListItem,
	PinnedWrapper,
};
