import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';

import { projectSettingsBehaviorSubject$ } from './components/project-nav/internal/behavior-subject';
import { counter$ } from './hooks/useNotificationsCounter';
import { Docflow } from './pages/docflow/docflow';
import { Root } from './root';
import { notificationService } from './topbar/app';
import { billingService } from './topbar/app';
import { chatService } from './topbar/components/chat-link';
import { authService } from './topbar/providers/auth-provider';
import { isNavMini$ } from './topbar/providers/brand-provider';
import { language } from './topbar/providers/localization-provider';
import { useConfirm } from './utils/useConfirm';

const lifecycles = singleSpaReact({
	React,
	ReactDOM,
	rootComponent: Root,
	errorBoundary(err, info, props) {
		// Customize the root error boundary for your microfrontend here.
		return null;
	},
});

export const { bootstrap, mount, unmount } = lifecycles;

export {
	authService,
	notificationService,
	chatService,
	language,
	billingService,
	isNavMini$,
	counter$,
	useConfirm,
	Docflow,
	projectSettingsBehaviorSubject$,
};
