export function getVh(measure: number): string {
	return `calc(${measure} * var(--vh))`;
}

export const flexCenterStyles = `
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const flexHorizontalSpaceBetweenStyles = `
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
