import { AddInfo } from '@dartech/griffon-auth';

export const newUserStorageKey = 'newUserCreds';

export type UserRolesStructure = 'ADMIN' | 'OWNER' | 'MEMBER' | 'GUEST';

export type UserStatus = 'ACTIVE' | 'INACTIVE' | 'MATERNITY_LEAVE' | 'TERMINATED';

export type InvitationStatus = 'ACCEPTED' | 'PENDING' | 'EXPIRED';

export interface UserName {
	first: string;
	last: string;
	middle: string;
}

export type NewUserCreds = {
	username: string;
	firstName?: string;
	lastName?: string;
	phone?: string;
	sid?: string;
	verification_token?: string;
} & AddInfo;

export interface SignupCreed {
	username: string | undefined;
	sid: string;
	cool_down_ending_date_time: string;
	cool_down: string;
}

export interface UserStructureQueryParams {
	nameOrId?: string;
	statuses?: UserStatus;
	roles?: UserRolesStructure | UserRolesStructure[];
	invitationStatusList?: InvitationStatus;
}

export type UserStructureResponseDto = PaginatedResponse<StructureUserDto>;

export interface StructureUserDto extends UserBase {
	id: string;
	employeeId: string;
	userName: string;
	invitationStatus: InvitationStatus;
	photo: string;
}

export interface UserBase {
	role: UserRolesStructure;
	status: UserStatus;
	name: UserName;
}

export interface PaginatedResponse<T> {
	content: T[];
	empty: boolean;
	first: boolean;
	last: boolean;
	number: number;
	numberOfElements: number;
	size: number;
	totalElements: number;
	totalPages: number;
	pageable: {
		sort: {
			empty: boolean;
			unsorted: boolean;
			sorted: boolean;
		};
		offset: number;
		pageNumber: number;
		pageSize: number;
		paged: boolean;
		unpaged: boolean;
	};
	sort: {
		empty: boolean;
		unsorted: boolean;
		sorted: boolean;
	};
}
