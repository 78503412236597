export enum Matomo {
	nano = '1',
}

export enum MATOMO_KEYS {
	HOME = 'Home_side-bar_web',
	MY_WORK_TASKS = 'Click_on_Tasks_web',
	MY_WORK_PROCESSES = 'Click_on_Processes_web',
	MY_WORK_PROJECT = 'Click_on_Projects_web',
	MY_WORK_PLANS = 'Click_on_plans_web',
	MY_WORK_BUDGETS = 'Click_on_Budgets_web',
	MY_WORK_DISK = 'Click_on_Drive_web',
	MY_WORK_LMS = 'Click_on_training_web',
	MEETINGS_MEETINGS = 'Click_on_Calendar_web',
	MEETINGS_NOTES = 'Click_on_Meetings_notes_web',
	TEAM_TIMESHEET = 'Click_on_Timesheet_web',
	TEAM_ORG_STRUCTURE = 'Click_on_Org_structure_web',
	TEAM_HOLDING_STRUCTURE = 'Click_on_Holding_structure_web',
	DOC_FLOW = 'DOC_flow',
	DOC_FLOW_DOCUMENTS = 'Click_on_DOC_flow_documents_web',
	WORKSPACES = 'Click_on_Spaces_web',
	PROFILE = 'Click_on_Profile_web',
}
