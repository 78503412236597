import { useQuery } from 'react-query';

import { Workspace } from '@dar/api-interfaces';
import { Profile } from '@dartech/griffon-auth';
import { getEmployeeWorkspaces } from '@topbar/src/services/companies';

type UseWorkspacesByEmployeeId = {
	onError: () => void;
	profileId: Profile['id'];
};

const GET_WORKSPACES_BY_EMPLOYEE_ID_QUERY_KEY = 'fetch-workspaces';

export function useWorkspacesByEmployeeId({ onError, profileId }: UseWorkspacesByEmployeeId): Workspace[] {
	const { data: workspaces } = useQuery(GET_WORKSPACES_BY_EMPLOYEE_ID_QUERY_KEY, () => getEmployeeWorkspaces(profileId), {
		onError,
		enabled: !!profileId,
	});

	return workspaces;
}
