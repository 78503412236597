/* eslint-disable react-hooks/rules-of-hooks */
import jwtDecode from 'jwt-decode';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { Box, Menu, MenuItem, Theme, Typography } from '@mui/material';
import { ReactComponent as ChevronRightIcon } from '@topbar/src/assets/icons/chevron_right_regular.svg';
import { WORKSPACE_STORAGE_KEY, WORKSPACE_TOKEN_STORAGE_KEY } from '@topbar/src/constants/workspace';
import { checkPermission } from '@topbar/src/utils/permissions';

import { IData } from '../nav-interface';

interface Props {
	name: string;
	anchorEl: Element;
	onClose: () => void;
	data: IData[];
	settings?: {
		width?: string;
		anchorOrigin?: {
			vertical: number | 'bottom' | 'top' | 'center';
			horizontal: number | 'center' | 'left' | 'right';
		};
		transformOrigin?: {
			vertical: number | 'bottom' | 'top' | 'center';
			horizontal: number | 'center' | 'left' | 'right';
		};
	};
}

const useStyles = makeStyles<{ width: string }>()((theme, { width }) => ({
	paper: {
		border: '1px solid rgba(38, 40, 66, 0.08)',
		boxShadow: '0px 8px 16px rgb(38 40 66 / 4%)',
		borderRadius: '12px',
		width: width ? width : '240px',
		transform: 'translate(2%, 0px) !important',
	},
	option: {
		display: 'flex',
		'&:hover': {
			background: 'rgba(38, 40, 66, 0.04)',
		},
	},
	arrowOption: {
		display: 'flex',
		alignItems: 'center',
		gap: '8px',
		'&:hover': {
			background: 'rgba(38, 40, 66, 0.04)',
		},
	},
	arrowDisplay: {
		justifyContent: 'space-between',
	},
}));

const MenuSettings = ({ name, anchorEl, onClose, data, settings }: Props) => {
	const { classes } = useStyles({ width: settings?.width });
	const history = useHistory();

	const tokenDecodablePart = JSON.parse(localStorage.getItem(WORKSPACE_STORAGE_KEY));
	const role = tokenDecodablePart?.role;

	const workspaceToken = localStorage.getItem(WORKSPACE_TOKEN_STORAGE_KEY);
	const decodedWorkspace: { permissions: any } = workspaceToken && jwtDecode(workspaceToken);
	const permissions = decodedWorkspace?.permissions;

	const selectHandler = (item) => {
		item?.route && history.push(item.route, { from: history.location.pathname + history.location.search });
	};

	return (
		<Menu
			id={`menu-${name}`}
			disableEnforceFocus
			disableRestoreFocus
			anchorEl={anchorEl}
			classes={{ paper: classes.paper }}
			anchorOrigin={settings?.anchorOrigin}
			transformOrigin={settings?.transformOrigin}
			open={Boolean(anchorEl)}
			onClose={onClose}
			MenuListProps={{
				style: { padding: '8px 0', width: '100%' },
			}}
		>
			{data.reduce((acc: any, item: IData) => {
				if (
					item?.children?.length &&
					(['ADMIN', 'OWNER'].includes(role) || checkPermission([...item.permissions], permissions))
				) {
					const [anchorEl, setAnchorEl] = useState();

					return [
						...acc,
						<div>
							<MenuItem
								className={`${classes.option} ${classes.arrowDisplay}`}
								onClick={(e: any) => setAnchorEl(e.currentTarget)}
							>
								<Box className={classes.arrowOption}>
									<item.icon />
									<Typography>{item.title}</Typography>
								</Box>

								<ChevronRightIcon />
							</MenuItem>
							<MenuSettings
								data={item.children}
								name={item.title}
								anchorEl={anchorEl}
								onClose={() => setAnchorEl(null)}
								settings={item.settings}
							/>
						</div>,
					];
				}
				if (['ADMIN', 'OWNER'].includes(role) || checkPermission([...item.permissions], permissions)) {
					return [
						...acc,
						<MenuItem className={classes.arrowOption} onClick={() => selectHandler(item)}>
							<item.icon />
							<Typography>{item.title}</Typography>
						</MenuItem>,
					];
				}
			}, [])}
		</Menu>
	);
};

export { MenuSettings };
