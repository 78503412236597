import React, { ReactElement } from 'react';

import { Tooltip } from '@mui/material';

type TypographyWithHintProps = {
	text: string;
	render: (copy: string) => ReactElement;
	shortWordMaxLength?: number;
};

const SHORT_WORD_MAX_LENGTH = 15;

export function TypographyWithHint({
	text,
	render,
	shortWordMaxLength = SHORT_WORD_MAX_LENGTH,
}: TypographyWithHintProps): ReactElement {
	const isLongCopy = text?.length > shortWordMaxLength;

	if (isLongCopy) {
		return (
			<Tooltip placement='bottom' arrow title={text} PopperProps={{ disablePortal: true }}>
				{render(`${text.substring(0, shortWordMaxLength - 1)}...`)}
			</Tooltip>
		);
	}

	return render(text);
}
