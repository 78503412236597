import React, { useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import { Menu, MenuItem, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { StyledRegionMenu } from './region-menu-styles';

const regions = [
	{ value: 'kz', label: 'Kazakhstan' },
	{ value: 'id', label: 'Indonesia' },
	{ value: 'eu', label: 'Europe' },
];

const RegionMenu = ({ anchorEl, onClose }) => {
	const languageMenuOpen = Boolean(anchorEl);
	const [selectedRegion, setSelectedRegion] = useState<string>(localStorage.getItem('region') || regions[0].value);

	const handleSelectRegion = (region: string) => {
		localStorage.setItem('region', region);
		setSelectedRegion(region);
		window.location.reload();
		onClose();
	};

	return (
		<Menu
			id='menu-languages'
			disableEnforceFocus
			disableRestoreFocus
			anchorEl={anchorEl}
			
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			open={languageMenuOpen}
			onClose={onClose}
			MenuListProps={{
				style: { padding: '8px 0', width: '100%' },
			}}
			PaperProps={{
				style: {
					borderRadius: '0px 0px 4px 4px',
					border: 'none',
					width: '200px',
					zIndex: 1200,
					transform: 'translate(105%, 15%)',
				},
			}}
			defaultValue={selectedRegion}
		>
			{regions.map((item, index) => (
				<StyledRegionMenu.MenuItem
					key={index}
					value={item.value}
					selected={item.value === selectedRegion}
					onClick={() => handleSelectRegion(item.value)}
				>
					<Typography
						style={{
							fontSize: '14px',
							lineHeight: '18px',
							fontWeight: 400,
						}}
					>
						{item.label}
					</Typography>
					{selectedRegion === item.value && <CheckIcon style={{ color: '#6D7C8B', marginRight: '9px' }} />}
				</StyledRegionMenu.MenuItem>
			))}
		</Menu>
	);
};

export { RegionMenu };
