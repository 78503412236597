import React, { Suspense, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { Route, useLocation } from 'react-router-dom';

import { BillingService } from '@topbar/src/billing/billing-service';
import { useCookieScripts } from '@topbar/src/components/cookie-scripts';
import { ProjectModule } from '@topbar/src/components/project-nav/interfaces';
import { Spinner } from '@topbar/src/components/spinner-loader';
import { getSurveyStructure } from '@topbar/src/services/workspace';

import { NotificationService } from '../../notifications/notification-service';
import { useAuth } from '../providers/auth-provider';
import routes from './app-lazy';
import { AppRouting } from './app-routing';
import { Settings } from './app-settings';

export const notificationService = new NotificationService();
export const billingService = new BillingService();

const App = () => {
	const { loadScripts } = useCookieScripts();

	const { authenticated, invited, workspace, workspaceProcessing, profile, setSurveyStructure } = useAuth();
	const { pathname } = useLocation();
	const paths = pathname.split('/').slice(1);
	const isWorkspaceArea = paths[0].includes('workspace');
	const isAdmin =
		paths[0].includes('admin') || pathname.includes('/people/settings') || pathname.includes('/processes/settings/roles');

	useEffect(() => {
		authenticated && loadScripts();
	}, [authenticated, loadScripts]);

	useEffect(() => {
		workspace && getSurveyStructure().then(setSurveyStructure);
	}, [workspace]);

	const qc = useQueryClient();
	//Listen to event when profile data updates.
	//Like userName or user avatar
	useEffect(() => {
		const listener = () => {
			qc.invalidateQueries(['GET_EMPLOYEE_ITEM']);
		};

		window.addEventListener('PROFILE_UPDATE', listener);

		return () => {
			window.removeEventListener('PROFILE_UPDATE', listener);
		};
	}, [qc]);

	// useEffect(() => {
	// 	if (registrationRoutes.includes(pathname) || pathname.includes('/invitation/')) {
	// 		localStorage.setItem('pageUrl', '"/overview"');
	// 	} else {
	// 		localStorage.setItem('pageUrl', JSON.stringify(pathname));
	// 	}
	// }, [pathname]);

	if (workspaceProcessing) return <Spinner fullscreen />;

	//Proceed further to reauthenticate with new token from landing
	if (
		authenticated &&
		!invited &&
		workspace &&
		workspace.isSurveyFinished &&
		!isWorkspaceArea &&
		!pathname.includes('from-landing')
	) {
		if (isAdmin) {
			return (
				<Suspense fallback>
					<routes.SettingsNav />
				</Suspense>
			);
		}
		if (paths.includes('docflow-sign')) {
			return (
				<Suspense fallback>
					<Route path='/docflow-sign' component={routes.Docflow} />
				</Suspense>
			);
		}
		if (paths[0].includes('congratulations')) {
			return (
				<Suspense fallback>
					<Route path='/congratulations' component={routes.Congrats} />
				</Suspense>
			);
		}
		if (paths.includes(ProjectModule.projects) || paths.includes(ProjectModule.plans)) {
			if (paths.includes('settings')) {
				return (
					<Suspense fallback>
						<routes.ProjectSettingsNav module={paths[0] as ProjectModule} />
					</Suspense>
				);
			}
			if (paths.includes('detail')) {
				return (
					<Suspense fallback>
						<routes.ProjectTabNav module={paths[0] as ProjectModule} />
					</Suspense>
				);
			}
		}
		if (paths.includes('budgets')) {
			if (paths.some((s) => ['plan', 'fact', 'forecast', 'analysis', 'dictionary'].includes(s))) {
				return (
					<Suspense fallback>
						<routes.BudgetTabNav />
					</Suspense>
				);
			}
		}
		// if (paths.includes('budget') && paths.includes('settings')) {
		// 	return (
		// 		<Suspense fallback>
		// 			<routes.BudgetSettingsNav />
		// 		</Suspense>
		// 	);
		// }
		if (paths.includes('processes') && paths.includes('settings') && paths.includes('groups')) {
			return (
				<Suspense fallback>
					<routes.ProcessesSettingsNav />
				</Suspense>
			);
		}
		if (paths.includes('cnb-settings')) {
			return (
				<Suspense fallback>
					<routes.CnbSettingsNav />
				</Suspense>
			);
		}
		return (
			<Suspense fallback>
				<routes.Nav />
				<Settings />
			</Suspense>
		);
	}

	return <AppRouting authenticated={authenticated} workspace={workspace} profile={profile} isWorkspaceArea={isWorkspaceArea} />;
};

export { App };
