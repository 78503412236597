import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge } from '@mui/material';
import { BadgeContext } from '@topbar/src/contexts/badge-context/badge-context';

import { Tooltip } from '../components/shared/tooltip/tooltip';
import { ReactComponent as BitrixIcon } from '../svg/bitrix.svg';
import { ReactComponent as Chat } from '../svg/chat.svg';
import { ReactComponent as Collapse } from '../svg/collapse.svg';
import { ReactComponent as Open } from '../svg/open.svg';
import { useFooterAnimations } from './hooks/use-footer-animations';
import { StyledFooter } from './sidebar-footer-styles';

type SidebarFooterProps = {
	collapsed: boolean;
	toggleCollapse?: () => void;
	onChatClick?: () => void;
};

const SIDEBAR_FOOTER_ID = 'sidebar-footer-id';
const CHAT_SECTION_ID = 'chat-section-id';

export function SidebarFooter({ collapsed, toggleCollapse, onChatClick }: SidebarFooterProps): ReactElement {
	const { t } = useTranslation();
	const [showTooltip, setShowTooltip] = useState(false);
	const timeoutRef = useRef<number>();

	const { badges } = useContext(BadgeContext);
	const isUnreadChat = !!badges?.CHAT;

	const shouldHaveChatButton = useMemo(() => typeof onChatClick === 'function', [onChatClick]);

	useFooterAnimations({
		sidebarFooterId: SIDEBAR_FOOTER_ID,
		collapsed,
		chatSectionId: CHAT_SECTION_ID,
		shouldHaveChatButton,
	});

	const onBitrixClick = useCallback(() => {
		const bitrixButton: HTMLElement = document.querySelector('.b24-widget-button-icon-container');
		bitrixButton?.click();
	}, []);

	const osKey = useMemo(() => (navigator.userAgent.includes('Mac') ? '⌘' : 'Ctrl'), []);
	const tooltipTitle = useMemo(() => `${t('general.toggle_sidebar_tooltip')}\n${osKey}+/`, [t]);

	const onMouseOver = useCallback(() => {
		if (collapsed) {
			setShowTooltip(true);
		}
	}, [collapsed]);

	const onMouseLeave = useCallback(() => {
		timeoutRef.current = window.setTimeout(() => {
			setShowTooltip(false);
		}, 300);
	}, []);

	const onToggleCollapse = useCallback(() => {
		toggleCollapse();
		setShowTooltip(false);
	}, [toggleCollapse]);

	useEffect(() => {
		return () => {
			if (timeoutRef.current) {
				window.clearTimeout(timeoutRef.current);
			}
		};
	}, []);

	return (
		<StyledFooter.Container id={SIDEBAR_FOOTER_ID}>
			<StyledFooter.ChatSection id={CHAT_SECTION_ID}>
				{shouldHaveChatButton ? (
					<Badge color='error' variant='dot' invisible={!isUnreadChat}>
						<StyledFooter.ChatButton onClick={onChatClick}>
							<Chat />
						</StyledFooter.ChatButton>
					</Badge>
				) : null}
				<StyledFooter.ChatButton onClick={onBitrixClick}>
					<BitrixIcon />
				</StyledFooter.ChatButton>
			</StyledFooter.ChatSection>
			<Tooltip title={tooltipTitle} arrow placement='right' isHidden={!showTooltip}>
				<StyledFooter.CollapseButton onClick={onToggleCollapse} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
					{collapsed ? <Open /> : <Collapse />}
				</StyledFooter.CollapseButton>
			</Tooltip>
		</StyledFooter.Container>
	);
}
