import React, { ReactElement, useCallback, useState } from 'react';

import { takeShortName } from '@topbar/src/components/nav/utils';

import { Size, WorkspaceAvatarProps } from './workspace-avatar-types';
import { StyledWorkspaceAvatar } from './worskpace-avatar-styles';

export function WorkspaceAvatar({
	workspaceAvatarSrc,
	workspaceName,
	hasNotifications,
	size = Size.DEFAULT,
}: WorkspaceAvatarProps): ReactElement {
	const [hasAvatarLoadError, setAvatarLoadError] = useState(false);

	const onAvatarLoadError = useCallback(() => {
		setAvatarLoadError(true);
	}, []);

	if (workspaceAvatarSrc && !hasAvatarLoadError) {
		return (
			<StyledWorkspaceAvatar.Container $hasNotifications={hasNotifications} $size={size}>
				<StyledWorkspaceAvatar.Logo src={workspaceAvatarSrc} alt={workspaceName} onError={onAvatarLoadError} />
			</StyledWorkspaceAvatar.Container>
		);
	}

	return (
		<StyledWorkspaceAvatar.Container $hasNotifications={hasNotifications} $size={size}>
			{takeShortName(workspaceName)}
		</StyledWorkspaceAvatar.Container>
	);
}
