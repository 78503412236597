import { addModuleSetting } from './add';
import { fetchModuleSettings } from './fetch';
import { getModuleSettingByModuleType } from './get-by-module-type';
import { putModuleSettings } from './put';
import { removeModuleSetting } from './remove';
import { reorderModuleSettingsByIndex } from './reorder-by-index';
import { sortModuleSettingsByOrder } from './sort-by-order';

export const moduleSettingsHelper = {
	add: addModuleSetting,
	fetch: fetchModuleSettings,
	getByModuleType: getModuleSettingByModuleType,
	sortByOrder: sortModuleSettingsByOrder,
	remove: removeModuleSetting,
	reorderByIndex: reorderModuleSettingsByIndex,
	put: putModuleSettings,
};
