import format from 'date-fns/format';
import en from 'date-fns/locale/en-GB';
import kz from 'date-fns/locale/kk';
import ru from 'date-fns/locale/ru';
import dayjs from 'dayjs';
import i18n from 'i18next';

import DateFnsUtils from '@date-io/date-fns';

export const localeMap = {
	en: en,
	kz: kz,
	ru: ru,
};
const locales = {
	ru: () => import('dayjs/locale/ru'),
	en: () => import('dayjs/locale/en'),
	kz: () => import('dayjs/locale/kk'),
};
export function loadLocale() {
	locales[i18n.language.slice(0, 2)]().then(() =>
		dayjs.locale(i18n.language.slice(0, 2) === 'kz' ? 'kk' : i18n.language.slice(0, 2))
	);
	return i18n.language.slice(0, 2);
}
class RuLocalizedUtils extends DateFnsUtils {
	getCalendarHeaderText(date) {
		return format(date, 'LLLL YYYY', { locale: this.locale });
	}

	getDatePickerHeaderText(date) {
		return format(date, 'DD MMMM', { locale: this.locale });
	}
}

class KzLocalizedUtils extends DateFnsUtils {
	getCalendarHeaderText(date) {
		return format(date, 'LLLL YYYY', { locale: this.locale });
	}
	getDatePickerHeaderText(date) {
		return format(date, 'D MMMM YYYY', { locale: this.locale });
	}
}

export const localeUtilsMap = {
	en: DateFnsUtils,
	kz: KzLocalizedUtils,
	ru: RuLocalizedUtils,
};

export const localeFormatMap = {
	en: 'D MMMM YYYY',
	kz: 'D MMMM YYYY',
	ru: 'D MMMM YYYY',
};

export const localeCancelLabelMap = {
	en: 'cancel',
	kz: 'жою',
	ru: 'отмена',
};

export const currentDateLocale = localStorage.getItem('i18nextLng');

export const getRoleLocalizationKey = (role: string): string => {
	switch (role) {
		case 'OWNER':
			return 'roles.owner';
		case 'ADMIN':
			return 'roles.admin';
		case 'MEMBER':
			return 'roles.member';
		case 'GUEST':
			return 'roles.guest';
		default:
			return role;
	}
};

export const hasCyrillic = (value: string) => new RegExp('[ЁёА-я]').test(value);
