import client from './client';
import unauthorizedClient from './unauthorizedClient';

type VerifyOTPResponse = {
	uri: string;
	documentId: string;
	counterpartyId: string;
	auth_type: string;
	auth_token: string;
	expiry_date: string;
};

async function sendApprovalOTP(approvalId: number, docId: number) {
	try {
		const response = await unauthorizedClient.get(`/counterparties/approvals/${approvalId}/document/${docId}/send-otp`);
		return response.data;
	} catch (error) {
		throw error;
	}
}

async function verifyApprovalOTP(approvalId: number, docId: number, password: string): Promise<VerifyOTPResponse> {
	try {
		const response = await unauthorizedClient.post(`/counterparties/approvals/${approvalId}/document/${docId}/verify-otp`, null, {
			params: { password },
		});
		return response.data;
	} catch (error) {
		throw error;
	}
}

async function approveDocument(counterId: number) {
	try {
		await client.put(`/counterparties/approve/${counterId}`);
	} catch (error) {
		throw error;
	}
}

export { sendApprovalOTP, verifyApprovalOTP, approveDocument };
