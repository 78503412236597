import { makeStyles } from 'tss-react/mui';

const useLeaveModalStyles = makeStyles()(() => ({
  backdrop: {
    zIndex: -1
  },
  dialogPaper: {
    width: 500,
    padding: 20,
    borderRadius: 12,
    position: 'relative',
    boxShadow: '0px 4px 16px 0px #00000029'
  },
  closeButtonWrapper: {
    position: 'absolute',
    top: 24,
    right: 24
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 20,
  },
  dialogIconWrapper: {
    width: 64,
    height: 64,
    padding: 8,
    marginBottom: 20,
    fontSize: 48,
    backgroundColor: '#FFE8C2',
    borderRadius: 12
  },
  dialogTitle: {
    fontWeight: 500,
    fontSize: 20,
    textAlign: 'center',
    color: '#262842',
    marginBottom: 8,
  },
  dialogDescription: {
    fontWeight: 400,
    fontSize: 16,
    textAlign: 'center',
    color: '#6D6E85',
  },
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 12
  }
}));
export default useLeaveModalStyles;
