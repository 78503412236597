import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Button } from '../components/shared/button/button';
import { Typography } from '../components/shared/typography/typography';
import { sidebarTheme } from '../sidebar-theme';

const Container = styled(Button)`
	border-top: ${sidebarTheme.border.default};
	width: 100%;
	height: var(--chat-bot-section-height, 58px);
	padding: ${sidebarTheme.spacing['positive-150']};
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	&:hover {
		background: #f7f7f8;
		cursor: pointer;
	}

	&:active {
		background: #eeeef0;
	}
`;

const Title = styled(Typography)`
	color: #262842;
	text-align: left;
`;

const SubTitle = styled(Typography)`
	color: #6d6e85;
	text-align: left;
	opacity: 0.6;
`;

const AnimatedBox = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex: 1;
	margin-left: ${sidebarTheme.spacing['positive-125']};
	will-change: opacity, display;
`;

const TextContainer = styled(Box)`
	display: flex;
	flex-direction: column;
`;

const Counter = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 24px;
	padding-left: 8px;
	color: #262842;
`;

export const StyledChatBotSection = {
	Container,
	Title,
	SubTitle,
	TextContainer,
	Counter,
	AnimatedBox,
};
