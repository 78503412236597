import { MATOMO_KEYS } from '@topbar/src/constants/matomo';
import { getWorkspace } from '@topbar/src/hooks/useCompanyID';

export function trackWorkspaceMenuClick(): void {
	const workspace = getWorkspace();
	window._paq?.push([
		'trackEvent',
		'Nav',
		MATOMO_KEYS.WORKSPACES,
		JSON.stringify({ holdingId: workspace?.holdingId, companyId: workspace?.id }),
	]);
}
