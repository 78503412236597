import { TFunction } from 'i18next';

import { SurveyQuestion } from '@dar/api-interfaces';

import { stepProgress } from '../survey/utils';
import { Config } from './interface';

const config: Record<string, Config> = {
	'/workspace/business-name': {
		title: 'business_name.title',
		description: 'business_name.description',
		progressStep: 1,
	},
	'/workspace/how-will-use': {
		title: 'how_will_use.how_will_use_title',
		description: 'how_will_use.how_will_use_description',
	},
	'/workspace/manage-tools': {
		hasBackButton: true,
		title: 'onboarding.manage_tools_title',
		description: 'onboarding.manage_tools.description',
		progressStep: 5,
	},
};

export function getSettings(pathname: string, step: string, surveyStructure: SurveyQuestion[], t: TFunction) {
	const settings = config[pathname];
	if (settings) {
		return {
			...settings,
			title: t(settings.title),
			description: t(settings.description),
		};
	}

	const surveyStep = surveyStructure?.find((question) => question.key === step);

	if (surveyStep) {
		return {
			title: t(surveyStep.title),
			description: t(surveyStep.description),
			progressStep: stepProgress[step],
		};
	}

	return {
		title: '',
		deserialize: '',
	};
}
