import { useHistory } from 'react-router-dom';

import { UserLanguage, Workspace } from '@dar/api-interfaces';
import { Profile, WORKSPACE_STORAGE_KEY, WORKSPACE_TOKEN_STORAGE_KEY } from '@dartech/griffon-auth';

import { billingService } from '../dar-dms-topbar';
import { getCurrentSubscription } from '../services/billing';
import { getEmployeeWorkspaces } from '../services/companies';
import { setLanguage } from '../services/language';
import { getLastVisitedWorkspace } from '../services/onboarding';
import { getWorkspaceToken } from '../services/workspace';
import { useAuth } from '../topbar/providers/auth-provider';
import { useQuery } from './useQuery';

type ChangeWorkspace = {
	workspace: Workspace;
	profile: Profile;
	toRedirect?: boolean;
	isUseSubscription?: boolean;
};

export const useWorkspace = () => {
	const { setWorkspace } = useAuth();
	const query = useQuery();
	const history = useHistory();
	const authRedirect = query.get('authRedirect');

	const changeWorkspace = async ({ workspace, profile, toRedirect = true, isUseSubscription = true }: ChangeWorkspace) => {
		localStorage.setItem(WORKSPACE_STORAGE_KEY, JSON.stringify(workspace));
		const tokenResponse = await getWorkspaceToken(workspace?.id, profile?.id);
		localStorage.setItem(WORKSPACE_TOKEN_STORAGE_KEY, tokenResponse?.token);
		setWorkspace(workspace);

		if (isUseSubscription) {
			const subscriptionResponse = await getCurrentSubscription(workspace);
			billingService.assignSubscription(subscriptionResponse);
		}

		handleLanguageSave(profile?.id);
		if (toRedirect) {
			history.push(authRedirect || profile?.default_page);
		}
	};

	const changeWorkspaceById = async (workspaceId: string, profile: Profile) => {
		const workspaces = await getEmployeeWorkspaces(profile.id);
		const workspace = workspaces.find((workspaceItem) => workspaceItem.id === workspaceId);
		await changeWorkspace({ workspace, profile });
	};

	const handleLanguageSave = async (userId: string) => {
		const language: string = localStorage.getItem('i18nextLng');
		const data: UserLanguage = {
			userId,
			language,
		};
		await setLanguage(data);
	};

	const setLastWorkspace = async (profile: Profile) => {
		const workspace = await getLastVisitedWorkspace();
		if (!workspace) {
			return history.push('/company/select');
		}
		const workspaceForSave: Workspace = {
			...workspace,
			isModerator: false,
			information: {
				...workspace.organization,
				bin: '',
				isHolding: false,
				parentId: '',
				registrationDate: '',
				isSubsidiary: false,
			},
			token: '',
			contacts: null,
			address: null,
		};
		await changeWorkspace({ workspace: workspaceForSave, profile, toRedirect: true });
	};

	return { changeWorkspace, setLastWorkspace, changeWorkspaceById };
};
