import { t } from 'i18next';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { ProjectSettingsNavProps } from './interfaces';
import { Nav } from './nav';

const ProjectSettingsNav = ({ module }: ProjectSettingsNavProps) => {
	const { pathname } = useLocation();

	const backPath = pathname
		.split('/')
		.filter((_, ind) => ind < 4)
		.join('/');

	return <Nav module={module} variant='light' backPath={backPath} backText={t('projectNav.back_to_projects')} isSettings />;
};

export { ProjectSettingsNav };
