import { useContext, useMemo } from 'react';

import { BadgeContext } from '../../../../contexts/badge-context/badge-context';
import { TransformedCountersByWorkspaceId, transformCountersByWorkspaceId } from '../utils/transform-counters-by-workspace';

export function useTransformedCountersByWorkspaceId(): TransformedCountersByWorkspaceId {
	const { badgesByHoldings } = useContext(BadgeContext);

	const countersByWorkspaceId = useMemo(() => {
		if (!badgesByHoldings?.workspaces) {
			return {};
		}

		return transformCountersByWorkspaceId(badgesByHoldings.workspaces);
	}, [badgesByHoldings]);

	return countersByWorkspaceId;
}
