import React, { ReactNode, createContext, useContext, useState } from 'react';

interface ModalConfig {
	isOpen: boolean;
	props?: any;
}

interface ModalState {
	[key: string]: ModalConfig;
}

interface ModalContextValue {
	modals: ModalState;
	openModal: (modalId: string, props?: any) => void;
	closeModal: (modalId: string) => void;
	isModalOpen: (modalId: string) => boolean;
	getModalProps: (modalId: string) => any;
}

const ModalContext = createContext<ModalContextValue | undefined>(undefined);

interface ModalProviderProps {
	children: ReactNode;
	initialModals?: string[];
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children, initialModals = [] }) => {
	const [modals, setModals] = useState<ModalState>(() => {
		const initialState: ModalState = {};
		initialModals.forEach((modalId) => {
			initialState[modalId] = { isOpen: false, props: {} };
		});
		return initialState;
	});

	const openModal = (modalId: string, props?: any) => {
		setModals((prev) => ({
			...prev,
			[modalId]: { isOpen: true, props },
		}));
	};

	const closeModal = (modalId: string) => {
		setModals((prev) => ({
			...prev,
			[modalId]: { ...prev[modalId], isOpen: false },
		}));
	};

	const isModalOpen = (modalId: string): boolean => {
		return !!modals[modalId]?.isOpen;
	};

	const getModalProps = (modalId: string): any => {
		return modals[modalId]?.props;
	};

	const contextValue: ModalContextValue = {
		modals,
		openModal,
		closeModal,
		isModalOpen,
		getModalProps,
	};

	return <ModalContext.Provider value={contextValue}>{children}</ModalContext.Provider>;
};

export const useModalManager = (): ModalContextValue => {
	const context = useContext(ModalContext);
	if (context === undefined) {
		throw new Error('useModalManager must be used within a ModalProvider');
	}
	return context;
};
