import styled from '@emotion/styled';
import { formControlClasses, formControlLabelClasses, radioClasses, radioGroupClasses } from '@mui/material';
import { STYLES_PREFIX } from '@topbar/src/shared/constants';

const Container = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	flex: 1,
	margin: '48px auto 0px',
	maxWidth: 'fit-content',
	padding: '0 16px',

	'@media (max-width: 960px)': {
		marginTop: '16px',
		padding: '0px 16px',
	},

	'& h1': {
		fontSize: '32px',
		lineHeight: '36px',
		fontWeight: 600,
		marginBottom: '8px',
		textAlign: 'center',
		'@media (max-width: 960px)': {
			fontSize: '28px',
		},
	},
	'& p': {
		color: '#262842',
		textAlign: 'center',
		marginBottom: '32px',
	},
	'& form': {
		maxWidth: 'fit-content',
		display: 'flex',
		flexDirection: 'column',
		margin: '0px auto',
	},
	[`& .${STYLES_PREFIX}-${formControlClasses.root}`]: {
		alignItems: 'center',
		marginBottom: '32px',
	},

	[`& .${STYLES_PREFIX}-${radioGroupClasses.root}`]: {
		flexFlow: 'unset',
	},

	[`& .${STYLES_PREFIX}-${formControlLabelClasses.root}`]: {
		border: '1px solid rgba(38, 40, 66, 0.12)',
		borderRadius: '12px',
		':last-child': {
			marginRight: '0px',
		},
		':focus': {
			border: '2px solid rgb(3, 155, 230)',
		},
	},
	[`& .${STYLES_PREFIX}-${formControlLabelClasses.label}`]: {
		paddingRight: '21px',
	},
	[`& .${STYLES_PREFIX}-${radioClasses.root}`]: {
		padding: '8px 8px 8px 12px',
	},
	[`& .${radioClasses.checked}`]: {
		'& svg path': {
			fill: 'rgb(3, 155, 230)',
		},
	},
	[`& .${STYLES_PREFIX}-${formControlLabelClasses.root}:has(.${radioClasses.checked})`]: {
		border: '2px solid rgb(3, 155, 230)',
	},
});

export default { Container };
