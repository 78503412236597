import { ModuleSetting } from '@topbar/src/services/sidebar';

import { MenuItem } from '../../../sidebar-constants';
import { getMenuItemById } from './get-menu-item-by-id';

export function getMenuItemsByModuleSettings(
	modulesSettings: readonly ModuleSetting[],
	menuItems: readonly MenuItem[]
): MenuItem[] {
	return modulesSettings.map(({ moduleType }) => getMenuItemById(menuItems, moduleType)).filter((menuItem) => !!menuItem);
}
