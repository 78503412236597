import jwtDecode from 'jwt-decode';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { Workspace } from '@dar/api-interfaces';
import { WORKSPACE_TOKEN_STORAGE_KEY } from '@dartech/griffon-auth';
import { Menu } from '@mui/material';
import { ReactComponent as PersonIcon } from '@topbar/src/components/sidebar/svg/person-outline.svg';
import { ReactComponent as Plus } from '@topbar/src/components/sidebar/svg/plus.svg';
import { ReactComponent as SettingsIcon } from '@topbar/src/components/sidebar/svg/settings-outline.svg';
import { ReactComponent as TrendingUpIcon } from '@topbar/src/components/sidebar/svg/trending-up.svg';
import { PERMISSIONS_TYPES } from '@topbar/src/constants/permissions';
import { WORKSPACE_STORAGE_KEY } from '@topbar/src/constants/workspace';
import { useAuth } from '@topbar/src/topbar/providers/auth-provider';
import { getRoleLocalizationKey } from '@topbar/src/utils/locale';
import { checkPermission } from '@topbar/src/utils/permissions';
import { TYPE_ENUM, useConfirm } from '@topbar/src/utils/useConfirm';

import { TypographyWithHint } from '../../../components/shared/typography-with-hint/typography-with-hint';
import { Typography } from '../../../components/shared/typography/typography';
import { TypographyFontWeightVariant, TypographyVariant } from '../../../sidebar-theme';
import { WorkspaceAvatar } from '../workspace-avatar/workspace-avatar';
import { Size } from '../workspace-avatar/workspace-avatar-types';
import { StyledWorkspaceMenu } from './workspace-menu-styles';
import { WorkspaceMenuProps } from './workspace-menu-types';
import { MenuAutoCompleteSelect } from './workspace-select/workspace-select';

const useMenuStyles = makeStyles()((theme) => ({
	paper: {
		borderRadius: '10px',
		border: '1px solid rgba(38, 40, 66, 0.08)',
		zIndex: 1200,
		boxShadow: '0px 8px 16px 0px #2628421A',
		width: '560px',
		maxHeight: '448px',
		maxWidth: 'unset',
		overflowY: 'hidden',
	},
	list: {
		padding: '0',
		width: '100%',
		display: 'flex',
		height: '100%',
		overflowY: 'hidden',
	},
}));

const WorkspacesMenu = ({
	onClose,
	isOpen,
	activeWorkspace,
	anchorEl,
	workspaces,
	onSelect,
	countersByWorkspaceId,
}: WorkspaceMenuProps) => {
	const { onConfirm } = useConfirm();
	const { classes: menuClasses } = useMenuStyles();
	const { setWorkspace } = useAuth();
	const { t } = useTranslation();
	const { t: tHcms } = useTranslation('hcms');
	const history = useHistory();
	const workspaceToken = localStorage.getItem(WORKSPACE_TOKEN_STORAGE_KEY);
	const decodedWorkspace: { permissions: any } = workspaceToken && jwtDecode(workspaceToken);
	const permissions = decodedWorkspace?.permissions;
	const tokenDecodablePart = JSON.parse(localStorage.getItem(WORKSPACE_STORAGE_KEY));
	const roles = tokenDecodablePart?.role;

	const onCreateNewWorkspace = () => {
		onConfirm((isVideoConference: boolean) => {
			clearWorkspace();
			const route = '/workspace/how-will-use';
			if (isVideoConference) {
				window.open(route);
			} else {
				history.push(route);
			}
		});
	};

	const clearWorkspace = () => {
		localStorage.removeItem(WORKSPACE_STORAGE_KEY);
		localStorage.removeItem(WORKSPACE_TOKEN_STORAGE_KEY);
		setWorkspace(null);
	};

	const onClickSettings = () =>
		onConfirm(() => {
			if (history.location.pathname === '/admin/users') {
				return;
			}
			history.push('/admin/users', { from: history.location.pathname + history.location.search });
		}, TYPE_ENUM.TAB);

	const onUpgradeClick = () =>
		onConfirm(() => {
			if (history.location.pathname === '/admin/plans') {
				return;
			}

			history.push('/admin/plans');
		}, TYPE_ENUM.TAB);

	const onInviteUserClick = () =>
		onConfirm(() => {
			if (history.location.pathname === '/admin/users') {
				return;
			}

			history.push('/admin/users');
		}, TYPE_ENUM.TAB);

	const isAdminOrOwner = useMemo(() => roles === 'ADMIN' || roles === 'OWNER', [roles]);

	const hasWorkspaceSettingsPermissions = useMemo(
		() =>
			isAdminOrOwner ||
			checkPermission(
				[
					PERMISSIONS_TYPES.USER_AND_TEAMS,
					PERMISSIONS_TYPES.DIRECTORIES,
					PERMISSIONS_TYPES.COMPANY_PROFILE,
					PERMISSIONS_TYPES.HOLDING_ADMINISTRATION,
				],
				permissions
			),
		[isAdminOrOwner]
	);

	const hasUserInvitePermission = useMemo(
		() => isAdminOrOwner || checkPermission([PERMISSIONS_TYPES.USER_AND_TEAMS], permissions),
		[isAdminOrOwner]
	);

	const onAutoCompleteSelect = useCallback(
		(value: Workspace) => {
			return onConfirm(() => onSelect(value), TYPE_ENUM.WORKSPACE);
		},
		[onSelect, onConfirm]
	);

	return (
		<Menu
			id='menu-companies'
			disableEnforceFocus
			disableRestoreFocus
			anchorEl={anchorEl}
			classes={menuClasses}
			marginThreshold={0}
			anchorReference='anchorPosition'
			anchorPosition={{ top: 110, left: 8 }}
			open={isOpen}
			onClose={onClose}
		>
			<StyledWorkspaceMenu.WorkspaceSelectContainer>
				<MenuAutoCompleteSelect
					activeCompany={activeWorkspace}
					onSelect={onAutoCompleteSelect}
					open={isOpen}
					options={workspaces}
					countersByWorkspaceId={countersByWorkspaceId}
				/>
			</StyledWorkspaceMenu.WorkspaceSelectContainer>
			<StyledWorkspaceMenu.CurrentWorkspaceInfoContainer $hasAction={hasUserInvitePermission || hasWorkspaceSettingsPermissions}>
				<StyledWorkspaceMenu.CurrentWorkspaceInfo>
					<StyledWorkspaceMenu.CurrentWorkspaceDescriptionContainer>
						<WorkspaceAvatar
							workspaceAvatarSrc={activeWorkspace.organization?.logo}
							workspaceName={activeWorkspace.organization?.name}
							size={Size.BIG}
						/>
						<TypographyWithHint
							text={activeWorkspace.organization?.name}
							shortWordMaxLength={20}
							render={(text) => (
								<StyledWorkspaceMenu.CurrentWorkspaceName
									customVariant={TypographyVariant.LARGE}
									fontWeight={TypographyFontWeightVariant.BOLD}
								>
									{text}
								</StyledWorkspaceMenu.CurrentWorkspaceName>
							)}
						/>
						<StyledWorkspaceMenu.CurrentWorkspaceAdditionalInfo>
							{t('login.wrkspc_list_member', { N: activeWorkspace?.numberOfEmployees })} ·{' '}
							{tHcms(getRoleLocalizationKey(activeWorkspace?.role))}
						</StyledWorkspaceMenu.CurrentWorkspaceAdditionalInfo>
					</StyledWorkspaceMenu.CurrentWorkspaceDescriptionContainer>
					<StyledWorkspaceMenu.ActionItemsContainer>
						{hasWorkspaceSettingsPermissions ? (
							<StyledWorkspaceMenu.CurrentWorkspaceActionItem onClick={onClickSettings}>
								<SettingsIcon />
								<Typography fontWeight={TypographyFontWeightVariant.BOLD}>{t('sidebar_settings.workspace_settings')}</Typography>
							</StyledWorkspaceMenu.CurrentWorkspaceActionItem>
						) : null}
						{hasUserInvitePermission ? (
							<StyledWorkspaceMenu.CurrentWorkspaceActionItem onClick={onInviteUserClick}>
								<PersonIcon />
								<Typography fontWeight={TypographyFontWeightVariant.BOLD}>{t('invite.title')}</Typography>
							</StyledWorkspaceMenu.CurrentWorkspaceActionItem>
						) : null}
					</StyledWorkspaceMenu.ActionItemsContainer>
					{hasWorkspaceSettingsPermissions ? (
						<StyledWorkspaceMenu.UpgradeButton onClick={onUpgradeClick}>
							<TrendingUpIcon />
							<StyledWorkspaceMenu.UpgradeButtonLabel fontWeight={TypographyFontWeightVariant.BOLD}>
								{t('sidebar_settings.upgrade')}
							</StyledWorkspaceMenu.UpgradeButtonLabel>
						</StyledWorkspaceMenu.UpgradeButton>
					) : null}
				</StyledWorkspaceMenu.CurrentWorkspaceInfo>
			</StyledWorkspaceMenu.CurrentWorkspaceInfoContainer>
		</Menu>
	);
};

export { WorkspacesMenu };
