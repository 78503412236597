import React, { ReactElement } from 'react';

import styled from '@emotion/styled';

const StyledButton = styled.button`
	background: transparent;
	padding-block: 0;
	padding-inline: 0;

	&:hover {
		cursor: pointer;
	}
`;

type Ref = HTMLButtonElement;

export const Button = React.forwardRef<Ref, React.ButtonHTMLAttributes<HTMLButtonElement>>(
	({ children, ...rest }, ref): ReactElement => (
		<StyledButton ref={ref} {...rest}>
			{children}
		</StyledButton>
	)
);
