import jwtDecode from 'jwt-decode';

import { Workspace, WorkspaceDecodedToken } from '@dar/api-interfaces';

import { PERMISSIONS_ACTIONS, PERMISSIONS_TYPES } from '../constants/permissions';
import { WORKSPACE_STORAGE_KEY, WORKSPACE_TOKEN_STORAGE_KEY } from '../constants/workspace';
import { environment } from '../environments/environment';

export const isDar = () => {
	const workspace = JSON.parse(localStorage.getItem('workspace'));
	return workspace?.holdingId === environment().darHoldingId;
};

export const hasPermission = (module: PERMISSIONS_TYPES, crudType: PERMISSIONS_ACTIONS) => {
	const workspaceToken = localStorage.getItem(WORKSPACE_TOKEN_STORAGE_KEY);
	if (!workspaceToken) return false;

	const decodedToken = jwtDecode<WorkspaceDecodedToken>(workspaceToken);
	return decodedToken.permissions?.[module]?.includes(crudType);
};

export function getWorkspace(): Workspace | null {
	try {
		const workspace = JSON.parse(localStorage.getItem(WORKSPACE_STORAGE_KEY));

		if (workspace === null) {
			return null;
		}

		if (
			!('id' in workspace) ||
			!('employeeId' in workspace) ||
			!('holdingId' in workspace) ||
			!('bin' in workspace) ||
			!('isHolding' in workspace) ||
			!('isDeputy' in workspace) ||
			!('type' in workspace) ||
			!('organization' in workspace) ||
			!('role' in workspace)
		) {
			return null;
		}

		return workspace;
	} catch {
		return null;
	}
}
