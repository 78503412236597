import { Analytics } from '@dar/api-interfaces';
import { domainInfo } from '@topbar/src/utils/domain';

// const policyLinks = {
// 	kz: { privacy: 'https://darlean.com/policy?region=kz', cookies: 'https://darlean.com/cookies?region=kz' },
// 	id: { privacy: 'https://darlean.com/policy?region=id', cookies: 'https://darlean.com/cookies?region=id' },
// 	eu: { privacy: 'https://darlean.com/policy?region=eu', cookies: 'https://darlean.com/cookies?region=eu' },
// 	worldWide: { privacy: 'https://darlean.com/policy?region=ww ', cookies: 'https://darlean.com/cookies?region=ww' },
// };

export interface CookieSettingsOption {
	name: Analytics;
	label: string;
	value: boolean;
}

const matomoSettings: CookieSettingsOption = { name: 'matomo', label: 'Matomo', value: true };
const matomoTagManager: CookieSettingsOption = { name: 'matomo-tag-manager', label: 'MTM', value: true };

// TODO: add localization
const settingsOptions: Record<string, CookieSettingsOption[]> = {
	kz: [
		{ name: 'bitrix-kz', label: 'Bitrix', value: true },
		{ name: 'ga-4', label: 'Google tag Manager', value: true },
		matomoSettings,
		matomoTagManager,
	],
	id: [
		{ name: 'bitrix-kz', label: 'Bitrix', value: true },
		{ name: 'ga-4', label: 'Google tag Manager', value: true },
		matomoSettings,
		matomoTagManager,
	],
	eu: [{ name: 'bitrix-eu', label: 'Bitrix', value: true }, matomoSettings, matomoTagManager],
	worldWide: [
		{ name: 'bitrix-kz', label: 'Bitrix', value: true },
		{ name: 'ga-4', label: 'Google tag Manager', value: true },
	],
	dario: [
		{ name: 'bitrix-kz', label: 'Bitrix', value: true },
		{ name: 'ga-4', label: 'Google tag Manager', value: true },
		matomoSettings,
		matomoTagManager,
	],
};

export const getSettings = () => {
	if (domainInfo.isEU()) return settingsOptions.eu;
	if (domainInfo.isID()) return settingsOptions.id;
	if (domainInfo.isKZ()) return settingsOptions.kz;
	if (domainInfo.isDarIo()) return settingsOptions.dario;
	return settingsOptions.worldWide;
};
