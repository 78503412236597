import { Theme } from '@emotion/react';

export enum TypographyVariant {
	SMALL,
	MEDIUM,
	LARGE,
}

export enum TypographyFontWeightVariant {
	DEFAULT,
	BOLD,
}

export const sidebarTheme: Theme = {
	colors: {
		primary: 'hotpink',
	},
	border: {
		default: '1px solid #2628421f',
	},
	spacing: {
		'positive-50': '4px',
		'positive-75': '6px',
		'positive-100': '8px',
		'positive-125': '10px',
		'positive-150': '12px',
		'positive-200': '16px',
	},
	borderRadius: {
		xxs: '6px',
		xs: '8px',
		sm: '10px',
	},
	fontSize: {
		[TypographyVariant.SMALL]: '12px',
		[TypographyVariant.MEDIUM]: '14px',
		[TypographyVariant.LARGE]: '16px',
	},
	lineHeight: {
		[TypographyVariant.SMALL]: '16px',
		[TypographyVariant.MEDIUM]: '18px',
		[TypographyVariant.LARGE]: '20px',
	},
	fontWeight: {
		[TypographyFontWeightVariant.DEFAULT]: 400,
		[TypographyFontWeightVariant.BOLD]: 500,
	},
};
