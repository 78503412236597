import { useCallback, useEffect, useState } from 'react';

import { NotificationCounters, TotalNotificationCounters } from '@dar/api-interfaces';
import { Profile } from '@dartech/griffon-auth';

import { environment } from '../environments/environment';
import { useCompanyID } from '../hooks/useCompanyID';
import { setCounter } from '../hooks/useNotificationsCounter';
import { notificationsService } from '../services/notifications';
import { CUSTOM_EVENTS } from '../shared/constants';
import { useAuth } from '../topbar/providers/auth-provider';
import { SOCKET_EVENT_MESSAGE, SocketClient } from '../utils/websocket';

export type UseBadges = {
	badges: NotificationCounters;
	badgesByHoldings: TotalNotificationCounters;
};

type Event = {
	group: string;
	payload: string;
	type: string;
};

function isUpdateBadgesEvent(event: Event): boolean {
	return event.group === 'notifications' && event.payload === 'UPDATE_BADGES' && event.type === 'badges';
}

export function useBadges(profileId: Profile['id'], holdings: string[]): UseBadges {
	const [badges, setBadges] = useState<NotificationCounters>({});
	const [badgesByHoldings, setBadgedByHoldings] = useState<TotalNotificationCounters>({} as TotalNotificationCounters);
	const companyId = useCompanyID();
	const { authenticated } = useAuth();

	const fetchBadges = useCallback(() => {
		notificationsService
			?.getNotificationsCounters(profileId, companyId)
			.then((badges) => {
				setBadges(badges);
				setCounter(badges);
			})
			.catch(() => {});

		if (!!holdings?.length) {
			notificationsService
				?.getNotificationsCountersByHoldings(holdings)
				.then((badgesByHoldings) => {
					setBadgedByHoldings(badgesByHoldings);
				})
				.catch(() => {});
		}
	}, [companyId, profileId, holdings]);

	useEffect(() => {
		function receiveHandler(message) {
			if (isUpdateBadgesEvent(message)) {
				fetchBadges();
				window.dispatchEvent(new Event(CUSTOM_EVENTS.BADGE_UPDATE));
			}
		}

		if (authenticated) {
			fetchBadges();

			const socket = SocketClient.getInstance({
				url: environment().badgesWebstocketUrl,
			});
			socket.eventEmitter.on(SOCKET_EVENT_MESSAGE, receiveHandler);
			const token = JSON.parse(localStorage.getItem('dms-auth'))?.id_token;
			socket.open(token);
			return () => {
				socket.eventEmitter.off(SOCKET_EVENT_MESSAGE, receiveHandler);
				socket.close();
			};
		}
	}, [fetchBadges, authenticated]);

	return {
		badges,
		badgesByHoldings,
	};
}
