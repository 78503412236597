import axios from 'axios';

import { Country, UserLanguage } from '@dar/api-interfaces';

import { environment } from '../environments/environment';
import { httpClient } from './clients';

export const setLanguage = (data: UserLanguage) => {
	return httpClient.post<UserLanguage>(`${environment().hcmsApiRoot}/main-api/local/create`, data).then((res) => res?.data);
};

export const getLanguage = (id: string) => {
	return httpClient.get<UserLanguage>(`${environment().hcmsApiRoot}/main-api/local/${id}`).then((res) => res?.data);
};

export const getLanguages = () => {
	return axios.get<{ id: string; name: string }[]>(`${environment().dmsBaseApi}/dictionary-api/language/all`).then((res) => {
		return res.data;
	});
};

export const getCountriesApi = () => {
	return axios.get<Country[]>(`${environment().hcmsApiRoot}/main-api/work-calendar/countries/all`, {}).then((res) => res.data);
};
