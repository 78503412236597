import { Link as ReactRouteLink } from 'react-router-dom';

import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { sidebarTheme } from '../sidebar-theme';

const Container = styled(Box)`
	padding: ${sidebarTheme.spacing['positive-200']} ${sidebarTheme.spacing['positive-150']};
	border-bottom: ${sidebarTheme.border.default};
	height: 48.8px;
`;

const Link = styled(ReactRouteLink)`
	width: fit-content;
	display: block;

	&:focus-visible {
		outline: 2px solid #039be6;
	}
`;

export const StyledHeader = {
	Container,
	Link,
};
