import * as React from 'react';
import { useTranslation } from 'react-i18next';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import MobileAppAvif from '../../../../../assets/mobile_app_2x.avif';
import MobileAppJpeg from '../../../../../assets/mobile_app_2x.jpg';
import MobileAppWebp from '../../../../../assets/mobile_app_2x.webp';
import QrEu from '../../../../../assets/qr_code_darlean_eu.png';
import QrId from '../../../../../assets/qr_code_darlean_id.png';
import QrKz from '../../../../../assets/qr_code_darlean_kz.png';
import { environment } from '../../../../../environments/environment';

export default function MobileQrModal({ open, onClose }) {
	const { t } = useTranslation('weblayout');

	const { region } = environment();

	const [QR, setQR] = React.useState(QrKz);

	React.useEffect(() => {
		const qrCodeMap = {
			eu: QrEu,
			in: QrId,
			kz: QrKz,
		};

		setQR(qrCodeMap[region] || QrKz);
	}, [region]);

	return (
		<div>
			<Modal open={open} onClose={onClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
				<Box sx={style.root}>
					<CloseRoundedIcon onClick={onClose} sx={style.closeIcon} color='disabled' aria-label='Close' />
					<Box component={'picture'} sx={style.leftBox}>
						<source srcSet={MobileAppAvif} type='image/avif' />
						<source srcSet={MobileAppWebp} type='image/webp' />
						<img src={MobileAppJpeg} alt='Mobile App' />
					</Box>
					<Box sx={style.rightBox}>
						<Typography id='modal-title' component='h1' sx={style.title}>
							{t('menu.mobile_app_title')}
						</Typography>
						<Typography id='modal-description' sx={style.description}>
							{t('menu.mobile_app_description')}
						</Typography>
						<Box sx={style.qrBox}>
							<img src={QR} alt='QR Code' />
						</Box>
					</Box>
				</Box>
			</Modal>
		</div>
	);
}

const style = {
	root: {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 800,
		height: 500,
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		bgcolor: 'background.paper',
		borderRadius: 'var(--radius-md, 12px)',
		border: '1px solid var(--color-outline-2, rgba(38, 40, 66, 0.12))',
		boxShadow: '0px 16px 32px 0px rgba(38, 40, 66, 0.08)',
		overflow: 'hidden',
	},
	title: {
		fontSize: 'var(--font-size-headline-lg, 32px)',
		fontWeight: 600,
	},
	description: {
		color: 'var(--color-permanent-onNeutralVariant, #6D6E85)',
		fontSize: 'var(--font-size-text-lg, 16px)',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: 'var(--font-lineHeight-lg, 20px)', // 125%
	},
	leftBox: {
		flex: '0 0 337px',
		height: '100%',
		'& > source': {
			height: '100%',
			width: '100%',
		},
		'& > img': {
			height: '100%',
			width: '100%',
		},
	},
	rightBox: {
		display: 'flex',
		padding: 'var(--spacing-positive-400, 32px)',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'flex-start',
		gap: 'var(--spacing-positive-200, 16px)',
		flex: '1 0 0',
		alignSelf: 'stretch',
	},
	qrBox: {
		width: 180,
		padding: '1rem',
		border: '1px solid var(--color-outline-2, rgba(38, 40, 66, 0.12))',
		borderRadius: 'var(--radius-md, 12px)',
	},
	closeIcon: {
		position: 'absolute' as 'absolute',
		right: 23,
		top: 23,
		cursor: 'pointer',
	},
};
