import React, { ReactElement, useCallback, useContext, useMemo } from 'react';
import Div100vh from 'react-div-100vh';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';

import { Workspace as IWorkspace } from '@dar/api-interfaces';
import { Profile as IProfile } from '@dartech/griffon-auth';
import { Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { ChatContext } from '@topbar/src/contexts/chat-context';

import { ChatBotSection } from './chat-bot-section/chat-bot-section';
import { SidebarFooter } from './footer/sidebar-footer';
import { useSidebarAnimations } from './hooks/use-sidebar-animations';
import { useViewportHeight } from './hooks/use-viewport-height';
import { MenuList } from './menu-list/menu-list';
import { Profile } from './profile';
import { SidebarHeader } from './sidebar-header/sidebar-header';
import { StyledSidebar } from './sidebar-styles';
import { sidebarTheme } from './sidebar-theme';
import { ReactComponent as Logo } from './svg/darlean-logo.svg';
import { Workspace } from './workspace/workspace';

type SidebarProps = {
	currentWorkspace: IWorkspace;
	profile: IProfile;
	collapsed: boolean;
	toggleCollapse: () => void;
};

const SIDEBAR_CONTAINER_ID = 'sidebar-container-id';
export const HEADER_LOGO_ID = 'header-logo-id';

export function Sidebar({ currentWorkspace, profile, collapsed, toggleCollapse }: SidebarProps): ReactElement {
	const { t } = useTranslation();
	const chatContext = useContext(ChatContext);

	useViewportHeight();
	useSidebarAnimations({
		logoId: HEADER_LOGO_ID,
		sidebarContainerId: SIDEBAR_CONTAINER_ID,
		collapsed,
	});

	const osShortcut = useMemo(() => (navigator.userAgent.includes('Mac') ? 'meta+/' : 'ctrl+/'), []);
	useHotkeys(osShortcut, toggleCollapse);

	const shouldHaveChatBot = useMemo(() => typeof chatContext?.onChatBotClick === 'function', [chatContext]);

	const onChatBotClick = useCallback(() => {
		chatContext?.onChatBotClick();
	}, [chatContext]);

	const toggleChat = useCallback(() => {
		chatContext?.toggleChat();
	}, [chatContext]);

	return (
		<ThemeProvider theme={sidebarTheme}>
			<Div100vh className='sticky'>
				<StyledSidebar.Container id={SIDEBAR_CONTAINER_ID} $shouldHaveChatBot={shouldHaveChatBot}>
					<Box>
						<SidebarHeader link={{ to: '/overview', title: t('logo-link-title') }}>
							<Logo title={t('logo-title')} role='img' id={HEADER_LOGO_ID} />
						</SidebarHeader>
						<Workspace currentWorkspace={currentWorkspace} collapsed={collapsed} />
						<MenuList profile={profile} collapsed={collapsed} currentWorkspace={currentWorkspace} />
					</Box>
					<Box position='relative'>
						{shouldHaveChatBot ? <ChatBotSection onChatBotClick={onChatBotClick} collapsed={collapsed} /> : null}
						<Profile profile={profile} workspace={currentWorkspace} collapsed={collapsed} />
						<SidebarFooter
							toggleCollapse={toggleCollapse}
							collapsed={collapsed}
							onChatClick={typeof chatContext?.toggleChat === 'function' ? toggleChat : undefined}
						/>
					</Box>
				</StyledSidebar.Container>
			</Div100vh>
		</ThemeProvider>
	);
}
