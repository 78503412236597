import { animate } from 'motion';
import { useEffect } from 'react';

type UseProfileAnimations = {
	collapsed: boolean;
	profileUserInfoId: string;
};

export function useProfileAnimations({ collapsed, profileUserInfoId }: UseProfileAnimations): void {
	useEffect(() => {
		const profileUserInfoContainer = document.getElementById(profileUserInfoId);

		if (profileUserInfoContainer) {
			if (collapsed) {
				animate(profileUserInfoContainer, { opacity: 0 }, { duration: 0.4 }).finished.then(() => {
					animate(profileUserInfoContainer, { visibility: 'hidden' });
				});
			} else {
				animate(profileUserInfoContainer, { visibility: 'visible' }).finished.then(() => {
					animate(profileUserInfoContainer, { opacity: 1 }, { duration: 0.6 });
				});
			}
		}
	}, [profileUserInfoId, collapsed]);
}
