import React from 'react';

import { AuthProvider } from './context/auth-context/auth-context';
import { Router } from './router';

export const Docflow = () => {
	return (
		<AuthProvider>
			<Router />
		</AuthProvider>
	);
};
