import Cookies from 'js-cookie';

interface AuthTokens {
	access: string | null;
}

const STORAGE_KEYS = {
	ACCESS_TOKEN: 'docflow-access-token',
} as const;

export const clearTokens = (): void => {
	Cookies.remove(STORAGE_KEYS.ACCESS_TOKEN);
};

export const setTokens = ({ access }: { access: string }): void => {
	if (!access) {
		throw new Error('Access token is required');
	}
	Cookies.set(STORAGE_KEYS.ACCESS_TOKEN, access, {
		expires: 2 / 24,
		secure: true,
		sameSite: 'strict',
	});
};

export const getTokens = (): AuthTokens => {
	try {
		const access = Cookies.get(STORAGE_KEYS.ACCESS_TOKEN) || null;
		return { access };
	} catch (error) {
		clearTokens();
		return { access: null };
	}
};
